import {isPs4, isPs5} from '@fsa-streamotion/browser-utils';

import type RxPlayer from 'rx-player';

import type PlaybackRx from '.';
import type {KeySystemConfig} from '../../types';
import {VIDEO_FS_ERROR_TYPES} from '../../utils/player-tech-error';
import PlayerTechXhrError from '../../utils/player-tech-xhr-error';

const PLAY_READY_KEY_SYSTEM = 'com.microsoft.playready';
const FAIR_PLAY_KEY_SYSTEM = 'com.apple.fps.1_0';

type MassageRequestParams = {
    uri: string;
    method?: string;
    messageBody?: string | ArrayBuffer;
    headers: Record<string, string>;
    withCredentials?: boolean;
    responseType?: XMLHttpRequest['responseType'];
};

type MassageRequestSuccessResponse = {
    xhrResponse: Uint8Array;
    xhrRequestObject: XMLHttpRequest;
};

export default class RxPlayerEme {
    playbackTech: PlaybackRx;
    playbackHandler: RxPlayer;
    activeKeySystemName = '';

    constructor({
        playbackTech,
        playbackHandler,
    }: {
        playbackTech: PlaybackRx;
        playbackHandler: RxPlayer;
    }) {
        this.playbackTech = playbackTech;
        this.playbackHandler = playbackHandler;
    }

    private getPlayReadyChallenges(
        keySystemName: string,
        licenseMessageRequest: ArrayBuffer
    ): {
        playReadyHeaders?: Record<string, string>;
        playReadyChallengeBody?: string;
    } {
        if (!keySystemName.includes(PLAY_READY_KEY_SYSTEM)) {
            return {};
        }

        const UintArrayConstructor =
            isPs5() || isPs4() ? Uint8Array : Uint16Array;

        // Stolen from videofs-contrib-eme https://github.com/videojs/videojs-contrib-eme/blob/master/src/playready.js
        // getMessageContents()
        const xml = new window.DOMParser().parseFromString(
            // @TODO do we want to support UTF-8?
            String.fromCharCode(
                ...new UintArrayConstructor(licenseMessageRequest)
            ),
            'application/xml'
        );
        const [headersElement] = xml.getElementsByTagName('HttpHeaders');
        const playReadyHeaders: Record<string, string> = {};

        if (headersElement) {
            const headerNames = headersElement.getElementsByTagName('name');
            const headerValues = headersElement.getElementsByTagName('value');

            for (let i = 0; i < headerNames.length; i++) {
                const headerName = headerNames[i]?.childNodes[0]?.nodeValue;
                const headerValue = headerValues[i]?.childNodes[0]?.nodeValue;

                if (headerName && headerValue) {
                    playReadyHeaders[headerName] = headerValue;
                }
            }
        }

        const [challengeElement] = xml.getElementsByTagName('Challenge');
        const challengeElementValue =
            challengeElement?.childNodes[0]?.nodeValue;
        let playReadyChallengeBody;

        if (challengeElement && challengeElementValue) {
            playReadyChallengeBody = window.atob(challengeElementValue);
        }
        // eo getMessageContents

        const returnPayload = {
            playReadyHeaders,
            playReadyChallengeBody,
        };

        return returnPayload;
    }

    private makeMessageRequest = ({
        uri,
        messageBody,
        method = 'POST',
        responseType = 'arraybuffer',
        withCredentials = true,
        headers = {},
    }: MassageRequestParams): Promise<MassageRequestSuccessResponse> =>
        new Promise((resolve, reject) => {
            const licenseRequest = new XMLHttpRequest();

            licenseRequest.open(method, uri, true);
            licenseRequest.responseType = responseType;
            licenseRequest.withCredentials = withCredentials; // If we have cookies for this domain, use them.

            Object.entries(headers).forEach(([key, value]) => {
                licenseRequest.setRequestHeader(key, value);
            });

            licenseRequest.onload = () => {
                const {response, status} = licenseRequest;

                if (status && status >= 200 && status < 400) {
                    resolve({
                        xhrResponse: response,
                        xhrRequestObject: licenseRequest,
                    });
                } else {
                    reject(
                        new PlayerTechXhrError({
                            type: VIDEO_FS_ERROR_TYPES.EME_REQUEST,
                            message: `NativeEme: getLicenseFromMessageRequest response ${status}`,
                            XMLHttpRequest: licenseRequest,
                        })
                    );
                }
            };

            licenseRequest.ontimeout = (event) => {
                console.error(
                    'VideoFsNativeEme: getLicenseFromMessageRequest timeout',
                    event
                );
                reject(
                    new PlayerTechXhrError({
                        type: VIDEO_FS_ERROR_TYPES.EME_REQUEST,
                        message:
                            'NativeEme: getLicenseFromMessageRequest timeout',
                        XMLHttpRequest: licenseRequest,
                    })
                );
            };

            licenseRequest.onerror = (event) => {
                console.error(
                    'VideoFsNativeEme: getLicenseFromMessageRequest failed',
                    event
                );
                reject(
                    new PlayerTechXhrError({
                        type: VIDEO_FS_ERROR_TYPES.EME_REQUEST,
                        message:
                            'NativeEme: getLicenseFromMessageRequest error',
                        XMLHttpRequest: licenseRequest,
                    })
                );
            };

            licenseRequest.send(messageBody);
        });

    // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
    public async destroy(): Promise<void> {}

    public async makeLicenseCall({
        keySystemName,
        keySystemConfig,
        message,
    }: {
        keySystemName: string;
        keySystemConfig: KeySystemConfig;
        message: Uint8Array;
    }): Promise<BufferSource | null> {
        const {playReadyHeaders, playReadyChallengeBody} =
            this.getPlayReadyChallenges(keySystemName, message.buffer);

        this.activeKeySystemName = keySystemName;

        const requestResult = await this.makeMessageRequest({
            uri:
                keySystemConfig.licenseUri ||
                keySystemConfig.certificateUri ||
                '',
            messageBody: playReadyChallengeBody || message,
            headers: {
                ...(this.activeKeySystemName === FAIR_PLAY_KEY_SYSTEM && {
                    'content-type': 'application/x-www-form-urlencoded',
                }),
                ...playReadyHeaders,
                // Grab headers from any getHttpRequestHeaders method.
                ...(keySystemConfig?.getHttpRequestHeaders &&
                    (await keySystemConfig.getHttpRequestHeaders())),
            },
        });

        return requestResult.xhrResponse;
    }
}
