import type {PlaybackData, VideoState} from '@fsa-streamotion/player-state';
import type PlayerTech from '@fsa-streamotion/player-tech';
// eslint-disable-next-line no-duplicate-imports
import type {
    PlayerQualityLevel,
} from '@fsa-streamotion/player-tech';

import {version} from '../version';
import {AUDIO_CHANNEL_COUNT_TO_LABEL} from './get-audio-track-label-by-channel-count';

type ScreenConfig = {
    playerTech: PlayerTech;
    videoState: VideoState;
    playbackData: PlaybackData;
};

type ParsedDiagnosticsType = {
    videoWidth?: number;
    videoHeight?: number;
    videoBitrate?: number;
    videoCodec?: string;
    audioBitrate?: number;
    audioCodec?: string;
    audioTrackName?: string;
    totalFrames?: number;
    droppedFrames?: number;
    bufferedSeconds?: number;
    activeKeySystemName?: string | null;
    cdnProvider?: string;
    container: string;
    position: string;
    subtitle?: string | null;
    hasSsai: boolean;
    timestamp: number;
    version: string;
    videoQuality: PlayerQualityLevel;
    viewport: string;
    videoId?: string | null;
    frameRate?: number;
    audioOption?: string;
    sessionId?: string | null;
    playerVersion?: string;
};

/**
 * Extract common diagnostics information from a screenConfig object (most often from `activeScreenConfig`)
 *
 * @param screenConfig - Passed in, e.g. screenConfigByIndex, @see ScreenConfig
 * @returns The diagnostics details
 */
export default function parseDiagnostics(
    screenConfig: ScreenConfig
): ParsedDiagnosticsType {
    const {playerTech, videoState, playbackData} = screenConfig;

    const {diagnostics, currentPlaybackHandler} = playerTech;
    const {
        captions,
        currentCaptionTrackIndex,
        videoQuality,
        audioTracks,
        currentAudioTrackIndex,
    } = videoState;

    const cdnProvider = diagnostics?.cdnProvider;
    const container = diagnostics?.container;
    const playbackHandlerName = diagnostics?.playbackHandlerName;
    const hasSsai = !!diagnostics?.hasSsai;
    const playerVersion = diagnostics?.playerVersion;
    const channelCount = audioTracks?.[currentAudioTrackIndex]?.channelCount;
    const audioOption = AUDIO_CHANNEL_COUNT_TO_LABEL[channelCount ?? -1];

    const target = {
        videoWidth: diagnostics?.videoWidth,
        videoHeight: diagnostics?.videoHeight,
        videoBitrate: diagnostics?.videoBitrate,
        videoCodec: diagnostics?.videoCodec,
        audioBitrate: diagnostics?.audioBitrate,
        audioCodec: diagnostics?.audioCodec,
        audioTrackName: diagnostics?.audioTrackName,
        totalFrames: diagnostics?.totalFrames,
        droppedFrames: diagnostics?.droppedFrames,
        bufferedSeconds: diagnostics?.bufferedSeconds,
        frameRate: diagnostics?.frameRate,
    };

    return Object.assign(target, {
        activeKeySystemName: currentPlaybackHandler?.activeKeySystemName,
        cdnProvider,
        container: `${container} (${playbackHandlerName})`,
        position: currentPlaybackHandler?.videoElement
            ? `${currentPlaybackHandler.currentTime?.toFixed(2)}s`
            : '',
        subtitle: captions?.[currentCaptionTrackIndex]?.label,
        hasSsai,
        timestamp: Date.now(),
        version,
        videoQuality,
        viewport: `${window.innerWidth}×${window.innerHeight}`,
        videoId: playbackData?.id,
        audioOption,
        sessionId: playbackData?.sessionId,
        playerVersion,
    });
}
