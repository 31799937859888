// Generate SHA-512 integrity hashes via:
// openssl dgst -sha512 -binary FILENAME.js | openssl base64 -A
const VIDEO_LIBRARY_URLS = {
    hlsjs: {
        src: 'https://resources.streamotion.com.au/immutable/web/video-libraries/hlsjs/1.4.10/hls.min.js',
        integrity:
            'sha512-Tw2aUDnE3Mn2vIh5gwdBAib92rjv8lQbJD4gMue057/ePZrRUDTKP3ryMgAWwVUCWUmapcSgh/iNTL4Z4iADng==',
        version: '1.4.10',
    },
    dashjs: {
        src: 'https://resources.streamotion.com.au/immutable/web/video-libraries/dashjs/4.7.4/dash.all.min.js',
        integrity:
            'sha512-LyDgm9kfqyKlZOe+QjpNA6L/ZpcjNj+cKSJ/bQLTGkKXaxYNpYGN9Fe6DpI0H0w3Da2WcXVX8ACjL14y3iWGBQ==',
        version: '4.7.4',
    },
    rxplayer: {
        src: 'https://resources.streamotion.com.au/immutable/web/video-libraries/rxplayer/4.2.0/rx-player.js',
        integrity:
            'sha512-kZM/ZLv+1BI91IbQPXyO4ZKa0n+DrAAR4O60fTTNcfzLbnwqM1opix7MfqYjFoq3DdcDieEHuMcpyM4UVmdzYw==',
        version: '4.2.0',
    },
} as const;

export default VIDEO_LIBRARY_URLS;
