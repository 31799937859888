export default class NativeAdsAdapter {

  constructor() {
    this.lastPlayhead = null;
  }

  getVersion() {
    return '7.0.8-Bitmovin8-ads-jsclass';
  }

  getPlayhead() {
    let ret = null;
    if (this.player && this.flags && this.flags.isStarted) {
      ret = this.player.getCurrentTime();
    }
    return ret;
  }

  getTitle() {
    return this.title;
  }

  getDuration() {
    return this.duration;
  }

  getResource() {
    return this.src;
  }

  getRendition() {
    return this.getNpawUtils().buildRenditionString(this.width, this.height, this.bitrate);
  }

  getBitrate() {
    return this.bitrate * 1000;
  }

  getAudioEnabled() {
    let ret = true;
    if (this.player) {
      ret = this.player.getVolume() > 0 && !this.player.isMuted();
    }
    return ret;
  }

  getIsSkippable() {
    return this.skippable;
  }

  getIsFullscreen() {
    let ret = false;
    if (this.player) {
      ret = this.player.getViewMode() === 'fullscreen';
    }
    return ret;
  }

  getIsVisible() {
    let ret = false;
    if (this.player && this.player.getVideoElement && this.player.getVideoElement()) {
      ret = this.getNpawUtils().calculateAdViewability(this.player.getVideoElement());
    }
    return ret;
  }

  getCreativeId() {
    return this.creativeId;
  }

  getPosition() {
    let ret = this.position;
    const adapterInst = this.getVideo().getAdapter();
    if (adapterInst && !adapterInst.flags.isJoined) {
      ret = this.getNpawReference().Constants.AdPosition.Preroll;
    }
    return ret;
  }

  registerListeners() {
    const events = this.player.exports.PlayerEvent;
    this.monitorPlayhead(true, true);
    this.references = {};
    this.references[events.AdBreakStarted] = this.breakStartListener.bind(this);
    this.references[events.AdBreakFinished] = this.breakEndListener.bind(this);
    this.references[events.AdStarted] = this.adStartListener.bind(this);
    this.references[events.AdFinished] = this.adEndListener.bind(this);
    this.references[events.AdQuartile] = this.quartileListener.bind(this);
    this.references[events.AdSkipped] = this.skipListener.bind(this);
    this.references[events.AdClicked] = this.clickListener.bind(this);
    this.references[events.AdError] = this.errorListener.bind(this);
    this.references[events.Destroy] = this.destroyListener.bind(this);
    this.references[events.Paused] = this.pauseListener.bind(this);
    this.references[events.Playing] = this.resumeListener.bind(this);
    this.references[events.TimeChanged] = this.timeupdateListener.bind(this);

    for (let key in this.references) {
      this.player.on(key, this.references[key]);
    }
  }

  pauseListener() {
    this.firePause();
  }

  resumeListener() {
    this.fireResume();
  }

  timeupdateListener() {
    this.lastPlayhead = this.getPlayhead();
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
    try {
      if (this.player && this.player.off && this.references) {
        for (let key in this.references) {
          this.player.off(key, this.references[key]);
        }
        delete this.references;
      }
    } catch (err) { }
  }

  breakStartListener(e) {
    this.getVideo().fireInit();
    const adapterInst = this.getVideo().getAdapter();
    if (adapterInst) {
      adapterInst.firePause();
    };
    const position = e.adBreak.position;
    if (position === 'pre') {
      this.position = this.getNpawReference().Constants.AdPosition.Preroll;
    } else if (position === 'post'|| adapterInst && adapterInst.getDuration() !== 0 && (adapterInst.getPlayhead() || position) > adapterInst.getDuration() - 1) {
      this.position = this.getNpawReference().Constants.AdPosition.Postroll;
    } else {
      this.position = this.getNpawReference().Constants.AdPosition.Midroll;
    }
  }

  breakEndListener(e) {
    this.fireBreakStop();
    if (this.getVideo().requestBuilder.lastSent.adPosition !== this.getNpawReference().Constants.AdPosition.Postroll) {
      const adapterInst = this.getVideo().getAdapter();
      if (adapterInst) {
        adapterInst.fireResume();
      }
    } else {
      this.getVideo().fireStop();
    }
  }

  adStartListener(e) {
    if (e.ad && e.ad.data) {
      this.title = e.ad.data.adTitle;
      this.bitrate = e.ad.data.bitrate;
      this.creativeId = (e.ad.data.creative) ? e.ad.data.creative.id : undefined;
      this.src = e.ad.mediaFileUrl;
      this.height = e.ad.height;
      this.width = e.ad.width;
      this.skippable = e.ad.skippable;
      this.duration = e.ad.duration;
    }

    this.lastPlayhead = 0;
    this.counter = 0;
    this.fireStart();
    this.fireJoin();
  }

  adEndListener(e) {
    let videoPlayhead = this.getVideo().getPlayhead();
    this.fireStop({
      adPlayhead: this.lastPlayhead,
      playhead: videoPlayhead
    });
    if (this.getVideo().requestBuilder.lastSent.adPosition === this.getNpawReference().Constants.AdPosition.Postroll){
      this.getVideo().fireStop();
    }
    this.lastPlayhead = 0;
  }

  quartileListener(e) {
    let position = 1;
    switch (e.quartile) {
      case 'midpoint':
        position = 2;
        break;
      case 'thirdQuartile':
        position = 3;
    };
    this.fireQuartile(position);
  }

  skipListener(e) {
    let videoPlayhead = this.getVideo().getPlayhead();
    this.fireSkip({
      adPlayhead: this.lastPlayhead,
      playhead: videoPlayhead
    });
    if (this.getVideo().requestBuilder.lastSent.adPosition === this.getNpawReference().Constants.AdPosition.Postroll) {
      this.getVideo().fireStop();
    }
    this.lastPlayhead = 0;
  }

  clickListener(e) {
    this.fireClick(e.clickThroughUrl);
  }

  errorListener(e) {
    const errObj = e.data ? e.data : e;
    this.fireError(errObj.code, errObj.message);
  }

  destroyListener(e) {
    this.player = null;
    this.tag = null;
    let videoPlayhead = this.getVideo().getPlayhead();
    this.fireStop({
      adPlayhead: this.lastPlayhead,
      playhead: videoPlayhead
    });
    this.lastPlayhead = 0;
    this.getVideo().removeAdsAdapter();
  }
}