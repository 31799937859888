import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import classnames from 'classnames';
import {lighten, rgba} from 'polished';
import React, {useRef} from 'react';
import {CSSTransition} from 'react-transition-group';
import styled, {css} from 'styled-components';

import {
    transition,
    TRANSITION_CLASSNAMES,
} from '../../../../../common/animations';
import gibson from '../../../../../common/font-helper';
import {A, Button} from '../../../../../common/normalized-styled-components';
import {white} from '../../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../../common/screen-sizes';

const ANIMATE_IN_TRANSITIONS = [
    {
        type: 'color',
        speed: 100,
        style: 'ease-in',
        delay: 400,
    },
    {
        type: 'transform',
        speed: 100,
        style: 'ease-in',
        delay: 200,
    },
];

const ANIMATION_DURATION_MS = ANIMATE_IN_TRANSITIONS.reduce(
    (duration, {speed, delay}) => duration + speed + delay,
    0
);

const baseStyles = css`
    box-sizing: border-box;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    transform-origin: left center;
    transition: ${transition('background-color', 'opacity')};
    background-color: ${rgba(white, 0.2)};
    padding: 0 14px;
    height: 40px;
    text-align: center;
    color: ${white};
    font: ${gibson({size: 14, lineHeight: 1.29})};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 60px;
        font: ${gibson({size: 24, lineHeight: 1.25})};
    `}

    ${({isBlock}) =>
        isBlock
            ? css`
                  display: flex;
                  width: 100%;
              `
            : css`
                  display: inline-flex;
                  min-width: 123px;
                  ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            min-width: 186px;
        `}
              `}

    /* stylelint-disable-next-line no-duplicate-selectors */
    &.${TRANSITION_CLASSNAMES.enter},
    &.${TRANSITION_CLASSNAMES.exit},
    &.${TRANSITION_CLASSNAMES.exitDone} {
        transform: scaleX(0);
        color: transparent;
    }

    &:hover,
    &:focus {
        outline: 0;
        background-color: ${({theme}) => lighten(0.07, theme.brandColor)};
    }

    &:disabled {
        opacity: 0.35;
        cursor: not-allowed;
    }

    &.${TRANSITION_CLASSNAMES.enter} {
        transition: ${ANIMATE_IN_TRANSITIONS.map(
                ({type, speed, style, delay}) =>
                    `${type} ${speed}ms ${style} ${delay}ms`
            ).join(', ')},
            ${transition('background-color', 'opacity')};
    }

    &.${TRANSITION_CLASSNAMES.enterActive} {
        transform: scaleX(1);
        color: ${white};
    }
`;

const StyledButton = styled(Button)`
    ${baseStyles}

    appearance: none;
    border: 0;
`;

const StyledLink = styled(A)`
    ${baseStyles}

    text-decoration: none;
`;

type Props = {
    /** Content of button, e.g. text */
    children: React.ReactNode;
    /** If it’s actually a link provide a href */
    href?: string;
    /** Whether the button fills its parent's width or not */
    isBlock?: boolean;
    /** Whether the button is visible (and thus animated in) or not */
    isVisible?: boolean;
    /** The HTML type of the button */
    type?: 'submit' | 'reset' | 'button';
    /** Additional CSS class(es) */
    className?: string;
    onClick?: React.MouseEventHandler;
};

const CtaButton = React.forwardRef<HTMLElement, Props>(
    (
        {
            children,
            href,
            isBlock,
            isVisible = true,
            className,
            type = 'button',
            ...htmlAttributes
        },
        ref
    ) => {
        const StyledElement = href ? StyledLink : StyledButton;
        const innerRef = useRef<HTMLLinkElement | HTMLButtonElement>(null);
        const transitionNodeRef = ref ?? innerRef;

        return (
            <CSSTransition
                in={isVisible}
                timeout={ANIMATION_DURATION_MS}
                classNames={TRANSITION_CLASSNAMES}
                nodeRef={transitionNodeRef}
            >
                <StyledElement
                    {...htmlAttributes}
                    {...{
                        href,
                        isBlock,
                        isVisible,
                    }}
                    ref={transitionNodeRef}
                    className={classnames('CtaButton', className)}
                    type={href ? undefined : type}
                >
                    {children}
                </StyledElement>
            </CSSTransition>
        );
    }
);

CtaButton.displayName = 'CtaButton';

export default CtaButton;
