/**
 * Represents an error related to a resource, with additional context.
 */
export default class ResourceError extends Error {
    /** A unique string to identify an error */
    readonly type: string = '';
    /** A HTTP response code */
    readonly statusCode: number = 0;
    /** A message to help the developer */
    readonly debugMessage: string = '';

    constructor({
        message,
        type = 'unknown',
        statusCode = 0,
        debugMessage,
    }: {
        message: string;
        type?: string;
        statusCode?: number;
        debugMessage: string;
    }) {
        super(message);

        this.type = type;
        this.statusCode = statusCode;
        this.debugMessage = debugMessage;
    }
}
