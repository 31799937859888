import {
    ANALYTICS_EVENT_NAME,
    SCRUBBER_TYPE,
    type ScreenConfig,
    type VideoState,
} from '@fsa-streamotion/player-state';
import {
    stylesWhenNot,
    stylesWhen,
} from '@fsa-streamotion/styled-component-helpers';

import noop from 'lodash/noop';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';

import {white} from '../../../../common/palette';
import {
    useEffectiveViewport,
    SCREEN_1280_DESKTOP,
    SCREEN_768_TABLET,
} from '../../../../common/screen-sizes';
import {effectiveViewportMediaQuery} from '../../../../common/theme-helpers';
import BA01CtrlBtn from '../../../../component-library/atoms/ba/01-ctrl-btn';
import {
    IC06Play,
    IC07Pau,
    IC14Skb,
    IC15Skf,
    IC218XtraInfo,
} from '../../../../component-library/atoms/ic';
import TM05CentreControls from '../../../../component-library/molecules/tm/05-centre-controls';
import {SEEK_ADJUSTMENT_S} from '../../../utils/constants';
import {usePlayerContext} from '../../context';
import {getScalingStylesByIndex} from '../utils/screen-layout-styles';

const LongPauseContainer = styled.div`
    ${stylesWhenNot('isVisible')`
        display: none;
    `}
`;

const ButtonContainer = styled.div`
    width: 30px;
    height: 30px;

    ${stylesWhen('isTabletUp')`
        width: 80px;
        height: 80px;
    `};
`;

const HudButton = styled(BA01CtrlBtn)`
    display: none;

    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_768_TABLET})`
        display: flex;
        margin-right: 34px;
    `}
`;

// Spread onto buttons to prevent propagating double-click events which would erroneously toggle fullscreen.
const preventDoubleClickFullScreen = {
    onDoubleClick: (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
    },
};

type Props = {
    screenIndex?: number;
    isVisible?: boolean;
    isLiveStream?: boolean;
    isHudEnabled?: boolean;
};

const CentreControls = ({
    screenIndex,
    isVisible,
    isLiveStream,
    isHudEnabled,
}: Props): React.ReactElement => {
    const prolongedPauseRef = useRef<HTMLDivElement>();
    const playerState = usePlayerContext();
    const {
        screenConfigs,
        generalConfig,
        isSingleLayout,
        globalState,
        globalActions,
    } = playerState ?? {};

    const {layoutStyle, screenOrder, isHudVisible, isInSplitViewMode} =
        globalState ?? {};
    const {setHudVisibility} = globalActions ?? {};
    const scalingTransformStyles =
        layoutStyle &&
        typeof screenIndex === 'number' &&
        typeof screenOrder?.[screenIndex] === 'number'
            ? getScalingStylesByIndex(
                  layoutStyle,
                  screenOrder?.[screenIndex] as number
              )
            : {};

    const screenConfigByIndex =
        typeof screenIndex === 'number' &&
        (screenConfigs?.[screenIndex] as ScreenConfig);
    const {
        actions = {},
        videoState = {},
        playbackData,
    } = screenConfigByIndex || {};
    const {togglePlayPause = noop, setCurrentTime = noop} =
        actions as ScreenConfig['actions'];
    const {
        isPlaying = false,
        seekingTime,
        isPausedFor5Seconds,
        isInAdBreak,
    } = videoState as VideoState;
    const {
        isProlongedPauseEnabled,
        onShowLongPause,
        onHideLongPause,
        onPlayerInteractionByType,
    } = generalConfig ?? {};

    const isDesktopUp = useEffectiveViewport(SCREEN_1280_DESKTOP);
    const isTabletUp = useEffectiveViewport(SCREEN_768_TABLET);

    const singleProlongedPause =
        screenIndex === 0 && isPausedFor5Seconds && isSingleLayout;
    const buttonSize = isTabletUp ? '80px' : '30px';

    useEffect(
        function renderLongPauseMetadata() {
            if (!isProlongedPauseEnabled || isInAdBreak) {
                return;
            }

            if (isPausedFor5Seconds) {
                if (prolongedPauseRef.current && playbackData?.metadata) {
                    onShowLongPause?.(
                        prolongedPauseRef.current,
                        playbackData.metadata
                    );
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (prolongedPauseRef.current) {
                    onHideLongPause?.(
                        prolongedPauseRef.current,
                        playbackData?.metadata
                    );
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isPausedFor5Seconds, isProlongedPauseEnabled, isInAdBreak]
    );

    const scrubberType =
        (playbackData?.scrubBarType &&
            SCRUBBER_TYPE[playbackData?.scrubBarType]) ??
        (isLiveStream
            ? SCRUBBER_TYPE.fromEdge
            : SCRUBBER_TYPE.currentAndDuration);
    const isCentreButtonEnabled = scrubberType !== SCRUBBER_TYPE.none;

    const skipForward = (): void => {
        setCurrentTime(seekingTime + SEEK_ADJUSTMENT_S);
        onPlayerInteractionByType?.(
            ANALYTICS_EVENT_NAME.SELECT_OPTION,
            `+${SEEK_ADJUSTMENT_S}`
        );
    };

    const skipBack = (): void => {
        setCurrentTime(seekingTime - SEEK_ADJUSTMENT_S);
        onPlayerInteractionByType?.(
            ANALYTICS_EVENT_NAME.SELECT_OPTION,
            `-${SEEK_ADJUSTMENT_S}`
        );
    };

    const showHud = (): void => {
        setHudVisibility?.(true);
    };

    const skipBackButton = !!isCentreButtonEnabled && !isInAdBreak && (
        <BA01CtrlBtn
            {...preventDoubleClickFullScreen}
            aria-label={`Rewind ${SEEK_ADJUSTMENT_S} seconds`}
            key="rewind-15-button"
            onClick={skipBack}
            shouldUseParentContainer={true}
        >
            <IC14Skb color={white} hasDropshadow={true} size={buttonSize} />
        </BA01CtrlBtn>
    );

    const skipForwardButton = !!isCentreButtonEnabled && !isInAdBreak && (
        <BA01CtrlBtn
            {...preventDoubleClickFullScreen}
            aria-label={`Skip ${SEEK_ADJUSTMENT_S} seconds`}
            key="skip-15-button"
            onClick={skipForward}
            shouldUseParentContainer={true}
        >
            <IC15Skf color={white} hasDropshadow={true} size={buttonSize} />
        </BA01CtrlBtn>
    );

    const playButton = !!isCentreButtonEnabled && (
        <BA01CtrlBtn
            {...preventDoubleClickFullScreen}
            aria-label="Toggle play/pause"
            key="play-button"
            isPressed={isPlaying}
            onClick={togglePlayPause}
            shouldUseParentContainer={true}
        >
            {isPlaying ? (
                <IC07Pau color={white} hasDropshadow={true} />
            ) : (
                <IC06Play color={white} hasDropshadow={true} />
            )}
        </BA01CtrlBtn>
    );

    const longPauseMetadata = isProlongedPauseEnabled ? (
        // Its children is rendered by onShowLongPause & onHideLongPause
        <LongPauseContainer
            className="ProlongedPauseMetadataContainer"
            isVisible={isPausedFor5Seconds}
            ref={prolongedPauseRef}
        />
    ) : null;

    // We only show the play button during prolonged pause on desktop and up.
    const desktopUpMiddleCentreControls: [string, React.ReactNode][] | null =
        singleProlongedPause ? [['play-button', playButton]] : null;

    // tablet and down, playback controls are hidden during prolonged pause.
    const desktopDownMiddleCentreControls: [string, React.ReactNode][] | null =
        singleProlongedPause && !isTabletUp
            ? null
            : [
                  ['skip-back', skipBackButton],
                  ['play-button', playButton],
                  ['skip-forward', skipForwardButton],
              ];

    const keyIndex = 0;
    const componentIndex = 1;
    const middleCentreControls = (
        isDesktopUp
            ? desktopUpMiddleCentreControls
            : desktopDownMiddleCentreControls
    )?.map((data) => (
        <ButtonContainer key={data[keyIndex]} isTabletUp={isTabletUp}>
            {data[componentIndex]}
        </ButtonContainer>
    ));

    const hudButton =
        isTabletUp && !isHudVisible && isHudEnabled && !isInSplitViewMode && !isInAdBreak ? (
            <HudButton
                onClick={showHud}
                isLabelOnLeft={true}
                label="Info Centre"
            >
                <IC218XtraInfo color={white} hasDropshadow={true} />
            </HudButton>
        ) : null;

    return (
        <TM05CentreControls
            isVisible={isVisible}
            controlsStyle={scalingTransformStyles}
            middleCentreControls={middleCentreControls}
            middleLeftControls={longPauseMetadata}
            middleRightControls={hudButton}
            hasBackgroundColor={!isDesktopUp || singleProlongedPause}
        />
    );
};

CentreControls.displayName = 'CentreControls';

export default observer(CentreControls);
