import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import Hammer from 'react-hammerjs';
import debounce from 'lodash/debounce';

import {scrollLeft} from '@fsa/fs-commons/lib/utils/animations';
import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';
import ImageFallback from '@fsa-streamotion/streamotion-web-fs-ui/src/components/image-fallback/image-fallback';
import RatioAsset from '@fsa-streamotion/streamotion-web-fs-ui/src/components/ratio-asset/ratio-asset';
import {browserSupportsPassive} from '@fsa/fs-commons/lib/utils/browser-support';

export default class Shows extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: 0
        };
    }

    componentDidMount() {
        this.debouncedScrollToSelectedItem = debounce(this.scrollToSelectedItem.bind(this), 200, {
            maxWait: 250
        });
        window.addEventListener(
            'resize',
            this.debouncedScrollToSelectedItem,
            browserSupportsPassive ? {passive: true} : false
        );
        this.scrollToSelectedItem();
    }

    componentDidUpdate() {
        this.scrollToSelectedItem();
    }

    componentWillUnmount() {
        window.removeEventListener(
            'resize',
            this.debouncedScrollToSelectedItem,
            browserSupportsPassive ? {passive: true} : false
        );
    }

    scrollToSelectedItem() {
        if (!this.props.showsList.length) {
            return null;
        }

        const scrollComponent = this.scrollComponent.getBoundingClientRect();
        const scrollComponentWidth  = this.scrollComponent.clientWidth;
        const activeItem = this.activeItem.getBoundingClientRect();
        const scrollAdjustment = 0.2 * scrollComponentWidth;
        let scrollMeasurement;

        if (activeItem.left > scrollComponent.left) {
            scrollMeasurement = activeItem.right - scrollComponent.right + scrollAdjustment;
        } else {
            scrollMeasurement = activeItem.left - scrollComponent.left - scrollAdjustment;
        }
        scrollLeft(this.scrollComponent, scrollMeasurement, 400);
    }

    selectNextItem(direction, index) {
        const scrollModifier = direction === 'left' ? -1 : 1;
        const nextItem = index === undefined ? (this.state.selected + scrollModifier) : index;

        if (nextItem >= 0 && nextItem < this.props.showsList.length) {
            const newState = {
                selected: nextItem
            };

            this.setState(newState);
        }
    }

    onSwipe(event) {
        if (event.direction === 4) {
            this.selectNextItem('left');
        } else if (event.direction === 2) {
            this.selectNextItem('right');
        }
    }

    renderImage(item, index) {
        const listClassName = classNames(
            'fiso-hawk-shows__carousel-list-item',
            {'fiso-hawk-shows__carousel-list-item--active': index === this.state.selected}
        );

        return (
            <li
                key={item.id}
                className={listClassName}
                ref={(el) => {
                    if (index === this.state.selected) {
                        this.activeItem = el;
                    }
                }}
                itemProp="itemListElement"
                itemScope={true}
                itemType="http://schema.org/ListItem">
                <meta itemProp="position" content={index + 1} />
                <a
                    href={item.link}
                    onFocus={() => {
                        this.selectNextItem(false, index);
                    }}
                    itemProp="item"
                    itemScope={true}
                    itemType="http://schema.org/TVSeries">
                    <meta itemProp="name" content={item.title} />
                    <meta itemProp="url" content={item.link} />
                    <figure
                        className="fiso-hawk-shows__carousel-figure"
                        itemScope={true}
                        itemType="http://schema.org/ImageObject">
                        <RatioAsset ratio="16x9">
                            <ImageFallback
                                fallbackSrc={this.props.fallbackImage}>
                                <img
                                    alt={item.title}
                                    className="fiso-hawk-shows__carousel-figure-img"
                                    src={item.imageSource}
                                    itemProp="contentUrl" />
                            </ImageFallback>
                        </RatioAsset>
                        <figcaption className="fiso-hawk-shows__carousel-figure-caption" itemProp="name">{item.title}</figcaption>
                    </figure>
                </a>
            </li>
        );
    }

    renderArrow(direction) {
        let isDisabled;
        const iconName = (direction === 'left') ? 'back-1' : 'next-1';

        if ((direction === 'left' && this.state.selected === 0) ||
            (direction === 'right' && this.state.selected === (this.props.showsList.length - 1))) {
            isDisabled = true;
        }

        const buttonClass = classNames(
            'fiso-hawk-shows__carousel-arrow',
            `fiso-hawk-shows__carousel-arrow--${direction}`,
            {
                'fiso-hawk-shows__carousel-arrow--disabled': isDisabled
            }
        );

        return (
            <button
                aria-label={'Select item on the' + direction}
                className={buttonClass}
                disabled={isDisabled}
                type="button"
                onClick={() => {
                    this.selectNextItem(direction);
                }}>
                <Fsvg name={iconName} ariaHidden="true" />
            </button>
        );
    }

    render() {
        if (!this.props.showsList.length) {
            return null;
        }

        const listClassName = classNames(
            'fiso-hawk-shows__carousel-list-item',
            'fiso-hawk-shows__carousel-list-item--hidden'
        );

        return (
            <div className="fiso-hawk-shows fsui-normalise">
                <Hammer onSwipe={this.onSwipe.bind(this)}>
                    <div className={'fiso-hawk-shows__carousel'}>
                        {this.renderArrow('left')}
                        <div className={'fiso-hawk-shows__carousel-content'} ref={(el) => { this.scrollComponent = el; }}>
                            <ul className="fiso-hawk-shows__carousel-list" itemScope={true} itemType="http://schema.org/ItemList">
                                {this.props.showsList.map(this.renderImage.bind(this))}
                                <li key="last" className={listClassName}>
                                    <RatioAsset ratio="16x9">
                                        <div />
                                    </RatioAsset>
                                </li>
                            </ul>
                        </div>
                        {this.renderArrow('right')}
                    </div>
                </Hammer>
            </div>
        );
    }
}

Shows.defaultProps = {
    fallbackImage: 'https://fiso.foxsports.com.au/assets/video/latest/video/images/error-generic.jpg', // @TODO - reconfirm src
    showsList: []
};

Shows.propTypes = {
    fallbackImage: propTypes.string,
    showsList: propTypes.arrayOf(propTypes.shape({
        id: propTypes.number,
        imageSource: propTypes.string,
        link: propTypes.string,
        title: propTypes.string
    }))
};
