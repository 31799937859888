export default class BrightcoveFreewheelAdsAdapter {
  isUsed() {
    return !this.player.ima3 && this.player.ads;
  }

  getVersion() {
    return '7.0.4-videojs-bcove-freewheel-ads-jsclass';
  }

  getPlayhead() {
    if (this.player.ads.ad) {
      return this.player.ads.ad.currentTime();
    }
    return undefined;
  }

  getPosition() {
    switch (this.player.ads.ad.type) {
      case 'PREROLL':
        return this.getNpawReference().Constants.AdPosition.Preroll;
      case 'POSTROLL':
        this.videoEnded = true;
        return this.getNpawReference().Constants.AdPosition.Postroll;
    }
    return this.getNpawReference().Constants.AdPosition.Midroll;
  }

  getResource() {
    if (this.player.ads.contentSrc) {
      return this.player.ads.contentSrc;
    }
    return undefined;
  }

  getTitle() {
    if (this.player.ads.ad && this.player.ads.ad.id) {
      return this.player.ads.ad.id;
    }
    return undefined;
  }

  getDuration() {
    if (this.player.ads.ad && this.player.ads.ad.duration) {
      this.lastDuration = this.player.ads.ad.duration;
      return this.lastDuration;
    }
    return undefined;
  }

  getPlayerName() {
    return 'Freewheel';
  }

  getPlayerVersion() {
    var ret = null;
    if (typeof tv !== 'undefined' && tv.freewheel && tv.freewheel.SDK) {
      ret = tv.freewheel.SDK.version;
    } else {
      return this.player.ads.VERSION;
    }
    return ret
  }

  registerListeners() {
    this.monitorPlayhead(true, false);
    this.references = [];
    this.references['ads-ad-started'] = this.adStartListener.bind(this);
    this.references['ads-ad-ended'] = this.adEndedListener.bind(this);
    this.references['ads-pause'] = this.adPausedListener.bind(this);
    this.references['ads-play'] = this.adResumedListener.bind(this);
    this.references['ads-first-quartile'] = this.firstQuartileListener.bind(this);
    this.references['ads-midpoint'] = this.secondQuartileListener.bind(this);
    this.references['ads-third-quartile'] = this.thirdQuartileListener.bind(this);
    this.references['ads-pod-ended'] = this.adPodEndListener.bind(this);
    this.references['adserror'] = this.errorListener.bind(this);
    
    for (var key in this.references) {
      this.player.on(key, this.references[key]);
    }
  }

  firstQuartileListener(e) {
    this.fireQuartile(1);
  }

  secondQuartileListener(e) {
    this.fireQuartile(2);
  }

  thirdQuartileListener(e) {
    this.fireQuartile(3);
  }

  adPodEndListener(e) {
    this.fireBreakStop();
  }

  adStartListener(e) {
    this.fireStart();
    this.fireJoin();
  }

  adPausedListener(e) {
    this.firePause();
  }

  adResumedListener(e) {
    this.fireResume();
  }

  adEndedListener(e) {
    this.fireStop({
      adPlayhead: this.lastDuration
    });
    this.fireStop();
    const videoAdapter = this.getVideo().getAdapter();
    if (videoAdapter){
      if(this.videoEnded){
        videoAdapter.fireStop();
        this.videoEnded = null;
      } else{
        videoAdapter.fireResume();
      }
    } 
  }

  errorListener(e) {
    this.fireError('Ad error');
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
    if (this.player && this.references) {
      for (var key in this.references) {
        this.player.off(key, this.references[key]);
      }
      this.references = [];
    }
  }
}