import {ERROR_CODES, type BaseBitrateInfo} from '@fsa-streamotion/player-tech';

import isEqual from 'lodash/isEqual';
import {action} from 'mobx';
import {
    type Observable,
    type OperatorFunction,
    combineLatest,
    combineLatestWith,
    concat,
    delay,
    distinctUntilChanged,
    EMPTY,
    filter,
    first,
    fromEvent,
    iif,
    interval,
    merge,
    of,
    map,
    share,
    shareReplay,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
    throttleTime,
    timer,
    withLatestFrom,
    pairwise,
    skipWhile,
    asyncScheduler,
} from 'rxjs';

import type PlayerState from '..';
import {calculateAdSecondsPassed} from '../../utils/ad-capabilities';
import youboraTrackingForSingleVideo, {
    YouboraPlugin,
} from '../../utils/analytics/youbora';
import {
    PLAYER_TECH_VIDEO_QUALITY_CATEGORY,
    CAPTIONS_OFF_INDEX,
    AUDIO_DEFAULT_INDEX,
    PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS,
    PLAYER_LAYOUT_STYLE,
} from '../../utils/constants';
import {filterNullish} from '../../utils/custom-rx-operators';
import getErrorDetails, {
    type ErrorDetails,
} from '../../utils/get-error-details';
import getNextLayout from '../../utils/get-next-layout';
import observableFromCallback from '../../utils/observable-from-callback';
import getProgressiveThumbnailRegistry from '../../utils/thumbnails/progressive-thumbnail-registry';
import type {
    PlaybackData,
    PlayerStateSubjects,
    VideoStateStreamsToSubscribe,
    UpNext,
    VideoState,
    VideoStreamsPerScreen,
} from '../types';
import getAdDataStreams from './get-ad-data-streams';

/**
 * The delay time the play waits before showing & hiding the content warning info
 */

type BufferDetail = CustomEvent<{isBuffering: boolean; isSeeking: boolean}>;

type LiveStreamDetail = CustomEvent<{isLive: boolean}>;

type LiveStreamOnEdge = CustomEvent<{isOnEdge: boolean}>;

type TimeUpdate = {
    target: {
        currentTime: number;
    };
};

type BitrateLevelsLoaded = CustomEvent<{
    levels: BaseBitrateInfo[];
    canManuallyAdjustBitrate: boolean;
}>;

type BufferingPercentage = CustomEvent<{bufferPercentage: number}>;

type CustomErrorEvent = {
    target: EventTarget & {
        error: {
            code?: number;
            message?: string;
        };
    };
};

export type ToStreamInfo = {
    id: string;
    start: number;
};

export type PeriodSwitchStarted = CustomEvent<{
    periodInfo: {
        fromStreamInfo: {
            id?: string;
        };
        toStreamInfo: ToStreamInfo;
    };
}>;

export type PeriodSwitchCompleted = CustomEvent<{
    periodInfo: {
        toStreamInfo: ToStreamInfo;
    };
}>;

export const CONTENT_WARNING_DELAY_MS = {
    DELAY_DISPLAY: 300,
    SHOW_PERIOD: 5_000,
};

export type InferVideoStateValueType<T extends keyof VideoState> =
    VideoState[T];

export const INITIAL_VIDEO_STATE: VideoState = {
    hasFiredFirstPlay: false,
    isEnded: false,
    isInErrorRecovery: false,
    isLiveStream: false,
    isLiveEnded: false,
    isOnEdge: false,
    isPlaying: false,
    isSeeking: false,
    isBtybVisible: true,
    isPlaying4k: false,

    duration: 0,
    currentTime: 0,
    seekingTime: 0,
    latestSecondsViewed: 0,

    currentTextTrackIndex: -1,
    playbackRate: 1,
    hasVideoElementPlayed: false,
    playerTechErrorCode: null,
    bufferedTimeRanges: [],
    buffering: {
        isStalledBuffering: false,
        stalledBufferingPercentage: 0,
    },

    videoQuality: PLAYER_TECH_VIDEO_QUALITY_CATEGORY.AUTO,
    initialQuality: PLAYER_TECH_VIDEO_QUALITY_CATEGORY.AUTO,
    bitrates: [],

    captions: [],
    currentCaptionTrackIndex: CAPTIONS_OFF_INDEX,
    currentAudioTrackIndex: AUDIO_DEFAULT_INDEX,
    isPausedFor5Seconds: false,
    isCentreControlsVisible: false,
    isKeyMomentsVisible: false,
    thumbnails: [],

    /**
     * isInAdBreak If current time is in an ad-break
     */
    isInAdBreak: false,

    /**
     * adBreakAdCount The number of ads in a currently playing ad-break
     */
    adBreakAdCount: 0,

    /**
     * adCurrentTime Time elapsed in a currently playing ad
     */
    adCurrentTime: 0,

    /**
     *adDuration Duration of currently playing ad
     */
    adDuration: 0,

    /**
     * adIndex The index of the currently playing ad within an ad-break
     */
    adIndex: 0,
};

type Params = {
    playerStateInstance: PlayerState;
    playbackDataStreams: Observable<PlaybackData | undefined>[];
    subjects: PlayerStateSubjects;
};

type VideoStateStreams = {
    streamsToSubscribe: VideoStateStreamsToSubscribe;
    streams: VideoStreamsPerScreen;
} | null;

export default function createVideoStateStreams({
    playerStateInstance,
    playbackDataStreams,
    subjects,
}: Params): VideoStateStreams {
    const {generalConfig} = playerStateInstance;

    if (generalConfig === null) {
        return null;
    }

    const {
        errorHandlersByCode,
        logger,
        onChangeCaptions,
        waitMsShowingLongPause,
        youboraAccountId,
        youboraHost,
        getCaptionsPreference,
    } = generalConfig;

    // We construct these video state streams for each individual screen
    // We pipe off playbackData$ so they reset every time the video source changes
    const streamsPerScreen = playbackDataStreams.map(
        (playbackData$, screenIndex) => {
            const screenConfig =
                playerStateInstance?.screenConfigs?.[screenIndex];

            if (!screenConfig) {
                return null;
            }

            const {videoElement, playerTech, videoState, actions} =
                screenConfig;

            // This creates a `tap` (or bacon `doAction`) that updates a field in the video state with the latest value
            // Usage: `foo$.pipe(updateVideoState(currentTime))`
            const updateVideoState = <T extends keyof VideoState>(
                fieldName: T
            ): OperatorFunction<
                InferVideoStateValueType<T>,
                InferVideoStateValueType<T>
            > =>
                tap(
                    action((value: InferVideoStateValueType<T>) => {
                        videoState[fieldName] = value;
                    })
                );

            const eventPlay$ = fromEvent(videoElement, 'play').pipe(share());
            const eventPlaying$ = fromEvent(videoElement, 'playing').pipe(
                share()
            );
            const eventPause$ = fromEvent(videoElement, 'pause').pipe(share());
            const eventEnded$ = fromEvent(videoElement, 'ended').pipe(share());
            const eventSeeking$ = fromEvent(videoElement, 'seeking');
            const eventSeeked$ = fromEvent(videoElement, 'seeked');
            const eventTimeUpdate$ = fromEvent<TimeUpdate>(
                videoElement,
                'timeupdate'
            ).pipe(
                // This will emit multiple times a second depending on CPU load: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/timeupdate_event
                // We only want to emit on the earliest unique second
                // throttling can cause de-sync of our current time and state
                // NOTE: We don't use this value to populate state, we will update state from playerTech in currentTime$
                map(({target}) => Math.floor(target.currentTime)),
                distinctUntilChanged(),
                share()
            );
            const eventError$ = fromEvent<CustomErrorEvent>(
                videoElement,
                'error'
            ).pipe(share());
            const eventFsSourceUpdated$ = fromEvent(
                videoElement,
                'fs-source-updated'
            ).pipe(share());
            const eventFsBitrateChanged$ = fromEvent(
                videoElement,
                'fs-bitrate-level-change'
            );
            const eventFsBitrateLoaded$ = fromEvent<BitrateLevelsLoaded>(
                videoElement,
                'fs-bitrate-levels-loaded'
            ).pipe(share());
            const eventMetadataLoaded$ = fromEvent(
                videoElement,
                'loadedmetadata'
            ).pipe(share());
            const eventDataLoaded$ = fromEvent(videoElement, 'loadeddata').pipe(
                share()
            ); // Emmits when the video has loaded into the player. However, this may not fire on tablets according to MDN
            const eventCaptionsUpdated$ = fromEvent(
                videoElement,
                'fs-captions-updated'
            );
            const eventAudioTracksUpdated$ = fromEvent(
                videoElement,
                'fs-audio-tracks-updated'
            );
            const eventIsLiveStream$ = fromEvent<LiveStreamDetail>(
                videoElement,
                'fs-live-stream-is-live'
            ).pipe(share());
            const eventFsPeriodSwitchStarted$ = fromEvent<PeriodSwitchStarted>(
                videoElement,
                'fs-period-switch-started'
            );
            const eventFsPeriodSwitchCompleted$ =
                fromEvent<PeriodSwitchCompleted>(
                    videoElement,
                    'fs-period-switch-completed'
                );

            const firstPlay$ = playbackData$.pipe(
                tap(
                    action(() => {
                        // Reset video state when playing another video
                        Object.assign(videoState, INITIAL_VIDEO_STATE);

                        // Clear key moments from previous video
                        screenConfig.keyEvent = null;
                    })
                ),
                switchMap(() =>
                    eventPlay$.pipe(
                        first(),
                        tap(
                            action(() => {
                                videoState.hasFiredFirstPlay = true;
                            })
                        )
                    )
                ),
                share()
            );

            const resetStartScreenOnFirstPlay$ = firstPlay$.pipe(
                tap(function resetStartScreenDataOnFirstPlay() {
                    screenConfig.startScreenData = {};
                })
            );

            const INTERACTION_THROTTLE_MS = 500;
            const throttledInteractionType$: Observable<{type: null | string}> =
                subjects.interactionSubject.pipe(
                    throttleTime(INTERACTION_THROTTLE_MS, asyncScheduler, {
                        trailing: false,
                        leading: true,
                    }),
                    startWith({type: null})
                );

            const globalClicks$ = throttledInteractionType$.pipe(
                filter(({type}) => type === 'click'),
                map(() => true),
                share()
            );

            const globalMouseMoves$ = throttledInteractionType$.pipe(
                filter(({type}) => type === 'mousemove'),
                map(() => true),
                share()
            );

            const currentTime$ = playbackData$.pipe(
                switchMap(() => merge(of(), firstPlay$, eventTimeUpdate$)),
                shareReplay(1),
                // NOTE: Not caching currentTime via shareReplay because we don't want a stale value
                map(() => playerTech?.currentTime || 0),
                distinctUntilChanged()
            );

            const isPlaying$ = playbackData$.pipe(
                switchMap(() =>
                    merge(
                        of(false),
                        eventPlay$.pipe(map(() => true)),
                        eventPlaying$.pipe(map(() => true)),
                        eventPause$.pipe(map(() => false)),
                        eventEnded$.pipe(map(() => false))
                    ).pipe(
                        distinctUntilChanged(), // Like skipDuplicates
                        updateVideoState('isPlaying')
                    )
                ),
                shareReplay({bufferSize: 1, refCount: true})
            );

            const isOutOfLongPausing$ = merge(
                isPlaying$,
                globalClicks$,
                globalMouseMoves$
            ).pipe(
                filter(Boolean),
                map(() => false),
                updateVideoState('isPausedFor5Seconds'),
                share()
            );

            const triggerCountdown$ = merge(
                eventPause$.pipe(map(() => true)),
                isOutOfLongPausing$
            );

            // The duration is specified by user (default 5 seconds)
            const timerToShowLongPause$ = combineLatest([
                isPlaying$,
                subjects.isUserHoldingControlsSubject,
            ]).pipe(
                switchMap(([isPlaying, isUserHoldingControls]) =>
                    isPlaying || isUserHoldingControls
                        ? EMPTY
                        : timer(waitMsShowingLongPause)
                )
            );
            const isPausedFor5Seconds$ = playbackData$.pipe(
                switchMap(() => triggerCountdown$),
                switchMap(() =>
                    timerToShowLongPause$.pipe(
                        map(() => true),
                        updateVideoState('isPausedFor5Seconds'),
                        takeUntil(isOutOfLongPausing$)
                    )
                )
            );

            const isLiveStream$ = playbackData$.pipe(
                switchMap(() =>
                    eventIsLiveStream$.pipe(
                        // In case the fs-live-stream-is-live event is triggered by the manifest loading, get the live state directly.
                        startWith({
                            detail: {
                                isLive:
                                    playerTech.currentPlaybackHandler &&
                                    'controllerLivestreamInstance' in
                                        playerTech.currentPlaybackHandler
                                        ? playerTech.currentPlaybackHandler
                                              ?.controllerLivestreamInstance
                                              ?.live ?? false
                                        : false,
                            },
                        }),
                        map(
                            (eventIsLiveStream) =>
                                eventIsLiveStream.detail.isLive
                        ),
                        startWith(false),
                        updateVideoState('isLiveStream'),
                        distinctUntilChanged(),
                        shareReplay({refCount: true, bufferSize: 1})
                    )
                )
            );

            const isLiveEvent$ = isLiveStream$.pipe(
                withLatestFrom(playbackData$),
                filter(
                    ([isLiveStream, playbackData]) =>
                        !!isLiveStream &&
                        playbackData?.contentItem?.asset.assetType !==
                            'live-linear'
                )
            );

            const isLiveEnded$ = playbackData$.pipe(
                switchMap(() =>
                    eventIsLiveStream$.pipe(
                        map(
                            (eventIsLiveStream) =>
                                eventIsLiveStream.detail.isLive
                        ),
                        skipWhile((isLive) => !isLive), // filter out non-live stream
                        pairwise(),
                        map(
                            ([previousLiveStatus, currentLiveStatus]) =>
                                videoState.isLiveEnded ||
                                (previousLiveStatus && !currentLiveStatus)
                        ),
                        distinctUntilChanged(),
                        updateVideoState('isLiveEnded'),
                        startWith(false),
                        share()
                    )
                )
            );

            const isOnEdge$ = playbackData$.pipe(
                switchMap(() =>
                    fromEvent<LiveStreamOnEdge>(
                        videoElement,
                        'fs-live-stream-is-on-edge'
                    ).pipe(
                        map(
                            (liveStreamOnEdge) =>
                                liveStreamOnEdge.detail.isOnEdge
                        ),
                        startWith(false),
                        updateVideoState('isOnEdge'),
                        distinctUntilChanged()
                    )
                )
            );

            const playbackRate$ = playbackData$.pipe(
                switchMap(() =>
                    fromEvent(videoElement, 'ratechange').pipe(
                        startWith(true), // sample playerTech immediately
                        map(() => playerTech.playbackRate),
                        distinctUntilChanged(),
                        updateVideoState('playbackRate')
                    )
                )
            );

            const isSeeking$ = playbackData$.pipe(
                switchMap(() =>
                    merge(
                        eventSeeking$.pipe(map(() => true)),
                        eventSeeked$.pipe(map(() => false)),
                        eventPlaying$.pipe(map(() => false))
                    ).pipe(
                        startWith(false),
                        distinctUntilChanged(), // Like skipDuplicates
                        updateVideoState('isSeeking')
                    )
                )
            );

            // When seeking we are updating playerTech.currentTime and listening to the seeking event.
            // currentTime$ is only listening to the timeUpdated event
            const seekingTime$ = playbackData$.pipe(
                // reset seeking time to 0 when we change playbackData
                switchMap(() =>
                    concat(
                        of(0),
                        merge(eventSeeking$, eventTimeUpdate$).pipe(
                            map(() => playerTech?.currentTime || 0) // There's a bug in playerTech where it throws an error if you request currentTime from the dash playback handler while it's tearing down
                        )
                    ).pipe(distinctUntilChanged(), share())
                )
            );

            const duration$ = playbackData$.pipe(
                switchMap(() =>
                    concat(
                        of(0),
                        fromEvent(videoElement, 'durationchange').pipe(
                            map(() => playerTech.duration ?? 0)
                        )
                    ).pipe(startWith(0), distinctUntilChanged(), share())
                )
            );

            function requestUpNext(): Observable<UpNext> {
                return observableFromCallback<UpNext>((callback) =>
                    playerStateInstance.globalActions?.updateUpNextData(
                        playerStateInstance.activeScreenConfig?.playbackData,
                        callback
                    )
                );
            }

            const updateUpNextDataWithRandomInterval$ =
                subjects.splitViewLayoutTypeSubject.pipe(
                    filter(
                        (type) => type === PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE
                    ),
                    switchMap(() =>
                        isLiveEvent$.pipe(
                            filter(Boolean),
                            withLatestFrom(playbackData$),
                            switchMap(([, playbackData]) => {
                                const pollingIntervalSeconds =
                                    playbackData?.upNextConfig
                                        ?.pollingIntervalSeconds;

                                if (!pollingIntervalSeconds) {
                                    return EMPTY;
                                }

                                const randomPollingIntervalSeconds =
                                    pollingIntervalSeconds +
                                    Math.random() * pollingIntervalSeconds;

                                return interval(
                                    randomPollingIntervalSeconds * 1000
                                ).pipe(switchMap(requestUpNext));
                            }),
                            share(),
                            tap(
                                action((upNext: UpNext) => {
                                    playerStateInstance.setUpNext(upNext);
                                })
                            )
                        )
                    )
                );

            const REFRESH_UP_NEXT_BUFFER_IN_SEC = 5;

            const updateUpNextDataWhenLiveEnded$: Observable<UpNext> =
                subjects.splitViewLayoutTypeSubject.pipe(
                    filter(
                        (type) => type === PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE
                    ),
                    switchMap(() =>
                        isLiveEnded$.pipe(
                            withLatestFrom(playbackData$),
                            filter(
                                ([isLiveEnded, playbackData]) =>
                                    !!isLiveEnded &&
                                    !!playbackData?.upNextConfig
                                        ?.requestOnEndOfLive
                            ),
                            switchMap(() =>
                                combineLatest([currentTime$, duration$])
                            ),
                            filter(([currentTime, duration]) => {
                                const validDuration = duration || Infinity;
                                const upNextDuration =
                                    playerStateInstance?.globalState?.upNext
                                        ?.durationInSeconds ?? 0;

                                return (
                                    currentTime >=
                                    validDuration -
                                        (upNextDuration +
                                            REFRESH_UP_NEXT_BUFFER_IN_SEC)
                                );
                            }),
                            take(1),
                            switchMap(requestUpNext),
                            share(),
                            tap(
                                action((upNext: UpNext) => {
                                    playerStateInstance.setUpNext(upNext);
                                })
                            )
                        )
                    )
                );

            const latestSecondsViewed$ = firstPlay$.pipe(
                switchMap(() =>
                    currentTime$.pipe(
                        map((currentTime) =>
                            Math.max(
                                Number(videoState?.latestSecondsViewed),
                                currentTime
                            )
                        ),
                        startWith(0),
                        distinctUntilChanged(),
                        updateVideoState('latestSecondsViewed'),
                        share()
                    )
                )
            );

            const isVideoElementPlaying$ = playbackData$.pipe(
                switchMap(() =>
                    merge(
                        eventPlaying$.pipe(map(() => true)),
                        eventPause$.pipe(map(() => false)),
                        eventEnded$.pipe(map(() => false))
                    ).pipe(startWith(false), distinctUntilChanged())
                )
            );

            const hasVideoElementPlayed$ = playbackData$.pipe(
                switchMap(() =>
                    isVideoElementPlaying$.pipe(
                        filter(Boolean),
                        map(() => true),
                        take(1),
                        startWith(false),
                        updateVideoState('hasVideoElementPlayed')
                    )
                )
            );

            const resetError$: Observable<null> = merge(
                isPlaying$,
                eventFsSourceUpdated$
            ).pipe(
                map(() => null),
                share()
            );

            const playerTechErrorCode$ = playbackData$.pipe(
                switchMap(() =>
                    merge(
                        resetError$,
                        eventError$.pipe(
                            map(
                                (errorEvent: CustomErrorEvent) =>
                                    playerTech?.error ||
                                    errorEvent?.target?.error
                            ),
                            map(
                                (error) =>
                                    error?.code ??
                                    ERROR_CODES.CUSTOM_ERR_UNKNOWN
                            )
                        )
                    ).pipe(
                        startWith(playerTech?.error?.code),
                        distinctUntilChanged(),
                        updateVideoState('playerTechErrorCode'),
                        shareReplay({bufferSize: 1, refCount: true})
                    )
                )
            );

            const isInErrorRecovery$ = playbackData$.pipe(
                switchMap(() =>
                    merge(
                        eventPlaying$.pipe(map(() => false)),
                        eventError$.pipe(map(() => false)),
                        fromEvent(videoElement, 'fs-source-error-retry').pipe(
                            map(() => true)
                        )
                    ).pipe(
                        startWith(false),
                        distinctUntilChanged(),
                        updateVideoState('isInErrorRecovery')
                    )
                )
            );

            const isEnded$ = playbackData$.pipe(
                switchMap(() =>
                    merge(
                        eventPlaying$.pipe(map(() => false)),
                        eventFsSourceUpdated$.pipe(map(() => false)),
                        eventEnded$.pipe(map(() => true))
                    ).pipe(
                        startWith(false),
                        distinctUntilChanged(),
                        updateVideoState('isEnded')
                    )
                )
            );

            const buffering$ = playbackData$.pipe(
                switchMap(() =>
                    combineLatest({
                        isStalledBuffering: fromEvent<BufferDetail>(
                            videoElement,
                            'fs-stalled-buffering'
                        ).pipe(
                            map(
                                (event: BufferDetail) =>
                                    event.detail.isBuffering
                            ),
                            switchMap((isBuffering: boolean) =>
                                iif(
                                    () => isBuffering,
                                    // delay 1000ms update buffering to prevent the short buffering (less than 1000ms, e.g. jumping gaps)
                                    // prevent short buffering only when video has fired first play
                                    of(true).pipe(
                                        delay(
                                            videoState.hasFiredFirstPlay
                                                ? 1000
                                                : 0
                                        )
                                    ),
                                    of(false)
                                )
                            ),
                            startWith(false),
                            distinctUntilChanged()
                        ),
                        stalledBufferingPercentage:
                            fromEvent<BufferingPercentage>(
                                videoElement,
                                'fs-stalled-buffering-percentage'
                            ).pipe(
                                map((event) => event.detail.bufferPercentage),
                                map(Math.round),
                                startWith(0),
                                distinctUntilChanged()
                            ),
                    })
                ),
                updateVideoState('buffering')
            );

            const errorInfo$ = eventError$.pipe(
                map(() => ({
                    errorInfo: playerTech?.error,
                    extraErrorInfo: playerTech?.errorDetail,
                }))
            );

            const playerTechErrorDetails$ = playbackData$.pipe(
                switchMap(() =>
                    merge(resetError$, errorInfo$).pipe(
                        map((code) =>
                            getErrorDetails(code, errorHandlersByCode, logger)
                        ),
                        startWith(null),
                        distinctUntilChanged(),
                        tap(
                            action((videoDetails: ErrorDetails) => {
                                screenConfig.playbackData = {
                                    ...screenConfig.playbackData,
                                    nativePlayerTechError: videoDetails,
                                }; // emit a new object as a signal to mobx
                            })
                        )
                    )
                )
            );

            const bitrateCurrentQuality$ = merge(
                eventFsBitrateChanged$,
                eventFsBitrateLoaded$
            ).pipe(map(() => playerTech.bitrateCurrentQuality));

            const initialQuality$ = eventPlay$.pipe(
                first(),
                map(
                    () =>
                        playerTech.bitrateGetUserPreferredQuality()?.level ??
                        PLAYER_TECH_VIDEO_QUALITY_CATEGORY.AUTO
                ),
                updateVideoState('initialQuality')
            );

            const currentVideoQuality$ = playbackData$.pipe(
                switchMap(() =>
                    concat(initialQuality$, bitrateCurrentQuality$).pipe(
                        distinctUntilChanged(),
                        updateVideoState('videoQuality')
                    )
                )
            );

            const isPlaying4k$ = merge(
                eventFsBitrateChanged$,
                eventFsBitrateLoaded$
            ).pipe(
                map(() => playerTech.currentBitrateLevel),
                map(({width, height}) => width >= 3840 && height >= 2160),
                distinctUntilChanged(),
                updateVideoState('isPlaying4k')
            );

            const bitrates$ = playbackData$.pipe(
                switchMap(() =>
                    eventFsBitrateLoaded$.pipe(
                        map((event) => event?.detail?.levels),
                        distinctUntilChanged(isEqual),
                        updateVideoState('bitrates')
                    )
                )
            );

            const textTrackEvents$ = playbackData$.pipe(
                switchMap(() =>
                    merge(eventMetadataLoaded$, eventCaptionsUpdated$).pipe(
                        share()
                    )
                )
            );

            const audioTrackEvents$ = merge(
                eventAudioTracksUpdated$,
                eventMetadataLoaded$
            ).pipe(share());

            const captionTracks$ = playbackData$.pipe(
                switchMap((playbackData) => {
                    const isClosedCaptionsEnabledForAsset =
                        playbackData?.isClosedCaptionsEnabledForAsset;

                    return concat(
                        of([]), // set our initial value to an empty array while we wait for textTrackEvents$ to emit
                        isClosedCaptionsEnabledForAsset
                            ? textTrackEvents$.pipe(
                                  map(() => playerTech?.textTracks)
                              ) // get textTracks from playerTech when we are ready
                            : EMPTY
                    ).pipe(
                        distinctUntilChanged(),
                        updateVideoState('captions'),
                        share()
                    );
                })
            );

            const updateCaptionTrackIndex$ = captionTracks$.pipe(
                filter(
                    (captionTracks) =>
                        captionTracks !== undefined && captionTracks.length > 0
                ),
                tap(() => {
                    const captionPreference = getCaptionsPreference();

                    if (typeof captionPreference === 'number') {
                        return actions.setCaptionTrackByIndex(
                            captionPreference
                        );
                    }
                })
            );

            const currentCaptionTrackIndex$ = playbackData$.pipe(
                switchMap(() =>
                    textTrackEvents$.pipe(
                        map(() => playerTech?.textTrackCurrentIndex),
                        startWith(CAPTIONS_OFF_INDEX),
                        distinctUntilChanged(),
                        updateVideoState('currentCaptionTrackIndex'),
                        shareReplay({refCount: true, bufferSize: 1})
                    )
                )
            );

            const currentCaptionTrackLabel$ = playbackData$.pipe(
                switchMap(() =>
                    combineLatest([
                        captionTracks$,
                        currentCaptionTrackIndex$,
                    ]).pipe(
                        map(([tracks = [], index]) => {
                            if (!tracks || !tracks.length) {
                                return null;
                            }

                            return tracks?.[index]?.label || 'Off';
                        }),
                        tap((label) => {
                            if (label) {
                                onChangeCaptions(label);
                            }
                        }),
                        startWith(null),
                        distinctUntilChanged(),
                        shareReplay({refCount: true, bufferSize: 1})
                    )
                )
            );

            const audioTracks$ = playbackData$.pipe(
                switchMap(() =>
                    audioTrackEvents$.pipe(
                        map(() => playerTech?.audioTracks),
                        startWith([]),
                        distinctUntilChanged(isEqual),
                        updateVideoState('audioTracks'),
                        shareReplay({refCount: true, bufferSize: 1})
                    )
                )
            );

            const currentAudioTrackIndex$ = playbackData$.pipe(
                switchMap(() =>
                    audioTrackEvents$.pipe(
                        map(() => playerTech.currentAudioTrackIndex),
                        distinctUntilChanged(),
                        updateVideoState('currentAudioTrackIndex'),
                        shareReplay({refCount: true, bufferSize: 1})
                    )
                )
            );

            const currentAudioTrackLabel$ = playbackData$.pipe(
                switchMap(() =>
                    combineLatest([audioTracks$, currentAudioTrackIndex$]).pipe(
                        map(([tracks = [], index]) => {
                            if (!tracks || !tracks.length) {
                                return null;
                            }

                            return tracks?.[index]?.label || 'Off';
                        }),
                        tap((label) => {
                            if (label) {
                                onChangeCaptions(label);
                            }
                        }),
                        startWith(null),
                        distinctUntilChanged(),
                        shareReplay({refCount: true, bufferSize: 1})
                    )
                )
            );

            const isBtybVisible$ = playbackData$.pipe(
                switchMap((playbackData) => {
                    const btyb = playbackData?.metadata?.btyb;
                    const minimumBtybDisplayTimeSeconds =
                        playbackData?.metadata?.minimumBtybDisplayTimeSeconds;

                    return btyb
                        ? combineLatest([
                              minimumBtybDisplayTimeSeconds
                                  ? timer(minimumBtybDisplayTimeSeconds * 1000)
                                  : of(true),
                              eventMetadataLoaded$,
                          ]).pipe(
                              first(),
                              withLatestFrom(playbackData$),
                              switchMap(([, latestPlaybackData]) => {
                                  // eslint-disable-line no-unused-vars
                                  const options = latestPlaybackData?.options;

                                  return options?.autoPlay
                                      ? of(true).pipe(
                                            tap(() => playerTech.play())
                                        ) // Hide start screen immediately if about to autoPlay
                                      : firstPlay$; // Otherwise, hide it only when user triggers play
                              }),
                              map(() => false),
                              startWith(true),
                              distinctUntilChanged()
                          )
                        : of(false);
                }),
                updateVideoState('isBtybVisible')
            );

            const youboraTracking$ = playbackData$.pipe(
                filterNullish(),
                switchMap(({getYouboraOptions, getYouboraAdapterConfig}) => {
                    const youboraPlugin = YouboraPlugin.getInstance(
                        youboraAccountId,
                        {
                            // Disable the CDN balancer because it modifies `XMLHttpRequest`.
                            // Important: Do not change this until you have verified that it no longer modifies core browser APIs!
                            components: {balancer: false},

                            // Alternative host to bypass ad blockers
                            host: youboraHost,
                        }
                    );

                    return eventPlaying$.pipe(
                        first(),
                        map(() => ({
                            playerTech,
                            videoElement,
                            getYouboraOptions,
                            getYouboraAdapterConfig,
                            youboraAccountId,
                            logger,
                            youboraPlugin,
                            screenIndex,
                        })),
                        switchMap(youboraTrackingForSingleVideo)
                    );
                })
            );

            /**
             * Video ended on split view
             */
            const splitViewVideoEnded$ = playbackData$.pipe(
                map((playbackData) => playbackData?.id),
                combineLatestWith(subjects.splitViewLayoutTypeSubject),
                distinctUntilChanged(isEqual),
                switchMap(([, layoutType]) =>
                    iif(
                        () => layoutType === PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE,
                        EMPTY,
                        isEnded$.pipe(
                            filter(Boolean),
                            take(1),
                            tap(() => {
                                const {
                                    lastPositionScreenIndex,
                                    globalState,
                                    globalActions,
                                } = playerStateInstance;

                                // Take the last postion and swap it with the current ended screen
                                if (
                                    lastPositionScreenIndex !== screenIndex &&
                                    lastPositionScreenIndex
                                ) {
                                    playerStateInstance.globalActions?.swapScreenOrder(
                                        lastPositionScreenIndex,
                                        screenIndex
                                    );
                                }

                                if (globalState === null) {
                                    return;
                                }

                                // Downgrade the layout which will remove the last screen that contains the ended video.
                                const availableScreenSlots =
                                    PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS[
                                        globalState.layoutStyle
                                    ];
                                const nextLayout = getNextLayout(
                                    availableScreenSlots - 1
                                );

                                globalActions?.setLayoutStyle(nextLayout);
                            })
                        )
                    )
                )
            );

            const {
                adData$,
                durationAdsRemoved$,
                currentTimeAdsRemoved$,
                adIndex$,
                isInAdBreak$,
                adBreakAdCount$,
                adCurrentTime$,
                adDuration$,
                seekingTimeAdsRemoved$,
                skipAdWhenPlayed$,
            } = getAdDataStreams({
                currentTime$,
                duration$,
                eventDataLoaded$,
                playbackData$,
                seekingTime$,
                playerTech,
                globalSetTrayVisibility:
                    playerStateInstance.globalActions?.setTrayVisibility,
                globalSetHudVisibility:
                    playerStateInstance.globalActions?.setHudVisibility,
                updateVideoState,
                eventFsPeriodSwitchCompleted$,
                eventFsPeriodSwitchStarted$,
            });

            const bufferedTimeRanges$ = playbackData$.pipe(
                switchMap(() =>
                    fromEvent(videoElement, 'progress').pipe(
                        map(() => playerTech.bufferedTimeRanges),
                        withLatestFrom(adData$),
                        map(([bufferedTimeRanges, adData]) =>
                            bufferedTimeRanges.map(({start, end}) => ({
                                start:
                                    start -
                                    calculateAdSecondsPassed(start, adData),
                                end:
                                    end - calculateAdSecondsPassed(end, adData),
                            }))
                        ),
                        startWith([]),
                        distinctUntilChanged(isEqual),
                        updateVideoState('bufferedTimeRanges')
                    )
                )
            );

            /*
            Remaining things to track in state:
            - Bitrate levels
         */
            const thumbnailBIF$ = playbackData$.pipe(
                switchMap((playbackData?: PlaybackData) => {
                    const thumbnailBIFs = playbackData?.thumbnailBIFs;

                    return durationAdsRemoved$.pipe(
                        map((duration) => ({
                            duration,
                            sd: thumbnailBIFs?.sdUrl,
                            hd: thumbnailBIFs?.hdUrl,
                            sdDelayMs: thumbnailBIFs?.sdDelayMs,
                            hdDelayMs: thumbnailBIFs?.hdDelayMs,
                        })),
                        switchMap(getProgressiveThumbnailRegistry),
                        updateVideoState('thumbnails')
                    );
                })
            );

            // Autoplay is false when we have ad-data so we need to tell the video to play when ad-data resolves
            // we also need to accomodate for when a startAt value is set
            const playWhenReady$ = adData$.pipe(
                filter(Boolean),
                withLatestFrom(playbackData$),
                tap(([, playbackData]) => {
                    const autoPlay = playbackData?.options?.autoPlay;

                    if (autoPlay) {
                        playerTech.play();
                    }
                })
            );

            const contentPlay$ = eventPlay$.pipe(
                switchMap(() =>
                    isInAdBreak$.pipe(filter((isInAdBreak) => !isInAdBreak))
                ),
                share()
            );

            // TODO: move this logic to global-state.js and change the videoState/globalState accordingly
            // It may also be worth changing this to be a screen-specific overlay, this will mean that we can have a content-warning per screen.
            const isContentWarningVisible$ = playbackData$.pipe(
                switchMap((playbackData?: PlaybackData) => {
                    const contentWarningLines =
                        playbackData?.contentWarningLines;

                    return iif(
                        () =>
                            !!contentWarningLines &&
                            contentWarningLines?.length > 0, // make sure we have content warnings to show, default to of(false) if we dont
                        contentPlay$.pipe(
                            // start a countdown when content starts to play
                            take(1),
                            delay(CONTENT_WARNING_DELAY_MS.DELAY_DISPLAY),
                            switchMap(() =>
                                timer(
                                    CONTENT_WARNING_DELAY_MS.SHOW_PERIOD
                                ).pipe(
                                    map(() => false),
                                    startWith(true)
                                )
                            ),
                            startWith(false)
                        ),
                        of(false)
                    );
                }),
                updateVideoState('isContentWarningVisible')
            );

            return {
                adBreakAdCount$,
                adData$,
                adIndex$,
                adCurrentTime$,
                adDuration$,
                audioTracks$,
                bitrates$,
                bufferedTimeRanges$,
                buffering$,
                captionTracks$,
                currentAudioTrackIndex$,
                currentAudioTrackLabel$,
                currentCaptionTrackIndex$,
                currentCaptionTrackLabel$,
                currentTime$,
                currentTimeAdsRemoved$,
                currentVideoQuality$,
                duration$,
                durationAdsRemoved$,
                eventDataLoaded$,
                eventEnded$,
                eventMetadataLoaded$,
                firstPlay$,
                hasVideoElementPlayed$,
                isPlaying4k$,
                isBtybVisible$,
                isEnded$,
                isInAdBreak$,
                isInErrorRecovery$,
                resetStartScreenOnFirstPlay$,
                isContentWarningVisible$,
                isLiveStream$,
                isOnEdge$,
                isOutOfLongPausing$,
                isPausedFor5Seconds$,
                isPlaying$,
                isSeeking$,
                latestSecondsViewed$,
                playbackData$,
                playbackRate$,
                playerTechErrorCode$,
                playerTechErrorDetails$,
                playWhenReady$,
                seekingTime$,
                seekingTimeAdsRemoved$,
                updateCaptionTrackIndex$,
                thumbnailBIF$,
                youboraTracking$,
                splitViewVideoEnded$,
                skipAdWhenPlayed$,
                isLiveEnded$,
                updateUpNextDataWhenLiveEnded$,
                updateUpNextDataWithRandomInterval$,
            };
        }
    );

    return {
        streamsToSubscribe: streamsPerScreen
            .map((value) => Object.values(value || {}))
            .flat(),
        streams: streamsPerScreen,
    };
}
