import first from 'lodash/first';

import type {
    SourceConfig,
    SupportedDashPlaybackHandler,
    SupportedPlayerTech,
} from '../types';
import {VIDEO_FS_ERROR_TYPES} from '../utils/player-tech-error';
import stripQueryParams from '../utils/strip-query-params';
import PlaybackDash from './dash';
import PlaybackHls from './hls';
import PlaybackNative from './native';
import PlaybackNativeHisenseModified from './native/hisense-modified';
import PlaybackRx from './rx-player';
import type {PlaybackHandlerArgs} from './types';

type ReturnTypes = Pick<PlaybackHandlerArgs, 'availableKeySystems'> &
    Pick<
        SourceConfig,
        | 'src'
        | 'type'
        | 'cdnProvider'
        | 'withCredentials'
        | 'hasSsai'
        | 'keySystems'
    > & {
        PlaybackHandler: SupportedPlayerTech;
        couldPlay: CanPlayTypeResult;
    };

// eslint-disable-next-line import/prefer-default-export
export async function getBestPlaybackHandlerWithSource({
    sources = [],
    srcBlacklist = new Set<string>(),
    videoElement = null,

    // Order of preference for playback handler.
    playbackHandlers = [
        // PlaybackWebmaf,
        PlaybackDash,
        PlaybackRx,
        PlaybackHls,

        // Dear brave soul.  Hisense native playback bugs from 2020.
        // When hisense resolve video elements reloading their source after 'ended' event is fired, we can remove
        // this check, and NativePlaybackHisense override.

        // Make another test around #t= for startAt which is accounted there, and can be moved back to this
        // class's setup method if we want.
        PlaybackNativeHisenseModified,

        PlaybackNative,
    ],
    preferredDashPlaybackHandler = 'dashjs',
}: {
    sources: SourceConfig[];
    srcBlacklist: Set<string>;
    videoElement: HTMLVideoElement | null;
    playbackHandlers?: SupportedPlayerTech[];
    preferredDashPlaybackHandler?: SupportedDashPlaybackHandler;
}): Promise<ReturnTypes | undefined> {
    const validSources = sources.filter(
        (source) => !srcBlacklist.has(stripQueryParams(source.src))
    );

    // if we have sources and none of them are valid then we have exhausted our source list.
    // throw an error for setSources to deal with
    if (sources.length > 0 && validSources.length === 0) {
        throw VIDEO_FS_ERROR_TYPES.ALL_SOURCES_BLACKLISTED;
    }

    // Check if each combination of source and playback handler can play, and retrieve the available keySystems
    // Using flatMap here ensures a 1D array, which is necessary for Promise.all to resolve correctly
    const playbackAndSourcesPromises = playbackHandlers.flatMap(
        (PlaybackHandler) =>
            validSources.map(
                async ({
                    src,
                    type,
                    cdnProvider,
                    hasSsai,
                    withCredentials,
                    keySystems,
                }) => {
                    const couldPlay = await PlaybackHandler.canPlaySource({
                        src,
                        type,
                        videoElement,
                        keySystems,
                    });
                    const availableKeySystems =
                        await PlaybackHandler.getAvailableKeySystems(
                            keySystems
                        );

                    // Return the source details along with playback capabilities
                    return {
                        availableKeySystems,
                        cdnProvider,
                        couldPlay,
                        keySystems,
                        PlaybackHandler,
                        src,
                        hasSsai,
                        type,
                        withCredentials,
                    };
                }
            )
    );
    const playbackAndSources = await Promise.all(playbackAndSourcesPromises);
    const compatiblePlaybackAndSources = playbackAndSources.filter(
        ({couldPlay}) => couldPlay
    );
    const couldPlayStrings = ['probably', 'maybe', ''];

    const sortedPlaybackAndSources = compatiblePlaybackAndSources
        .filter(({PlaybackHandler}) => {
            if (PlaybackHandler === PlaybackDash) {
                return preferredDashPlaybackHandler === 'dashjs';
            }

            if (PlaybackHandler === PlaybackRx) {
                return preferredDashPlaybackHandler === 'rxplayer';
            }

            return true;
        })
        .sort(
            (
                {
                    couldPlay: couldPlayA,
                    availableKeySystems: availableKeySystemsA = [],
                },
                {
                    couldPlay: couldPlayB,
                    availableKeySystems: availableKeySystemsB = [],
                }
            ) => {
                if (couldPlayA === couldPlayB) {
                    // If we have the same couldPlay choose the one who has more DRM key system options
                    return (
                        availableKeySystemsB.length -
                        availableKeySystemsA.length
                    );
                } else if (
                    couldPlayStrings.includes(couldPlayA) &&
                    couldPlayStrings.includes(couldPlayB)
                ) {
                    // Otherwise, choose the one with the best couldPlay
                    return (
                        couldPlayStrings.indexOf(couldPlayA) -
                        couldPlayStrings.indexOf(couldPlayB)
                    );
                } else {
                    return 0;
                }
            }
        );

    return first(sortedPlaybackAndSources);
}

export function calculateFrameRate(ratioStr: string): number | undefined {
    const [numerator, denominator = 1] = ratioStr.split('/');

    if (numerator) {
        return Number((Number(numerator) / Number(denominator)).toFixed(2));
    }

    return undefined;
}
