import bacon from 'baconjs';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import get from 'lodash/result';
import {EVENTS} from '@fsa-streamotion/player-tech';

export default function videoNormalisedEvents(videoElementAndPlayerTech) {
    const getAllVideoEventsStream = function ({videoElement, playerTech}) {
        return bacon.mergeAll(
            EVENTS.VIDEO.map((event) => bacon.fromEvent(videoElement, event))
        )
            .map(({type}) => ({event: type, playerTech}));
    };

    const videoEvents$ = getAllVideoEventsStream(videoElementAndPlayerTech);

    const hasPlayed$ = bacon.mergeAll(
        videoEvents$
            .filter(filterEventOn(['playing']))
            .map(true),
        videoEvents$
            .filter(filterEventOn(['loadstart']))
            .map(false) // reset when new video comes through
    ).toProperty(false);

    const isStopped$ = bacon.mergeAll(
        videoEvents$
            .filter(filterEventOn(['stopped']))
            .map(true),
        videoEvents$
            .filter(filterEventOn(['fs-mounted', 'fs-source-updated']))
            .map(false)
    ).toProperty(false);

    const calledToSeek$ = bacon.mergeAll(
        videoEvents$
            .filter(filterEventOn(['seeking']))
            .map(true),
        videoEvents$
            .filter(filterEventOn(['seeking']))
            .delay(250)
            .map(false)
    ).toProperty(false);

    const mounted$ = videoEvents$
        .filter(filterEventOn(['fs-mounted']))
        .map(({playerTech}) => ({event: 'mounted', playerTech}));

    const sourceupdate$ = videoEvents$
        .filter(filterEventOn(['fs-source-updated']))
        .map(({playerTech}) => ({event: 'sourceupdate', playerTech}));

    const userinitiated$ = videoEvents$
        .filter(filterEventOn(['fs-mounted', 'fs-source-updated']))
        .flatMapLatest(({event, playerTech = {}}) => {
            if (get(playerTech, 'options.autoPlay')) {
                return {event, playerTech};
            } else {
                return videoEvents$.filter(filterEventOn(['play'])).take(1);
            }
        })
        .take(1)
        .map(({playerTech}) => ({event: 'userinitiated', playerTech}));

    // First Play always starts on mounted or resumes on sourceupdate.
    // Create new stream that ends after first value.
    const firstplay$ = videoEvents$
        .filter(filterEventOn(['fs-mounted', 'fs-source-updated']))
        .flatMapLatest(() => videoEvents$.filter(filterEventOn(['playing'])))
        .take(1)
        .map(({playerTech}) => ({event: 'firstplay', playerTech}));

    const volumechange$ = videoEvents$
        .filter(filterEventOn(['volumechange']));

    const volumeWatcher$ = bacon.when(
        [volumechange$], ({event, playerTech}) => {
            const {muted, volume} = playerTech;

            return {
                muted: muted || volume === 0,
                event,
                playerTech,
            };
        }
    ).skipDuplicates(isEqual);

    const mute$ = volumeWatcher$
        .filter(({muted}) => muted);

    const unmute$ = mute$.not();

    const error$ = videoEvents$
        .filter(filterEventOn(['error', 'fs-source-error-retry']))
        .map(({playerTech, eventInfo}) => ({event: 'error', eventInfo, playerTech}));

    const timeupdate$ = videoEvents$
        .filter(filterEventOn(['timeupdate']))
        .throttle(1 * 1000);

    const ended$ = videoEvents$
        .filter(filterEventOn(['ended']));

    const stopped$ = videoEvents$
        .filter(filterEventOn(['stopped']));

    const replay$ = videoEvents$
        .filter(filterEventOn(['ended', 'seeking', 'play']))
        .slidingWindow(3, 3)
        .filter((events) => matchEventOrder(['ended', 'seeking', 'play'])(events) // everyone else
            || matchEventOrder(['ended', 'play', 'seeking'])(events)) // firefox
        .map('.2') // last event heard
        .changes()
        .map(({playerTech}) => ({event: 'replay', playerTech}));

    const allowSeek$ = bacon.mergeAll(
        videoEvents$
            .filter(filterEventOn(['playing']))
            .delay(1000)
            .map(true),

        videoEvents$
            .filter(filterEventOn(['ended']))
            .map(false)
    ).toProperty(false);

    const playPauseSeeked$ = videoEvents$
        .filter(hasPlayed$) // Don't bother unless we've started
        .filter(isStopped$.not()) // Don't allow play pause seeked while we're 'stopped'
        .filter(filterEventOn(['play', 'pause', 'seeked', 'ended']))
        .slidingWindow(3, 1) // Last 3 (at least 1) events
        .debounce(100) // Only let this through if it's been quiet for at least 100ms
        .map((events) => {
            const eventSequence = events.map((event) => event.event).join('|');

            if (eventSequence === 'pause|seeked|play') {
                // Seeks while playing first pause, seek, then resume.
                return events[1]; // Return seeked event.
            } else if (eventSequence === 'seeked|seeked|play') {
                // Someone dragging the bar around
                return events[1]; // Return seeked event.
            } else {
                // No particular matching here, return last even.
                // 'ended' falls in this category.

                return last(events);
            }
        });

    const play$ = playPauseSeeked$
        .filter(calledToSeek$.not()) // only take while not seeking
        .filter(filterEventOn(['play']));

    const pause$ = playPauseSeeked$
        .filter(calledToSeek$.not()) // only take while not seeking
        .filter(filterEventOn(['pause']));

    const seeked$ = playPauseSeeked$
        .filter(allowSeek$)
        .filter(filterEventOn(['seeked']))
        .debounce(200);

    const debouncedEvents$ = bacon.mergeAll(
        pause$,
        play$,
        ended$,
        seeked$,
        replay$
    ).debounce(100);

    return bacon.mergeAll(
        debouncedEvents$,
        error$,
        firstplay$,
        mounted$,
        mute$,
        sourceupdate$,
        stopped$,
        timeupdate$,
        unmute$,
        userinitiated$
    );
}

export function filterEventOn(wantedEvents = []) {
    return (event) => event && wantedEvents.includes(event.event);
}

export function matchEventOrder(eventOrder = []) {
    return (events) => events.every((event, index) => event.event === eventOrder[index]);
}
