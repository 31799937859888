export default class MediaKindWmcAdapter {
  registerListeners() {
    this.inAdBreak = false;
    this.lastSeenBitrate = 0;
    this.lastPlayhead = 0;
    this.lastDuration = 0;
  }

  registerWmcManager(wmcManager) {
    this.wmcEventListeners = new Map();

    if (!wmcManager) {
      return;
    }

    this.wmcManager = wmcManager;
    const wmcEvents = amc.AmcEvents;

    const checkAdsAdapterHandler = this._checkAdsAdapter.bind(this);
    const errorHandler = this.errorListener.bind(this);
    const playbackStartedHandler = this.playbackStartedListener.bind(this);
    const bitrateChangedHandler = this.bitrateChangedListener.bind(this);
    const metricsUpdateHandler = this.metricsUpdateListener.bind(this);
    const programChangedHandler = this.programChangedListener.bind(this);
    const seekCompletedHandler = this.seekCompletedListener.bind(this);
    const stateChangedHandler = this.stateChangedListener.bind(this);
    const positionChangedHandler = this.positionChangedListener.bind(this);

    this.wmcEventListeners.set(wmcEvents.AMC_EVENT_AD_BREAK, checkAdsAdapterHandler);
    this.wmcManager.addEventListener(wmcEvents.AMC_EVENT_AD_BREAK, checkAdsAdapterHandler);

    this.wmcEventListeners.set(wmcEvents.AMC_EVENT_BIT_RATE_CHANGED, bitrateChangedHandler);
    this.wmcManager.addEventListener(wmcEvents.AMC_EVENT_BIT_RATE_CHANGED, bitrateChangedHandler);

    this.wmcEventListeners.set(wmcEvents.AMC_EVENT_ERROR, errorHandler);
    this.wmcManager.addEventListener(wmcEvents.AMC_EVENT_ERROR, errorHandler);

    this.wmcEventListeners.set(wmcEvents.AMC_EVENT_INIT_COMPLETE, playbackStartedHandler);
    this.wmcManager.addEventListener(wmcEvents.AMC_EVENT_INIT_COMPLETE, playbackStartedHandler);

    this.wmcEventListeners.set(wmcEvents.AMC_EVENT_METRICS_UPDATE, metricsUpdateHandler);
    this.wmcManager.addEventListener(wmcEvents.AMC_EVENT_METRICS_UPDATE, metricsUpdateHandler);

    this.wmcEventListeners.set(wmcEvents.AMC_EVENT_PLAYER_METADATA, metricsUpdateHandler);
    this.wmcManager.addEventListener(wmcEvents.AMC_EVENT_PLAYER_METADATA, metricsUpdateHandler);

    this.wmcEventListeners.set(wmcEvents.AMC_EVENT_PLAYOUT_METRIC_UPDATE, metricsUpdateHandler);
    this.wmcManager.addEventListener(wmcEvents.AMC_EVENT_PLAYOUT_METRIC_UPDATE, metricsUpdateHandler);

    this.wmcEventListeners.set(wmcEvents.AMC_EVENT_PLAY_READY, playbackStartedHandler);
    this.wmcManager.addEventListener(wmcEvents.AMC_EVENT_PLAY_READY, playbackStartedHandler);

    this.wmcEventListeners.set(wmcEvents.AMC_EVENT_PROGRAM_CHANGED, programChangedHandler);
    this.wmcManager.addEventListener(wmcEvents.AMC_EVENT_PROGRAM_CHANGED, programChangedHandler);

    this.wmcEventListeners.set(wmcEvents.AMC_EVENT_SEEK_COMPLETE, seekCompletedHandler);
    this.wmcManager.addEventListener(wmcEvents.AMC_EVENT_SEEK_COMPLETE, seekCompletedHandler);

    this.wmcEventListeners.set(wmcEvents.AMC_EVENT_STATE_CHANGED, stateChangedHandler);
    this.wmcManager.addEventListener(wmcEvents.AMC_EVENT_STATE_CHANGED, stateChangedHandler);

    this.wmcEventListeners.set(wmcEvents.AMC_EVENT_VIDEO_POSITION_CHANGED, positionChangedHandler);
    this.wmcManager.addEventListener(wmcEvents.AMC_EVENT_VIDEO_POSITION_CHANGED, positionChangedHandler);
  }

  unregisterListeners() {
    this.wmcEventListeners.forEach((handler, event) => {
      this.wmcManager.removeEventListener(event, handler);
    });

    this.wmcEventListeners.clear();
  }

  _checkAdsAdapter() {
    const adsAdapterInst = this.getVideo().getAdsAdapter();
    if (!adsAdapterInst) {
      this.plugin.setAdsAdapter(this.getAdapterClass('nativeAdsAdapter'), this.getVideo().getVideoKey());
      this.getVideo().getAdsAdapter().registerListeners(this.wmcManager);
    } else if (adsAdapterInst && !adsAdapterInst.player) {
      adsAdapterInst.setPlayer(this.player);
    }

    return null;
  }

  playbackStartedListener(e) {
    if (this.flags.isStarted) return;

    this.firePlayerLog('playbackStarted', {});

    this.lastPlayhead = 0;
    this.fireStart({}, 'playbackStarted');
    this.failedView = false;
  }

  bitrateChangedListener(e) {
    this.firePlayerLog('bitrateChangedListener', {});

    if (e.value1) {
      this.lastSeenBitrate = e.value1;
    }
  }

  metricsUpdateListener(e) {
    this.firePlayerLog('metricsUpdateListener', {});

    if (e.value1) {
      if (e.value1.audio) {
        if (e.value1.audio.codec) {
          this.audioCodec = e.value1.audio.codec;
        }
      }
      if (e.value1.video) {
        if (e.value1.video.codec) {
          this.videoCodec = e.value1.video.codec;
        }
        if (e.value1.video.frameRate) {
          this.fps = e.value1.video.frameRate;
        }
        if (e.value1.video.resolution) {
          this.resolution = e.value1.video.resolution;
        }
        if (e.value1.video.droppedFrames) {
          this.droppedFrames = e.value1.video.droppedFrames;
        }
        if (e.value1.video.latency) {
          this.latency = e.value1.video.latency;
        }
      }
    }
  }

  programChangedListener(e) {
    this.firePlayerLog('programChangedListener', {});

    return null;
  }

  seekCompletedListener(e) {
    this.firePlayerLog('seekCompletedListener', {});

    if (this.flags.isSeeking) {
      this.fireSeekEnd({}, 'seekCompletedListener');
    } else {
      // covers the case where a seek completed event was received but no seek begin was triggered (e.g. when using the seek functino of the player directly)
      this.fireSeekBegin({}, false, 'seekCompletedListener');
      this.fireSeekEnd({}, 'seekCompletedListener');
    }
    return null;
  }

  stateChangedListener(e) {
    this.firePlayerLog('stateChangedListener', {});

    const newState = e.value1;

    switch (newState) {
      case amc.AmcConstants.IMC_STATE_PLAY_NONE:
        break;
      case amc.AmcConstants.IMC_STATE_PLAY_STARTED:
        if (!this.inAdBreak) {
          if (!this.flags.isStarted) {
            this.fireStart({}, 'IMC_STATE_PLAY_STARTED');
          }

          if (!this.flags.isJoined) {
            this.fireJoin({}, 'IMC_STATE_PLAY_STARTED');
          }
        }
        break;
      case amc.AmcConstants.IMC_STATE_PLAY_PAUSED:
        this.firePause({}, 'IMC_STATE_PLAY_PAUSED');
        break;
      case amc.AmcConstants.IMC_STATE_PLAY_RESUMED:
        if (this.flags.isSeeking) {
          this.fireSeekEnd({}, 'IMC_STATE_PLAY_RESUMED');
        } else if (this.flags.isBuffering) {
          this.fireBufferEnd({}, 'IMC_STATE_PLAY_RESUMED');
        }

        if (this.flags.isPaused) {
          this.fireResume({}, 'IMC_STATE_PLAY_RESUMED');
        }
        break;
      case amc.AmcConstants.IMC_STATE_PLAY_STOPPED:
        if (!this.inAdBreak) {
          this.fireStop({}, 'IMC_STATE_PLAY_STOPPED');
        }
        break;
      case amc.AmcConstants.IMC_STATE_SEEKING:
        if (!this.inAdBreak) {
          this.fireSeekBegin({}, false, 'IMC_STATE_SEEKING');
        }
        break;
      case amc.AmcConstants.IMC_STATE_PLAY_COMPLETED:
        if (!this.inAdBreak) {
          this.fireStop({}, 'IMC_STATE_PLAY_COMPLETED');
        }
        break;
      case amc.AmcConstants.IMC_STATE_BUFFERING_STARTED:
        if (!this.flags.isSeeking) {
          this.fireBufferBegin({}, false, 'IMC_STATE_BUFFERING_STARTED');
        }
        break;
      case amc.AmcConstants.IMC_STATE_BUFFERING_STOPPED:
        if (this.flags.isSeeking) {
          this.fireSeekBegin({}, false, 'IMC_STATE_BUFFERING_STOPPED');
        } else {
          this.fireBufferEnd({}, 'IMC_STATE_BUFFERING_STOPPED');
        }
        break;
      case amc.AmcConstants.IMC_STATE_DOWNLOAD_STARTED:
        break;
      case amc.AmcConstants.IMC_STATE_DOWNLOAD_STOPPED:
        break;
      case amc.AmcConstants.IMC_STATE_DOWNLOAD_COMPLETED:
        break;
      case amc.AmcConstants.IMC_STATE_DOWNLOAD_PAUSED:
        break;
      case amc.AmcConstants.IMC_STATE_DOWNLOAD_CANCELLED:
        break;
      case amc.AmcConstants.IMC_STATE_DOWNLOAD_PLAY_READY:
        break;
      case amc.AmcConstants.IMC_STATE_ADS_STARTED:
        this.inAdBreak = true;
        break;
      case amc.AmcConstants.IMC_STATE_ADS_COMPLETED:
        this.inAdBreak = false;
        break;
      case amc.AmcConstants.IMC_STATE_DONE:
        if (!this.inAdBreak) {
          this.fireStop({}, 'IMC_STATE_DONE');
        }
        break;
      default:
        break;
    }

    return null;
  }

  translateImcEvent(eventCode) {
    const imcEventMap = {
      [amc.AmcConstants.IMC_STATE_PLAY_NONE]: 'Play None',
      [amc.AmcConstants.IMC_STATE_PLAY_STARTED]: 'Play Started',
      [amc.AmcConstants.IMC_STATE_PLAY_PAUSED]: 'Play Paused',
      [amc.AmcConstants.IMC_STATE_PLAY_RESUMED]: 'Play Resumed',
      [amc.AmcConstants.IMC_STATE_PLAY_STOPPED]: 'Play Stopped',
      [amc.AmcConstants.IMC_STATE_PLAY_COMPLETED]: 'Play Completed',
      [amc.AmcConstants.IMC_STATE_BUFFERING_STARTED]: 'Buffering Started',
      [amc.AmcConstants.IMC_STATE_BUFFERING_STOPPED]: 'Buffering Stopped',
      [amc.AmcConstants.IMC_STATE_SEEKING]: 'Seeking',
      [amc.AmcConstants.IMC_STATE_ADS_STARTED]: 'Ads Started',
      [amc.AmcConstants.IMC_STATE_ADS_COMPLETED]: 'Ads Completed',
      [amc.AmcConstants.IMC_STATE_DOWNLOAD_STARTED]: 'Download Started',
      [amc.AmcConstants.IMC_STATE_DOWNLOAD_STOPPED]: 'Download Stopped',
      [amc.AmcConstants.IMC_STATE_DOWNLOAD_COMPLETED]: 'Download Completed',
      [amc.AmcConstants.IMC_STATE_DOWNLOAD_PAUSED]: 'Download Paused',
      [amc.AmcConstants.IMC_STATE_DOWNLOAD_CANCELLED]: 'Download Cancelled',
      [amc.AmcConstants.IMC_STATE_DOWNLOAD_PLAY_READY]: 'Download Play Ready',
      [amc.AmcConstants.IMC_STATE_DONE]: 'Done'
    };

    return imcEventMap[eventCode] || 'Unknown IMC Event';
  }

  positionChangedListener(e) {
    this.firePlayerLog('positionChangedListener', {});

    if (e.value1) {
      this.lastPlayhead = e.value1;
    } else if (e.videoPosition) {
      this.lastPlayhead = e.videoPosition;
    }

    if (e.videoDuration) {
      this.lastDuration = e.videoDuration;
    }

    if (this.getPlayhead() > 0.2 && (this.getIsLive() || this.getPlayhead() + 1 < this.getDuration())) {
      if (!this.flags.isStarted) {
        this.fireStart({}, 'positionChangedListener');
        this.failedView = false;
      }
      if (!this.flags.isJoined) {
        this.fireJoin({}, 'positionChangedListener');
      }
    }

    if (!this.player.doIsPaused() && this.flags.isSeeking) {
      this.fireSeekEnd({}, 'positionChangedListener');
    }

    return null;
  }

  errorListener(e) {
    if (this.failedView) return;

    this.firePlayerLog('errorListener', {});

    if (!this.flags.isStarted) this.failedView = true;
    if (e.code) {
      this.fireError(e.code, e.message, undefined, undefined, 'errorListener');
    } else {
      this.fireError(e, 'Error', undefined, undefined, 'errorListener');
    }
    this.fireStop(this.getStopParams());
    return null;
  }

  getVersion() {
    return '7.0.1-mediakind-wmc-jsclass';
  }

  getPlayhead() {
    let ret = this.player ? this.player.getCurrentTime() : this.lastPlayhead;
    return ret;
  }

  _getPlayhead() {
    return this.getPlayhead();
  }

  getPlayrate() {
    return this.player ? this.player.getPlaybackRate() : null;
  }

  getStopParams() {
    let params = {};
    if (this.getVideo().getPlayhead() > 0) {
      params = { playhead: this.getVideo().getPlayhead() };
    }
    return params;
  }

  getDroppedFrames() {
    if (this.player && this.player.doGetPlayer) {
      return this.player.doGetPlayer().getDroppedVideoFrames();
    }
    return null;
  }

  getDuration() {
    let ret = this.player ? this.player.doGetVideoDuration() ?? this.lastDuration : this.lastDuration;
    return ret;
  }

  getBitrate() {
    if (this.lastSeenBitrate) return this.lastSeenBitrate;

    let ret = null;
    if (this.player) {
      const videodata = this.player.doGetPlayer().getPlaybackVideoData();
      const videoquality = this.player.doGetPlayer().getVideoQuality();

      if (videoquality && videoquality.bitrate) {
        ret = videoquality.bitrate;
      } else if (videodata && videodata.bitrate) {
        ret = videodata.bitrate;
      }
    }
    return ret;
  }

  getRendition() {
    let ret = null;
    const bitrate = this.getBitrate();
    const resolution = this.getResolution();

    if (bitrate && resolution) {
      const [width, height] = resolution.split('x').map(Number);
      ret = this.getNpawUtils().buildRenditionString(width, height, bitrate);
    } else {
      if (this.player) {
        const videodata = this.player.doGetPlayer().getPlaybackVideoData();
        const videoquality = this.player.doGetPlayer().getVideoQuality();
        const streamType = this.player.doGetPlayer().getStreamType();
        if (videoquality && videoquality.bitrate) {
          ret = this.getNpawUtils().buildRenditionString(videoquality.width, videoquality.height, videoquality.bitrate);
        } else if (videodata && videodata.bitrate) {
          ret = this.getNpawUtils().buildRenditionString(videodata.width, videodata.height, videodata.bitrate);
        } else if (streamType === 'progressive') {
          if (videoquality) {
            ret = this.getNpawUtils().buildRenditionString(videoquality.width, videodata.height);
          } else if (videodata) {
            ret = this.getNpawUtils().buildRenditionString(videodata.width, videodata.height);
          }
        }
      }
    }
    return ret;
  }

  getIsLive() {
    return this.player ? this.player.isLive() : null;
  }

  getPlayerVersion() {
    if (this.wmcManager){
      return this.wmcManager.getAmcVersion();
    }
    return null;
  }

  getPlayerName() {
    return 'MediaKind';
  }

  getFramesPerSecond() {
    if (this.fps) return this.fps;

    let ret = null;
    if (this.player) {
      const videodata = this.player.doGetPlayer().getPlaybackVideoData();
      const videoquality = this.player.doGetPlayer().getVideoQuality();

      if (videoquality && videoquality.frameRate) {
        ret = videoquality.frameRate;
      } else if (videodata && videodata.frameRate) {
        ret = videodata.frameRate;
      }
    }
    return ret;
  }

  getThroughput() {
    return null;
  }

  getVideoCodec() {
    if (this.videoCodec) return this.videoCodec;

    let ret = null;
    if (this.player) {
      const videodata = this.player.doGetPlayer().getPlaybackVideoData();
      const videoquality = this.player.doGetPlayer().getVideoQuality();

      if (videoquality && videoquality.codec) {
        ret = videoquality.codec;
      } else if (videodata && videodata.codec) {
        ret = videodata.codec;
      }
    }
    return ret;
  }

  getAudioCodec() {
    if (this.audioCodec) return this.audioCodec;
    return null;
  }

  getTotalBytes() {
    return null;
  }

  getResource() {
    if (this.player && this.player.doGetPlayer) {
      return this.player.manifestUrl || this.player.doGetPlayer().getSource();
    }

    return null;
  }

  getResolution() {
    if (this.resolution) return this.resolution;

    return null;
  }

  getProgram() {
    let ret = null;
    if (wmcMgr.doGetProgramInfo) {
      ret = wmcMgr.doGetProgramInfo().name;
    }

    return ret;
  }
}
