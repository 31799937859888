import VIDEO_LIBRARY_URLS from '../../utils/video-library-urls';
import type {HlsjsConfig} from './types';

// eslint-disable-next-line import/prefer-default-export
export const DEFAULT_HLSJS_CONFIG: HlsjsConfig = {
    script: VIDEO_LIBRARY_URLS.hlsjs,
    settings: {
        // This buffer length (in seconds) is used by live and vod assets.
        backBufferLength: 90,

        fragLoadingTimeOut: 30 * 1000, // default 60 * 1000
        manifestLoadingTimeOut: 10 * 1000, // default 10 * 1000
        levelLoadingTimeOut: 10 * 1000, // default 10 * 1000

        fragLoadingMaxRetry: 3, // default 6
        manifestLoadingMaxRetry: 0, // Default is 1, however we want to control the retry attempts
        levelLoadingMaxRetry: 2, // default 4

        fragLoadingMaxRetryTimeout: 32 * 1000, // default 64 * 1000
        manifestLoadingMaxRetryTimeout: 32 * 1000, // default 64 * 1000
        levelLoadingMaxRetryTimeout: 32 * 1000, // default 64 * 1000

        fragLoadingRetryDelay: 0.5 * 1000, // default 1 * 1000
        manifestLoadingRetryDelay: 0.5 * 1000, // default 1 * 1000
        levelLoadingRetryDelay: 0.5 * 1000, // default 1 * 1000
    },
};
