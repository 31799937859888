export const VIDEO_EVENTS = [
    'durationchange',
    'ended',
    'error',
    'loadstart',
    'pause',
    'play',
    'replay',
    'playing',
    'seeking',
    'seeked',
    // 'timeupdate',
    'volumechange',
    'waiting',
    'stalled',

    // Custom events
    'fs-fatal-error', // When all attempts to get playback have failed
    'fs-source-error-retry', // When a source has issues with playback that we are actively trying to recover from
    'fs-source-error-recovered', // When a source has recovered from an error and has playback
    'fs-source-blacklisted', // When a source cannot achieve playback, and will attempt to fallback to another source
    'fs-source-updated',
    'fs-mounted',
    'fs-captions-updated',
    'fs-seek-requested',
] as const;

export const ALL_VIDEO_EVENTS = [
    'abort',
    'afterprint',
    'afterscriptexecute',
    'autocomplete',
    'autocompleteerror',
    'beforeprint',
    'beforescriptexecute',
    'beforeunload',
    'blur',
    'cancel',
    'canplay',
    'canplaythrough',
    'change',
    'click',
    'close',
    'connect',
    'contextmenu',
    'DOMContentLoaded',
    'durationchange',
    'emptied',
    'ended',
    'error',
    'focus',
    'hashchange',
    'input',
    'invalid',
    'languagechange',
    'load',
    'loadeddata',
    'loadedmetadata',
    'loadend',
    'loadstart',
    'message',
    'offline',
    'online',
    'open',
    'pagehide',
    'pageshow',
    'pause',
    'play',
    'playing',
    'popstate',
    // 'progress',
    'ratechange',
    'readystatechange',
    'reset',
    'seeked',
    'seeking',
    'select',
    'show',
    'sort',
    'stalled',
    'storage',
    'submit',
    'suspend',
    // 'timeupdate',
    'toggle',
    'unload',
    'volumechange',
    'waiting',

    'webkitbeginfullscreen',
    'webkitendfullscreen',
] as const;
