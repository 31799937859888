/**
 * Removes query parameters from a URL.
 *
 * @param src - The source URL string to process.
 * @returns The URL without query parameters, or the original string if its not a URL (this is because we might not be playing from a URL resource)
 */
export default function stripQueryParams(src: string): string {
    try {
        const sourceUrl = new URL(src);
        const sourceSrcWithoutParams = sourceUrl.origin + sourceUrl.pathname;

        return sourceSrcWithoutParams;
    } catch {
        return src;
    }
}
