import {
    getLocalStorageValue,
    isMsEdgeChromium,
    isWindows,
} from '@fsa-streamotion/browser-utils';

import merge from 'lodash/merge';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import result from 'lodash/result';
import type RxPlayer from 'rx-player';
import type {
    IKeySystemOption,
    ILoadVideoOptions,
    IManifestLoader,
    IPeriodChangeEvent,
} from 'rx-player/types';

import type {BreakToRemove, KeySystemConfig} from '../../types';
import {loadLibrary, triggerCustomEvent} from '../../utils/browser';
import {BITRATE_STORAGE_KEY} from '../../utils/storage-keys';
import PlaybackNative from '../native';
import type {CanPlaySourceParams, KeySystemsOption} from '../types';
import RxPlayerAudio from './audio';
import RxPlayerBuffer from './buffer';
import RxPlayerCaptions from './captions';
import {DEFAULT_RXPLAYER_CONFIG} from './constants';
import RxPlayerCustomBitrate from './custom-bitrate';
import RxPlayerDiagnostics from './diagnostics';
import RxPlayerEme from './eme';
import RxPlayerError from './error';
import RxPlayerLivestream from './livestream';
import type {RxPlayerConfig} from './types';

const fileExtensionCheck = /\.mpd($|\?|;)/i;
const PLAYREADY_KEYSYSTEM_KEY = 'com.microsoft.playready';
const WIDEVINE_KEYSYSTEM_KEY = 'com.widevine.alpha';
// some videos may stall when seeking to the end of the video.
// This stall issue exists on the web and most CTV platforms, except for Xbox.
// Add this offset to avoid seeking to the end of the video
const END_SEEK_OFFSET = 1;

const DEFAULT_START_AT = 0.1;

export default class PlaybackRx extends PlaybackNative {
    rxPlayerInstance: RxPlayer | null = null;
    _duration = 0;
    _isPaused = true;
    _segmentRetryTimes = 0;
    _rxPlayerConfig: RxPlayerConfig | null = null;
    _hasFireFirstPlay = false;
    _isPlayerReadyToPlay = false;
    _hasPlayAlreadyBeenCalled = false;
    _intermittentStallDetector = -1;
    _loadVideoConfig: ILoadVideoOptions = {
        transport: 'dash',
        autoPlay: false,
        textTrackMode: 'html',
    };

    declare controllerLivestreamInstance: RxPlayerLivestream;
    declare controllerAudioInstance: RxPlayerAudio;
    declare controllerBufferInstance: RxPlayerBuffer;
    declare controllerCaptionsInstance: RxPlayerCaptions;
    declare controllerCustomBitrateInstance: RxPlayerCustomBitrate;
    declare controllerError: RxPlayerError;
    declare controllerDiagnostics: RxPlayerDiagnostics;

    /**
     * #breaksToRemove - all breaks need to be removed
     */
    #breaksToRemove: BreakToRemove[] = [];
    override async removeBreaks(
        breaksToRemove: BreakToRemove[],
        timeToRestore: number
    ): Promise<void> {
        this.#breaksToRemove.push(...breaksToRemove);
        this.rxPlayerInstance?.loadVideo({
            ...this._loadVideoConfig,
            autoPlay: true,
            startAt: {position: timeToRestore},
        });
    }

    static override async canPlaySource({
        src,
        type,
        keySystems,
    }: CanPlaySourceParams): Promise<CanPlayTypeResult> {
        const {MediaSource, WebKitMediaSource} = window || {};
        // We need to have MediaSource available in the browser.
        const hasDrmSupportOrIsUnnecessary =
            !keySystems ||
            (await PlaybackRx.getAvailableKeySystems(keySystems)).length > 0;

        // MediaSource is an object on PS4 (Webmaf 3) but a function on other devices
        if (
            (typeof (MediaSource || WebKitMediaSource) === 'function' ||
                typeof MediaSource === 'object') &&
            hasDrmSupportOrIsUnnecessary
        ) {
            if (result(type, 'toLowerCase') === 'application/dash+xml') {
                // Mime Type suggests dash, so we cool.
                return 'probably';
            } else if (fileExtensionCheck.test(src)) {
                // Educated guess with suggested file format being dash.
                return 'probably';
            } else {
                // Neither of these things, so... lets go with no?
                return '';
            }
        }

        return '';
    }

    static override async getAvailableKeySystems(
        keySystems: KeySystemsOption = {}
    ): Promise<MediaKeySystemAccess[]> {
        // PS4 doesn't support window.navigator.requestMediaKeySystemAccess
        // so we'll do a check to make sure it exists before calling it
        // eslint-disable-next-line compat/compat
        if (!window.navigator.requestMediaKeySystemAccess) {
            return [];
        }

        // Workaround for VPW-180 Edge dropping frames. Cause was Playready DRM, fix was to temporarily force it to use something else.
        // TODO revisit this on 4K feature story since we'll need Playready back.
        // Full context in https://foxsportsau.atlassian.net/browse/VPW-180
        const adjustedKeySystems =
            isMsEdgeChromium() && isWindows()
                ? omit(keySystems, [PLAYREADY_KEYSYSTEM_KEY])
                : keySystems;

        const requestMediaKeySystemAccessPromises = Object.entries(
            adjustedKeySystems
        ).map(([keySystemName, keySystemConfig]) => {
            const config = PlaybackRx.getCapabilityConfig(keySystemConfig);

            // eslint-disable-next-line compat/compat
            return window.navigator
                .requestMediaKeySystemAccess(keySystemName, config)
                .catch(() => null);
        });

        return await Promise.all(requestMediaKeySystemAccessPromises).then(
            (keySystemAccesses) =>
                keySystemAccesses.filter(Boolean) as MediaKeySystemAccess[]
        );
    }

    static getCapabilityConfig({
        videoContentType,
        audioContentType,
        videoRobustness,
        audioRobustness,
        ...rest
    }: KeySystemConfig): MediaKeySystemConfiguration[] {
        const strictSupportedConfigurations = {
            ...(audioContentType && {
                audioCapabilities: [
                    {
                        contentType: audioContentType,
                        robustness: audioRobustness || '',
                    },
                ],
            }),
            ...(videoContentType && {
                videoCapabilities: [
                    {
                        contentType: videoContentType,
                        robustness: videoRobustness || '',
                    },
                ],
            }),
            ...pick(rest, ['persistentState', 'distinctiveIdentifier']),
        };

        const looseSupportedConfigurations = {
            ...(audioContentType && {
                audioCapabilities: [
                    {
                        contentType: audioContentType,
                        robustness: audioRobustness || '',
                    },
                ],
            }),
            ...(videoContentType && {
                videoCapabilities: [
                    {
                        contentType: videoContentType,
                        robustness: videoRobustness || '',
                    },
                ],
            }),
            ...pick(rest, ['distinctiveIdentifier']),
        };

        return [strictSupportedConfigurations, looseSupportedConfigurations];
    }

    override get isPlaying(): boolean {
        if (!this.rxPlayerInstance) {
            return false;
        }

        if (this.rxPlayerInstance.getPlayerState() === 'STOPPED') {
            // if playback has been stopped (most likely due to an error).
            // we don't want to return the current value in rxInstance but the last know value
            return !this._isPaused;
        }

        return !this.rxPlayerInstance.isPaused();
    }

    override pause(): void {
        this._isPaused = true; // Store this state so we know what to do after period switching (for PS5)
        super.pause();
    }

    override play(): void {
        // prevent call play before player ready to play
        if (!this._hasFireFirstPlay && !this._isPlayerReadyToPlay) {
            this._hasPlayAlreadyBeenCalled = true;

            return;
        }

        if (this.rxPlayerInstance) {
            this.preloadAuto(); // Make sure we always have a stream ready.
            this.rxPlayerInstance.play();
        }

        this._isPaused = false; // Store this state so we know what to do after period switching (for PS5)
        this._hasFireFirstPlay = true;
    }

    /**
     * Get the RxPlayer config, loading it if it hasn't already been loaded.
     *
     * This is a combination of the default RxPlayer config and, if provided, the
     * external RxPlayer config.
     *
     * @returns the combined RxPlayer config
     */
    private async _getRxPlayerConfig(): Promise<RxPlayerConfig> {
        if (!this._rxPlayerConfig) {
            // eslint-disable-next-line no-console
            console.debug(
                `[PlayerTech] Default RxPlayer config:\n\n${JSON.stringify(
                    DEFAULT_RXPLAYER_CONFIG,
                    null,
                    2
                )}`
            );

            const externalRxPlayerConfig =
                await this.options.getRxPlayerConfig?.();

            if (externalRxPlayerConfig || window.rxPlayerConfig) {
                // eslint-disable-next-line no-console
                console.debug(
                    `[PlayerTech] External RxPlayer config:\n\n${JSON.stringify(
                        externalRxPlayerConfig,
                        null,
                        2
                    )}`
                );

                // eslint-disable-next-line no-console
                console.debug(
                    `[PlayerTech] Window RxPlayer config:\n\n${JSON.stringify(
                        window.rxPlayerConfig,
                        null,
                        2
                    )}`
                );

                // Merge default and provided rx-player configs, preferring provided rx-player config values where there's a conflict.
                this._rxPlayerConfig = merge(
                    {},
                    DEFAULT_RXPLAYER_CONFIG,
                    externalRxPlayerConfig,
                    window.rxPlayerConfig
                );
            } else {
                this._rxPlayerConfig = DEFAULT_RXPLAYER_CONFIG;
            }

            // eslint-disable-next-line no-console
            console.debug(
                `[PlayerTech] Effective RxPlayer config:\n\n${JSON.stringify(
                    this._rxPlayerConfig,
                    null,
                    2
                )}`
            );
        }

        return this._rxPlayerConfig;
    }

    private _manifestLoader: IManifestLoader = (
        info,
        {resolve, reject}
    ): void => {
        if (info.url) {
            fetch(info.url)
                .then((response) => response.text())
                .then((manifestText) => {
                    const manifest = new DOMParser().parseFromString(
                        manifestText,
                        'application/xml'
                    );
                    const periods = manifest.querySelectorAll('Period');
                    const clipIdsToRemove = this.#breaksToRemove.flatMap(
                        ({breakClipIds}) => breakClipIds
                    );

                    periods.forEach((period) => {
                        const periodId = period.getAttribute('id');

                        if (clipIdsToRemove.includes(periodId || '')) {
                            period?.parentNode?.removeChild(period);
                        }
                    });
                    resolve({
                        data: manifest,
                    });
                })
                .catch(reject);
        } else {
            reject(new Error('Not a valid url'));
        }
    };

    private playWhenReady = (state: string): void => {
        if (!this.rxPlayerInstance || state !== 'LOADED') {
            return;
        }

        this.rxPlayerInstance.removeEventListener(
            'playerStateChange',
            this.playWhenReady
        );

        this._isPlayerReadyToPlay = true;

        const isLive = this.rxPlayerInstance.isLive();
        const dvrStartTime = this.rxPlayerInstance.getMinimumPosition() || 0;

        if (this.options.startAt === -1) {
            if (!isLive) {
                // note we will never be auto-recovering to a startAt of -1 since our DVR window will be moving
                this.rxPlayerInstance.seekTo({
                    position: dvrStartTime + DEFAULT_START_AT,
                });
            }
        } else {
            this.rxPlayerInstance.seekTo({
                position:
                    this.isAttemptingToRecover && isLive
                        ? // if we are recovering, seek to our last know position
                          // since our DVR window is moving make sure we don't set out position
                          Math.max(
                              dvrStartTime,
                              this.rxPlayerInstance.getLastStoredContentPosition() ||
                                  0
                          )
                        : dvrStartTime + this.options.startAt,
            });
        }

        // if we prevented the play request before
        // or we should auto play
        // then call play method now
        if (this._hasPlayAlreadyBeenCalled || this.options.autoPlay) {
            this.play();
        }

        this._startIntermittentStallDetect();
    };

    private _reloadPlayer(): void {
        if (
            !this.rxPlayerInstance ||
            this.rxPlayerInstance.state === 'RELOADING'
        ) {
            return;
        }

        this.rxPlayerInstance.reload({
            reloadAt: {
                relative: 1,
            },
            autoPlay: true,
        });
    }

    private _startIntermittentStallDetect(): void {
        const STALL_DETECT_INTERVAL = 3000;
        const BUFFERING_TIMEOUT = 10_000;

        let previousTime = -1;
        let bufferingStartTime = -1;

        window.clearInterval(this._intermittentStallDetector);

        const seekForwardALittle = (): void => {
            const STALL_FIX_SEEK_OFFSET = 0.5;

            previousTime += STALL_FIX_SEEK_OFFSET;

            this.currentTime = previousTime;
        };

        this._intermittentStallDetector = window.setInterval(() => {
            if (!this._hasFireFirstPlay) {
                return;
            }

            const {currentTime} = this;

            if (
                currentTime <= 0 ||
                (previousTime === currentTime && !this.videoElement?.paused)
            ) {
                if (!this.rxPlayerInstance) {
                    return;
                }

                const currentPlayerState = this.rxPlayerInstance.state;

                if (currentPlayerState === 'FREEZING') {
                    seekForwardALittle();
                } else if (currentPlayerState === 'BUFFERING') {
                    if (bufferingStartTime === -1) {
                        bufferingStartTime = Date.now();
                    } else if (
                        Date.now() - bufferingStartTime >
                        BUFFERING_TIMEOUT
                    ) {
                        // buffering takes too many time than expected
                        // seems skip forward cant fix the issues
                        // try reload content to see if playback could recover
                        this._reloadPlayer();

                        bufferingStartTime = -1;
                    }
                }
            } else {
                previousTime = this.currentTime;
                bufferingStartTime = -1;
            }
        }, STALL_DETECT_INTERVAL);
    }

    private triggerPeriodChangeEvents = (
        currentPeriod: IPeriodChangeEvent
    ): void => {
        const availablePeriods = this.rxPlayerInstance?.getAvailablePeriods();
        const currentPeriodIndex =
            availablePeriods?.findIndex(({id}) => id === currentPeriod.id) || 0;
        const lastPeriod = availablePeriods?.[currentPeriodIndex - 1];

        triggerCustomEvent(this.videoElement, 'fs-period-switch-started', {
            periodInfo: {
                fromStreamInfo: {
                    id: lastPeriod?.id,
                },
                toStreamInfo: {
                    id: currentPeriod?.id,
                    start: currentPeriod?.start,
                },
            },
        });
        triggerCustomEvent(this.videoElement, 'fs-period-switch-completed', {
            periodInfo: {
                toStreamInfo: {
                    id: currentPeriod?.id,
                    start: currentPeriod?.start,
                },
            },
        });
    };

    private getWorkerBlob(workerUrl?: string): Promise<Blob | undefined> {
        return new Promise((res) => {
            if (!workerUrl) {
                res(undefined);

                return;
            }

            const xhrRequest = new XMLHttpRequest();

            xhrRequest.open('GET', workerUrl);

            xhrRequest.onload = () => {
                const {status, response} = xhrRequest;

                if (status && status >= 200 && status < 400) {
                    const workerBlob = new Blob([response], {
                        type: 'application/javascript',
                    });

                    res(workerBlob);
                } else {
                    res(undefined);
                }
            };

            xhrRequest.onerror = () => {
                res(undefined);
            };

            xhrRequest.send();
        });
    }

    private getWasmArrayBuffer(
        wasmUrl?: string
    ): Promise<ArrayBuffer | undefined> {
        return new Promise((res) => {
            if (!wasmUrl) {
                res(undefined);

                return;
            }

            const xhrRequest = new XMLHttpRequest();

            xhrRequest.open('GET', wasmUrl);

            xhrRequest.responseType = 'arraybuffer';

            xhrRequest.onload = () => {
                const {status, response} = xhrRequest;

                if (status && status >= 200 && status < 400) {
                    const wasmArrayBuffer = new Uint8Array(response);

                    res(wasmArrayBuffer);
                } else {
                    res(undefined);
                }
            };

            xhrRequest.onerror = () => {
                res(undefined);
            };

            xhrRequest.send();
        });
    }

    private async setupWorker(
        workerUrl?: string,
        wasmUrl?: string
    ): Promise<void> {
        const playerInstance = this.rxPlayerInstance;
        const {MULTI_THREAD} = window.RxPlayer?.Features || {};

        const [workerBlob, wasmArrayBuffer] = await Promise.all([
            this.getWorkerBlob(workerUrl),
            this.getWasmArrayBuffer(wasmUrl),
        ]);

        return new Promise((resolve, reject) => {
            if (!playerInstance || !MULTI_THREAD || !workerBlob) {
                reject('Worker file is not exists or download failed');

                return;
            }

            window.RxPlayer?.addFeatures([MULTI_THREAD]);

            playerInstance
                .attachWorker({
                    workerUrl: workerBlob,
                    dashWasmUrl: wasmArrayBuffer,
                })
                .then(resolve)
                .catch(() => {
                    reject('Failed to attach work');
                });
        });
    }

    override async setup(): Promise<boolean> {
        const {
            script,
            workerUrl,
            wasmUrl,
            loadVideoSettings,
            constructorSettings,
            keySystemSettings,
        } = await this._getRxPlayerConfig();

        let rxPlayerLibrary: RxPlayerWithFeatures | undefined;

        if (script?.src && script.integrity) {
            await loadLibrary({
                src: script.src,
                integrity: script.integrity,
                loadedLibrary: window.RxPlayer,
                id: 'smweb-playertech-rxplayer-script',
            });

            rxPlayerLibrary = window.RxPlayer;
        }

        if (!rxPlayerLibrary) {
            throw 'PlayerTech: RxPlayer was requested, but no suitable library was found.';
        }

        rxPlayerLibrary.LogLevel = this.options.DEBUG_LIBS ? 'DEBUG' : 'ERROR';

        if (!this.videoElement) {
            return false;
        }

        const initialBitrate = getLocalStorageValue({
            key: BITRATE_STORAGE_KEY,
            defaultValue: undefined,
        }) as number | undefined;

        // eslint-disable-next-line new-cap
        this.rxPlayerInstance = new rxPlayerLibrary({
            videoElement: this.videoElement,
            baseBandwidth: initialBitrate,
            ...constructorSettings,
        });

        try {
            await this.setupWorker(workerUrl, wasmUrl);
        } catch (e) {
            console.warn(e);
        }

        this.controllerEmeInstance = new RxPlayerEme({
            playbackTech: this,
            playbackHandler: this.rxPlayerInstance,
        });

        let keySystemOption: IKeySystemOption[] = [];

        if (this.keySystems) {
            // We need to filter out unsupported keySystems
            // e.g. Windows Edge do not support PlayReady (license call error)
            const availableKeySystems = omit(
                this.keySystems,
                Object.keys(this.keySystems).filter((keySystemName) =>
                    [...this.availableKeySystems].every(
                        ({keySystem}) => keySystem !== keySystemName
                    )
                )
            );

            keySystemOption = Object.entries(availableKeySystems).map(
                ([keySystemName, keySystemConfig]) => {
                    let effectiveKeySystemName: string;

                    if (keySystemName.includes(PLAYREADY_KEYSYSTEM_KEY)) {
                        effectiveKeySystemName =
                            this.options.playReadyKeySystem ?? keySystemName;
                    } else if (keySystemName.includes(WIDEVINE_KEYSYSTEM_KEY)) {
                        effectiveKeySystemName =
                            this.options.widevineKeySystem ?? keySystemName;
                    } else {
                        effectiveKeySystemName = keySystemName;
                    }

                    const [capabilities] =
                        PlaybackRx.getCapabilityConfig(keySystemConfig);

                    return {
                        type: effectiveKeySystemName,
                        getLicense: async (message: Uint8Array) =>
                            await (
                                this.controllerEmeInstance as RxPlayerEme
                            )?.makeLicenseCall({
                                keySystemName: effectiveKeySystemName,
                                keySystemConfig,
                                message,
                            }),
                        videoCapabilitiesConfig: capabilities && {
                            type: 'full',
                            value: capabilities.videoCapabilities ?? [],
                        },
                        audioCapabilitiesConfig: capabilities && {
                            type: 'full',
                            value: capabilities.audioCapabilities ?? [],
                        },
                        ...keySystemSettings,
                    };
                }
            );
        }

        this.rxPlayerInstance.addEventListener(
            'playerStateChange',
            this.playWhenReady
        );

        this._loadVideoConfig = {
            ...loadVideoSettings,
            ...this._loadVideoConfig,
            url: this.src || '',
            keySystems: keySystemOption,
            textTrackElement: this.options.rxPlayerCaptionContainerDiv,
            startAt:
                this.options.startAt === -1
                    ? undefined
                    : {position: this.options.startAt},
        };

        if (this.options.enableRemoveBreakInRxPlayer) {
            this._loadVideoConfig.manifestLoader = this._manifestLoader;
            this.rxPlayerInstance.addEventListener(
                'periodChange',
                this.triggerPeriodChangeEvents
            );
        }

        this.rxPlayerInstance.loadVideo(this._loadVideoConfig);

        this.controllerBufferInstance = new RxPlayerBuffer(
            this,
            this.rxPlayerInstance
        );

        this.controllerAudioInstance = new RxPlayerAudio(
            this,
            this.rxPlayerInstance
        );
        this.controllerAudioInstance.setup();

        this.controllerDiagnostics = new RxPlayerDiagnostics(
            this.rxPlayerInstance,
            this.videoElement,
            this.src ?? '',
            this.cdnProvider,
            this.hasSsai
        );

        this.controllerCaptionsInstance = new RxPlayerCaptions(
            this,
            this.rxPlayerInstance
        );

        this.controllerCaptionsInstance.setup();
        this.controllerCaptionsInstance.setupPiPListeners();

        this.controllerCustomBitrateInstance = new RxPlayerCustomBitrate(
            this,
            this.rxPlayerInstance
        );

        this.controllerLivestreamInstance = new RxPlayerLivestream(
            this,
            this.rxPlayerInstance
        );

        this.controllerError = new RxPlayerError(this, this.rxPlayerInstance);

        return true;
    }

    override get duration(): number {
        if (!this.rxPlayerInstance) {
            return 0;
        }

        const {isLive, dvrStart} = this.controllerLivestreamInstance;

        if (isLive) {
            const duration = this.rxPlayerInstance.getLivePosition() || 0;

            this._duration = duration - dvrStart;
        } else {
            if (this._duration > 0) {
                return this._duration;
            }

            this._duration =
                this.rxPlayerInstance.getMaximumPosition() ||
                this.rxPlayerInstance.getMediaDuration();
        }

        return this._duration;
    }

    override get playbackRate(): number {
        return this.rxPlayerInstance?.getPlaybackRate() || 1;
    }

    override set playbackRate(newRate) {
        this.rxPlayerInstance?.setPlaybackRate(newRate);
    }

    override async destroy(): Promise<void> {
        this.rxPlayerInstance?.removeEventListener(
            'playerStateChange',
            this.playWhenReady
        );

        if (this.options.enableRemoveBreakInRxPlayer) {
            this.rxPlayerInstance?.removeEventListener(
                'periodChange',
                this.triggerPeriodChangeEvents
            );
        }

        window.clearInterval(this._intermittentStallDetector);

        this.rxPlayerInstance?.stop();
        this.rxPlayerInstance?.dispose();

        await super.destroy();
    }

    override get currentTime(): number {
        if (!this.rxPlayerInstance) {
            return this.options.startAt;
        }

        // Rxplayer sets currentTime to 0 when on error.
        // When we auto-recover from an error, we need to getLastStoredContentPosition instead of getPosition.
        const lastStoredContentPosition =
            this.rxPlayerInstance.getLastStoredContentPosition();

        const {isLive = false, dvrStart = 0} =
            this.controllerLivestreamInstance || {};

        if (
            this.rxPlayerInstance?.state === 'STOPPED' &&
            lastStoredContentPosition
        ) {
            return lastStoredContentPosition;
        }

        try {
            const currentPosition = this.rxPlayerInstance.getPosition();

            if (isLive) {
                return currentPosition - dvrStart;
            }

            return currentPosition;
        } catch (err) {
            return this.options.startAt;
        }
    }

    override set currentTime(seconds: number) {
        let seekToPosition = parseFloat(seconds.toString());
        const maxSafeCurrentTime =
            (this.rxPlayerInstance?.getMaximumPosition() || this._duration) -
            END_SEEK_OFFSET;
        const {isLive, dvrStart} = this.controllerLivestreamInstance;

        if (isLive || dvrStart > 0) {
            seekToPosition += dvrStart;
        }

        this.rxPlayerInstance?.seekTo({
            position: Math.min(seekToPosition, maxSafeCurrentTime),
        });
    }
}
