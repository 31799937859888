export const VIDEO_FS_ERROR_TYPES = {
    NO_PLAYBACK_HANDLER: 'NoPlaybackHandler',
    EME_NO_MEDIA_KEYS: 'EmeNoMediaKeys',
    EME_REQUEST: 'EmeRequest',
    ALL_SOURCES_BLACKLISTED: 'AllSourcesBlacklisted',
} as const;

export type PlayerTechErrorArgs = {
    readonly type: string;
    readonly message: string;
};

export default class PlayerTechError extends Error {
    readonly type: string;

    constructor({type, message}: PlayerTechErrorArgs) {
        super(message);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PlayerTechError);
        }

        this.name = `PlayerTech${type || 'Unknown'}Error`;
        this.type = type;
    }
}
