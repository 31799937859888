export default class NativeAdsAdapter {
  getPosition() {
    var pos = this.getNpawReference().Constants.AdPosition;
    var ret = pos.Midroll;
    var video = this.getVideo();
    let integration = '';
    if (
      this.player !== null &&
      this.player.ads !== null &&
      this.player.ads.currentAdBreak !== null &&
      this.player.ads.currentAdBreak.integration !== null
    ) {
      integration = this.player.ads.currentAdBreak.integration;
    }
    const isClientSide = 'google-ima' === integration;
    if (video) {
      var adapter = video.getAdapter();
      if (adapter) {
        const playhead = video.getPlayhead();
        const duration = video.getDuration() || 0;
        if (playhead < 0.1 || (!isClientSide && !adapter.flags.isJoined)) {
          ret = pos.Preroll;
        } else if ((isClientSide && playhead + 1 >= duration) || (!isClientSide && playhead + 1 + this.getDuration() >= duration)) {
          ret = pos.Postroll;
        }
      }
    }
    return ret;
  }

  quartileListener(e) {
    var pos = this.getPlayhead() / this.duration;
    if (pos >= 0.25) {
      this.fireQuartile(1);
      if (pos >= 0.5) {
        this.fireQuartile(2);
        if (pos >= 0.75) {
          this.fireQuartile(3);
        }
      }
    }
  }

  resumeAdListener(e) {
    this.fireResume();
  }

  getIsVisible() {
    try {
      var tag = this.player ? this.player.element : null;
    } catch (err) { }
    return tag ? this.getNpawUtils().calculateAdViewability(tag) : false;
  }

  skipAdListener(e) {
    this.fireSkip();
  }

  _getCurrentAd() {
    var ads = this.player.ads;
    var ret = null;
    if (ads) {
      if (ads.currentAds && ads.currentAds[0]) {
        ret = ads.currentAds[0];
      } else if (ads && ads.currentAdBreak && ads.currentAdBreak.ads[this.adCount - 1]) {
        ret = ads.currentAdBreak.ads[this.adCount - 1];
      }
    }
    return ret;
  }

  errorAdListener(e) {
    this.fireError();
    if (!this.flags.isStarted) {
      this.adCount = (this.adCount || 0) + 1;
    }
  }

  getGivenBreaks() {
    var ret = null;
    if (this.player.ads.scheduledAdBreaks) {
      ret = this.player.ads.scheduledAdBreaks.length || ret;
    }
    return ret;
  }

  getIsSkippable() {
    var current = this._getCurrentAd();
    return current && current.skipOffset ? current.skipOffset >= 0 : false;
  }

  getIsFullscreen() {
    var ret = false;
    if (this.player.element && this.player.element.clientWidth && this.player.element.clientHeight) {
      ret = (window.innerHeight <= this.player.element.clientHeight + 30 && window.innerWidth <= this.player.element.clientWidth + 30);
    }
    return ret;
  }

  getVersion() {
    return '7.0.14-theoplayer2-ads-jsclass';
  }

  getAudioEnabled() {
    return !this.player.muted && this.player.volume > 0;
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
    if (this.player && this.player.ads && this.references) {
      for (var key in this.references) {
        this.player.ads.removeEventListener(key, this.references[key]);
      }
      delete this.references;
    }
    if (this.player && this.playerReferences) {
      for (var key2 in this.playerReferences) {
        this.player.removeEventListener(key2, this.playerReferences[key2]);
      }
      delete this.playerReferences;
    }
  }

  breakBeginListener(e) {
    this.adCount = 0;
    this.actualSlot = e.ad;
  }

  registerListeners() {
    if (this.player.ads) {
      this.isDAI = false;
      this.getNpawUtils().logAllEvents(this.player.ads);
      this.monitorPlayhead(true, false, 2000);
      this.references = {
        adbegin: this.playAdListener.bind(this),
        adend: this.endedAdListener.bind(this),
        aderror: this.errorAdListener.bind(this),
        adskip: this.skipAdListener.bind(this),
        adbreakbegin: this.breakBeginListener.bind(this),
        adbreakend: this.breakEndListener.bind(this)
      };
      if (typeof google !== 'undefined' && google.ima.dai) {
        this.isDAI = true;
      }
      this.playerReferences = {
        pause: this.pauseAdListener.bind(this),
        playing: this.resumeAdListener.bind(this),
        play: this.resumeAdListener.bind(this),
        timeupdate: this.quartileListener.bind(this)
      };
      for (var key in this.references) {
        this.player.ads.addEventListener(key, this.references[key]);
      }
      for (var key2 in this.playerReferences) {
        this.player.addEventListener(key2, this.playerReferences[key2]);
      }
    }
  }

  getPlayhead() {
    let ret = this.player.currentTime;
    if (this.isDAI == true) {
      ret = this.player.currentTime - this.playhead;
    }
    return ret;
  }

  getDuration() {
    var current = this._getCurrentAd();
    return current && current.duration ? current.duration : this.player.duration;
  }

  pauseAdListener(e) {
    this.firePause();
  }

  breakEndListener(e) {
    this.fireBreakStop();
    const adapter = this.getVideo().getAdapter();
    if (adapter) {
      adapter.fireResume();
    }
    this.getVideo().requestBuilder.lastSent.givenAds = undefined;
    const postrollPosition = this.getNpawReference().Constants.AdPosition.Postroll;
    if (this.getPosition() === postrollPosition && this.player.ads.scheduledAds.length === 0) {
      // This means we have no more ads to see and should fire a stop at the video adapter
      this.getVideo().getAdapter().fireStop({}, 'breakEndListener');
    }
  }

  getGivenAds() {
    return this.actualSlot ? this.actualSlot.ads.length : null;
  }

  endedAdListener(e) {
    const adapter = this.getVideo().getAdapter();
    if (adapter && !adapter.flags.isStarted) {
      this.fireStart();
    }
    this.fireStop({
      adPlayhead: this.duration
    });
  }

  getResource() {
    var ret = null;
    var current = this._getCurrentAd();
    if (current) {
      if (current.mediaUrl) {
        ret = current.mediaUrl;
      } else if (current.mediaFiles) {
        ret = current.mediaFiles[0].resourceURI || current.mediaFiles[0].contentURL;
      }
    }
    return ret;
  }

  getTitle() {
    var current = this._getCurrentAd();
    return current && current.title ? current.title : 'unknown';
  }

  playAdListener(e) {
    this.adCount = (this.adCount || 0) + 1;
    var adapter = this.getVideo().getAdapter();
    if (adapter) {
      adapter.ended = false;
      adapter.fireInit();
      adapter.firePause();
    }
    this.fireStart({
      adPlayhead: '0'
    });
    this.fireJoin({
      adPlayhead: '0'
    });
    this.duration = this.getDuration();
    this.playhead = this.player.currentTime;
  }
}
