import HlsjsAdapters from './video_adapters/hlsjs/Hlsjs';
import BitmovinAdapter from './video_adapters/bitmovin8/Bitmovin8';
import VideoJsAdapter from './video_adapters/videojs/Videojs';
import ShakaAdapter from './video_adapters/shaka/Shaka';
import Html5Adapter from './video_adapters/html5/Html5';
import FlvjsAdapter from './video_adapters/flvjs/Flvjs';
import JwplayerAdapter from './video_adapters/jwplayer/Jwplayer';
import DashJSAdapter from './video_adapters/dashjs/Dashjs';
import NagraAdapter from './video_adapters/nagra/Nagra';
import BrightcoveAdapter from './video_adapters/brightcove/Brightcove';
import TheoplayerAdapter from './video_adapters/theoplayer/Theoplayer';
import ImaAdapter from './ads_adapters/ima/Ima';
import BrightcoveFreewheelAdsAdapter from './ads_adapters/brightcoveFreewheel/BrightcoveFreewheel';
import NagraReactNativeAdapter from './video_adapters/nagraReactNative/NagraReactNative';
import ChromecastAdapter from './video_adapters/chromecast/Chromecast';
import ChromecastCafAdapter from './video_adapters/chromecastCaf/ChromecastCaf';
import ViaccessOrcaAdapter from './video_adapters/viaccessorca/Viaccessorca';
import HbbTvAdapter from './video_adapters/hbbtv/Hbbtv';
import ReactNativeVideoAdapter from './video_adapters/reactNativeVideo/ReactNativeVideoAdapter';
import VisualonAdapter from './video_adapters/visualon/Visualon';
import BitmovinReactNativeAdapter from './video_adapters/bitmovinReactNative/BitmovinReactNative';
import MediaKindWmcAdapter from './video_adapters/mediakindWmc/MediaKindWmcAdapter';
export default {
    video: {
        Hlsjs: HlsjsAdapters,
        Bitmovin: BitmovinAdapter,
        Videojs: VideoJsAdapter,
        Shaka: ShakaAdapter,
        Html5: Html5Adapter,
        Flvjs: FlvjsAdapter,
        Jwplayer: JwplayerAdapter,
        Dashjs: DashJSAdapter,
        Nagra: NagraAdapter,
        Brightcove: BrightcoveAdapter,
        Theoplayer: TheoplayerAdapter,
        NagraReactNative: NagraReactNativeAdapter,
        Chromecast: ChromecastAdapter,
        ChromecastCaf: ChromecastCafAdapter,
        ViaccessOrca: ViaccessOrcaAdapter,
        Hbbtv: HbbTvAdapter,
        ReactNativeVideo: ReactNativeVideoAdapter,
        Visualon: VisualonAdapter,
        BitmovinReactNative: BitmovinReactNativeAdapter,
        MediaKindWmc: MediaKindWmcAdapter
    },
    ads: {
        Ima: ImaAdapter,
        BrightcoveFreewheel: BrightcoveFreewheelAdsAdapter
    }
};
