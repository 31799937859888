// sort-imports-ignore TODO: Remove this line after removing npaw-helper
import {
    version as playerTechVersion,
    type default as PlayerTech,
} from '@fsa-streamotion/player-tech';

// Important! Keep this before npaw-plugin-nwf
// eslint-disable-next-line import/no-unassigned-import
import './npaw-helper';
import NpawPlugin, {type NpawPluginOptions} from 'npaw-plugin-nwf';
import {EMPTY, NEVER, type Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';

import type {PlaybackData, VideoErrorDetail} from '../../state/types';
import createVideoFsHtml5Adapter from './create-video-fs-html5-adapter';

/** Gets stream tracking single video
 *
 * @param payload - Payload for tracking single video (see below)
 * @param playerTech - Player Tech of the current video
 * @param youboraAccountId - youbora ID, eg foxsportsaustraliadev
 * @param videoElement - current video element
 * @param getYouboraOptions - Youbora options for .setAnalyticsOptions()
 * @param getYouboraAdapterConfig - Function to get the config object that contains custom adapter methods
 * @param logger  - Custom logger
 *
 * @returns youbora tracking stream
 */

type Params = {
    youboraAccountId: string | null;
    videoElement: HTMLVideoElement;
    logger: {error: (value: string) => void};
    getYouboraAdapterConfig: PlaybackData['getYouboraAdapterConfig'];
    getYouboraOptions: PlaybackData['getYouboraOptions'];
    playerTech: PlayerTech;
    youboraPlugin: NpawPlugin | null;
    screenIndex: number;
};

type VideoBufferDetail = {
    detail?: {
        isBuffering?: boolean;
        isSeeking?: boolean;
    };
} & Event;

export class YouboraPlugin {
    private static _instance: NpawPlugin | null

    public static getInstance(
        youboraAccountId: string | null,
        options?: NpawPluginOptions
    ): NpawPlugin | null{
        if (!youboraAccountId) return null;

        if (!this._instance)
            this._instance = new NpawPlugin(youboraAccountId, options);

        return this._instance
    }
}

export default function youboraTrackingForSingleVideo({
    playerTech,
    youboraAccountId,
    videoElement,
    getYouboraOptions,
    getYouboraAdapterConfig,
    logger,
    youboraPlugin,
    screenIndex,
}: Params): Observable<never> {
    // Only track if we have a source.
    // Mounted _may_ have an initial source, but it's probably very unlikely (whole get the key thing)
    if (!playerTech.currentSource || !youboraAccountId || !youboraPlugin) {
        return EMPTY;
    } else if (!getYouboraOptions || !getYouboraAdapterConfig) {
        logger.error(
            'StreamotionWebPlayer: Please provide getYouboraOptions & getYouboraAdapterConfig callbacks to enable Youbora tracking'
        );

        return EMPTY;
    }

    const videoKey = `split_view_${screenIndex}`;

    // We want to keep track of whether or not we've called stop, because otherwise we might accidentally call it twice (once on fatal error, once when we close player)
    let hasCalledStop = false;

    const videoErrorHandler = (event: VideoErrorDetail): void => {
        // Custom error fs-source-error-retry is in magical detail object from the error. This is because videoFS won't store the error
        // during a retry, and this is the only time we'll see what we're retrying.
        // If it's not set, go to the playerTech.error as you would normally.
        const error = event.detail?.error || playerTech.error;
        let errorDetailMessage;

        if (playerTech.errorDetail && 'message' in playerTech.errorDetail) {
            errorDetailMessage = playerTech.errorDetail.message;
        }

        const isFatalErrorRetry = event.type === 'fs-source-error-retry';

        // youboraPlugin.fireError(code, message, 'fatal'|'error')
        youboraPlugin?.getAdapter(videoKey).fireError(
            String(error?.code || 0),
            errorDetailMessage || error?.message || 'unknown',
            {
                fsFatalErrorRetry: isFatalErrorRetry,
            },
            isFatalErrorRetry ? 'error' : 'fatal'
        );

        if (!isFatalErrorRetry && !hasCalledStop) {
            // We're not retrying after this one
            youboraPlugin?.getAdapter(videoKey).fireStop();
            hasCalledStop = true;
        }
    };

    const videoBufferHandler = (event: VideoBufferDetail): void => {
        const {isBuffering = false, isSeeking = false} = event.detail || {};

        if (isSeeking) {
            return; // Handled by seek handler don't fire buffer
        }

        if (isBuffering) {
            youboraPlugin
                ?.getAdapter(videoKey)
                .fireBufferBegin({}, false, 'videoBufferStartHandler');
        } else {
            youboraPlugin
                ?.getAdapter(videoKey)
                .fireBufferEnd({}, 'videoBufferEndHandler');
        }
    };

    const videoSeekHandler = (): void =>
        youboraPlugin
            .getAdapter(videoKey)
            .fireSeekBegin?.({}, false, 'handleSeekBegin');

    // Manually listen to the error retry event, and fire off like we would on a full .error.
    videoElement.addEventListener('fs-source-error-retry', videoErrorHandler);
    videoElement.addEventListener('fs-stalled-buffering', videoBufferHandler);
    videoElement.addEventListener('fs-seek-requested', videoSeekHandler);

    youboraPlugin.registerAdapterFromClass(
        videoElement,
        createVideoFsHtml5Adapter({
            playerTechVersion,
            playerTech,
            adapterConfig: getYouboraAdapterConfig(playerTech),
            videoErrorHandler,
        }),
        null,
        videoKey
    );

    youboraPlugin.setAnalyticsOptions(getYouboraOptions(playerTech));
    youboraPlugin.getAdapter(videoKey).fireInit();

    return NEVER.pipe(
        // Clean up
        finalize(() => {
            videoElement.removeEventListener(
                'fs-source-error-retry',
                videoErrorHandler
            );
            videoElement.removeEventListener(
                'fs-stalled-buffering',
                videoBufferHandler
            );
            videoElement.removeEventListener(
                'fs-seek-requested',
                videoSeekHandler
            );

            // Youbora kill method.
            if (!hasCalledStop) {
                youboraPlugin?.getAdapter(videoKey).fireStop();
                hasCalledStop = true;
            }

            youboraPlugin?.removeAdapter(videoKey);
        })
    );
}
