import type RxPlayer from 'rx-player';

import type PlaybackRx from '.';
import NativeLivestream from '../native/livestream';

export default class RxPlayerLivestream extends NativeLivestream {
    lastDuration = 0;
    isLive = false;
    isLiveEnded = false;
    hasMultiplePeriod = false;
    dvrStart = 0;
    durationUpdateInterval = -1;

    declare playbackHandler: RxPlayer;
    declare playbackTech: PlaybackRx;

    constructor(playbackTech: PlaybackRx, playbackHandler: RxPlayer) {
        super(playbackTech, playbackHandler);

        this.durationUpdateInterval = window.setInterval(() => {
            const maxPos = this.playbackHandler.getMaximumPosition() || this.playbackHandler.getLivePosition();

            if (this.playbackTech.isLiveLinear) {
                this.dvrStart = this.playbackHandler.getMinimumPosition() ?? 0;
            }

            const currentDuration = this.playbackHandler.isLive() && maxPos
                ? maxPos - this.dvrStart
                : this.playbackHandler.getMediaDuration();

            if (currentDuration !== this.lastDuration) {
                this.lastDuration = currentDuration;
                this.videoElement?.dispatchEvent(new Event('durationchange'));
            }
        }, 3000);
    }

    override destroy(): void {
        super.destroy();

        this.videoElement?.removeEventListener(
            'timeupdate',
            this.#dispatchEndedEventForSsaiLiveEvent
        );

        this.lastDuration = 0;

        window.clearInterval(this.durationUpdateInterval);
    }

    override setupLiveListeners(): void {
        this.playbackHandler.addEventListener(
            'positionUpdate',
            this.computeLive
        );
    }

    override destroyLiveListeners(): void {
        this.playbackHandler.removeEventListener(
            'positionUpdate',
            this.computeLive
        );
    }

    #dispatchEndedEventForSsaiLiveEvent = (): void => {
        const TIME_DIFF_THRESHOLD = 0.5;

        const {currentTime, duration} = this.playbackTech;

        // After ssai live ended and video current time about to reach to edge
        // We are not get correct duration and the video will infinite loading
        // To solve the issue, we'll have to manually emit an ended event and exit player
        if (
            typeof duration === 'number' &&
            typeof currentTime === 'number' &&
            duration - currentTime < TIME_DIFF_THRESHOLD
        ) {
            this.playbackHandler.pause();
            this.videoElement?.dispatchEvent(new Event('ended'));

            // stop listening the event and pause the video immediately to prevent multiple ended event sent out.
            this.videoElement?.removeEventListener(
                'timeupdate',
                this.#dispatchEndedEventForSsaiLiveEvent
            );
        }
    };

    override tryComputeLive = (): void => {
        if (this.isLiveEnded) {
            return;
        }

        const isLive = this.playbackHandler.isLive();

        if (isLive && this.dvrStart === 0) {
            this.dvrStart = this.playbackHandler.getMinimumPosition() || 0;
        }

        // live ended
        if (this.isLive !== isLive && this.isLive) {
            this.isLiveEnded = true;

            this.videoElement?.addEventListener(
                'timeupdate',
                this.#dispatchEndedEventForSsaiLiveEvent
            );
        }

        this.isLive = isLive;

        this.triggerIsLive(isLive);
    };

    override setCurrentTimeToEdge(): void {
        const livePosition =
            this.playbackHandler.getMaximumPosition() ||
            this.playbackHandler.getLivePosition();

        if (livePosition) {
            this.playbackHandler.seekTo({
                position: livePosition,
            });
        }
    }
}
