import NativeAdsAdapter from "./ads/NativeAdsAdapter";

export default class TheoplayerAdapter {
  adsAdapters = {
    nativeAdsAdapter: NativeAdsAdapter
  };

  checkExistsPlayer() {
    try {
      if (this.player.element) {
        return this.checkExistsObjectOnPage(this.player.element);
      }
    } catch (err) {
      return true;
    }
  }

  getBitrate() {
    var ret = -1;
    var qty = this.getActiveQuality();
    if (qty && qty.bandwidth) {
      ret = qty.bandwidth;
    }
    return ret;
  }

  pauseListener(e) {
    this.firePlayerLog('pauseListener', {});
    if (!this.flags.isSeeking) {
      this.firePause({}, 'pauseListener');
    }
  }

  offlineListener(e) {
    this.firePlayerLog('offlineListener', {});
    this.fireError('OFFLINE_SOURCE', 'OFFLINE_SOURCE', undefined, undefined, 'offlineListener');
  }

  getRendition() {
    this._setLanguage();
    var ret = null;
    var qty = this.getActiveQuality();
    if (qty) {
      if (qty.name && isNaN(qty.name)) {
        ret = qty.name;
      } else {
        ret = this.getNpawUtils().buildRenditionString(qty.width, qty.height, qty.bandwidth);
      }
    } else if (this.player.videoHeight && this.player.videoWidth) {
      ret = this.getNpawUtils().buildRenditionString(this.player.videoWidth, this.player.videoHeight, 0);
    }
    return ret;
  }

  getLatency() {
    var ret = null;
    if (this.getIsLive() && this.player.currentProgramDateTime !== null) {
      var now = new Date().getTime();
      ret = now - this.player.currentProgramDateTime;
    }
    return ret;
  }

  getPlayerVersion() {
    var version = 'THEOplayer';
    if (typeof THEOplayer !== 'undefined') {
      version += THEOplayer.version;
    } else if (typeof theoplayer !== 'undefined') {
      version += theoplayer.version;
    }
    return version;
  }

  playListener(e) {
    this.firePlayerLog('playListener', {});
    if (!this.player.autoplay) {
      this.fireInit({}, 'playListener');
    }
  }

  endedListener(e) {
    this.firePlayerLog('endedListener', {});
    if (this.flags.isStarted) {
      this.fireStop({}, 'endedListener');
      this.ended = true;
    }
  }

  getPlayrate() {
    try {
      return this.player.playbackRate;
    } catch (err) { }
    return 0;
  }

  getActiveQuality() {
    var vTracks = this.player.videoTracks;
    for (var i = 0; i < vTracks.length; i++) {
      var currentTrack = vTracks.item(i);
      if (currentTrack.enabled) {
        return currentTrack.activeQuality;
      }
    }
    return {};
  }

  playrateListener(e) {
    if (this.monitor) {
      this.monitor.skipNextTick();
    }
  }

  getPlayhead() {
    var ret = null;
    try {
      if ((this.player.ads && this.player.ads.playing) || (this.flags.isSeeking && this.flags.isPaused)) {
        ret = this.lastPlayhead;
      } else {
        this.lastPlayhead = this.player.currentTime;
        ret = this.player.currentTime;
      }
    } catch (err) { }
    return ret;
  }

  getDuration() {
    var ret = null;
    if (this.player.ads && this.player.ads.playing) {
      ret = this.lastDuration || ret;
    } else {
      this.lastDuration = this.player.duration;
      ret = this.lastDuration;
    }
    return ret;
  }

  getThroughput() {
    if (this.player) {
      if (this.player.metrics && this.player.metrics.currentBandwidthEstimate) {
        return this.player.metrics.currentBandwidthEstimate;
      }
    }
    return null;
  }

  getDroppedFrames() {
    return this.player.metrics ? this.player.metrics.droppedVideoFrames : null;
  }

  getVersion() {
    return '7.0.14-theoplayer2-jsclass';
  }

  unregisterListeners() {
    if (this.getVideo()) {
      this.getVideo().removeAdsAdapter();
    }
    if (this.monitor) this.monitor.stop();
    if (this.player && this.references) {
      for (var key in this.references) {
        this.player.removeEventListener(key, this.references[key]);
      }
      delete this.references;
    }
    if (this.player && this.player.network && this.networkReferences) {
      this.player.network.removeRequestInterceptor(this.requestInterceptor);
      this.player.network.removeResponseInterceptor(this.responseInterceptor);
      for (var key2 in this.networkReferences) {
        this.player.network.removeEventListener(key2, this.networkReferences[key2]);
      }
      delete this.networkReferences;
      delete this.requestInterceptor;
      delete this.responseInterceptor;
    }
  }

  stateChangeListener(e) {
    if (e.readyState === 1 && ((this.player.src !== this.previousSrc) && (this.player.src !== undefined))) {
      this.fireInit({}, 'stateChangeListener');
      this.previousSrc = this.player.src;
    }
    if (e.currentTime === 0 && e.readyState === 0 && ((this.player.src !== this.previousSrc) && (this.player.src !== undefined))) {
      this.fireStop();
      this.previousSrc = this.player.src;
    }
  }

  _segmentUrl() {
    var ret = null;
    if (this.getVideo() && this.getVideo().options && !this.getVideo().options['parse.manifest'] && this.parsedUrl) {
      ret = {
        parsedResource: this.parsedUrl
      };
    }
    return ret;
  }

  getIsLive() {
    return isNaN(this.player.duration) ? null : this.player.duration === Infinity;
  }

  _setLanguage() {
    if (this.getVideo()) {
      var tracks = this.player.audioTracks;
      for (var track in tracks) {
        var t = tracks[track];
        if (t.activeQuality) {
          this.getVideo().options['content.language'] = t.language === 'und' ? t.name || t.label : t.language;
        }
      }
    }
  }

  registerListeners() {
    this.getNpawUtils().logAllEvents(this.player);
    this.monitorPlayhead(false, true);
    this.references = {
      play: this.playListener.bind(this),
      playing: this.playingListener.bind(this),
      pause: this.pauseListener.bind(this),
      seeking: this.seekingListener.bind(this),
      ended: this.endedListener.bind(this),
      error: this.errorListener.bind(this),
      readystatechange: this.stateChangeListener.bind(this),
      sourcechange: this.endedListener.bind(this),
      timeupdate: this.timeUpdateListener.bind(this),
      ratechange: this.playrateListener.bind(this),
      waiting: this.waitingListener.bind(this)
    };
    this.networkReferences = {
      offline: this.offlineListener.bind(this)
    };
    for (var key in this.references) {
      this.player.addEventListener(key, this.references[key]);
    }
    if (this.player.network) {
      for (var key2 in this.networkReferences) {
        this.player.network.addEventListener(key2, this.networkReferences[key2]);
      }
    }
    if (!this.getVideo().getAdsAdapter() && this.player.ads) {
      this.getAdapterClass('nativeAdsAdapter')._updatePlayer(this.player);
      this.plugin.setAdsAdapter(this.getAdapterClass('nativeAdsAdapter'), this.getVideo().getVideoKey());
    }
  }

  getResource() {
    if (this.player.src && !this.previousSrc) {
      this.previousSrc = this.player.src;
    }
    return this.player.src || null;
  }

  getURLToParse() {
    return this.parsedUrl;
  }

  seekingListener(e) {
    this.firePlayerLog('seekingListener', {});
    this.fireSeekBegin({}, false, 'seekingListener');
  }

  timeUpdateListener(e) {
    if (!this.player.ads || !this.player.ads.playing) {
      if (!this.flags.isStarted && e.currentTime && !this.ended) {
        if (this.getIsLive() || e.currentTime + 1 < this.getDuration()) {
          this.fireStart(this._segmentUrl(), 'timeUpdateListener');
          this.fireJoin(this._segmentUrl(), 'timeUpdateListener');
        }
      }
      if (!this.flags.isPaused) {
        this.fireSeekEnd({}, 'timeUpdateListener');
        this.fireBufferEnd({}, 'timeUpdateListener');
      }
    }
  }

  getPlayerName() {
    return 'THEOplayer';
  }

  playingListener(e) {
    this.firePlayerLog('playingListener', {});
    if (!this.getVideo().getAdsAdapter() || !this.getVideo().getAdsAdapter().flags.isStarted) {
      if (this.flags.isSeeking && !this.flags.isPaused) {
        this.fireSeekEnd({}, 'playingListener');
      }
      if (this.flags.isBuffering && !this.flags.isPaused) {
        this.fireBufferEnd({}, 'playingListener');
      }
      this.fireResume({}, 'playingListener');
      if (!this.player.ads || !this.player.ads.playing) {
        this.ended = false;
        this.fireStart(this._segmentUrl(), 'playingListener');
      }
      this.fireJoin({}, 'playingListener');
    }
  }

  waitingListener(e) {
    this.firePlayerLog('waitingListener', {});
    if (!this.flags.isPaused && !this.flags.isSeeking) {
      this.fireBufferBegin({}, false, 'waitingListener');
    }
  }

  errorListener(e) {
    this.firePlayerLog('errorListener', {});
    var eventErrorObjectCode, playerErrorObjectCode, playerErrorCode, eventErrorObjectMessage, playerErrorObjectMessage, playerErrorMessage, eventErrorObjectCause, playerErrorObjectCause, playerErrorCause;
    if (e.errorObject) {
      eventErrorObjectCode = e.errorObject.code;
      eventErrorObjectMessage = e.errorObject.message;
      eventErrorObjectCause = !!e.errorObject.cause && e.errorObject.cause.message;
    } else {
      eventErrorObjectMessage = e.error;
    }
    if (this.player && this.player.errorObject) {
      playerErrorObjectCode = this.player.errorObject.code;
      playerErrorObjectMessage = this.player.errorObject.message;
      playerErrorObjectCause = !!this.player.errorObject.cause && this.player.errorObject.cause.message;
    }
    if (this.player && this.player.error) {
      playerErrorCode = this.player.error.code;
      playerErrorMessage = this.player.error.message;
      playerErrorCause = this.player.error.cause;
    }
    var messages = [];
    if (eventErrorObjectMessage) {
      messages.push('event: ' + eventErrorObjectMessage);
    }
    if (playerErrorObjectMessage) {
      messages.push('player.errorObject: ' + playerErrorObjectMessage);
    }
    if (playerErrorMessage) {
      messages.push('player.error: ' + playerErrorMessage);
    }
    var causes = [];
    if (eventErrorObjectCause) {
      causes.push('event: ' + eventErrorObjectCause);
    }
    if (playerErrorObjectCause) {
      causes.push('player.errorObject: ' + playerErrorObjectCause);
    }
    if (playerErrorCause) {
      causes.push('player.error: ' + playerErrorCause);
    }
    var codes = [];
    if (eventErrorObjectCode) {
      codes.push('event: ' + eventErrorObjectCode);
    }
    if (playerErrorObjectCode) {
      codes.push('player.errorObject: ' + playerErrorObjectCode);
    }
    if (playerErrorCode) {
      codes.push('player.error: ' + playerErrorCode);
    }
    var code = eventErrorObjectCode || playerErrorObjectCode || playerErrorCode || 'GENERIC_ERROR';
    var msg = '[MESSAGES] ' + messages.join(' - ') + ' - [CAUSES] ' + causes.join(' - ') + ' - [CODES] ' + codes.join(' - ');
    this.fireFatalError(code, msg, undefined, undefined, 'errorListener');
  }
}
