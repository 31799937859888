import {
    isServer,
    isBrowser,
    isIos,
    isHisenseU6,
    isSafari,
} from '@fsa-streamotion/browser-utils';

import get from 'lodash/get';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import trimStart from 'lodash/trimStart';
import {parse} from 'querystring';

import MediaError from './media-error';
import {MANIFEST_RETRY_TIMEOUT_MS} from './playback/dash/constants';
import type {DashAudioTrack} from './playback/dash/types';
import PlaybackHandlerEmpty from './playback/empty';
import type {BufferRange, TextTracks} from './playback/types';
import {getBestPlaybackHandlerWithSource} from './playback/utils';
import type {
    AudioTrack,
    BaseBitrateInfo,
    BreakToRemove,
    ErrorDetail,
    ErrorHistory,
    PlayerQualityLevel,
    PlayerTechOptions,
    SourceConfig,
    SupportedDashPlaybackHandler,
    SupportedPlayerTech,
    SupportedPlayerTechInstance,
} from './types';
import {triggerCustomEvent} from './utils/browser';
import {ERROR_CODES} from './utils/error-codes';
import PlayerTechError, {VIDEO_FS_ERROR_TYPES} from './utils/player-tech-error';
import type {PlayerQuality} from './utils/quality-settings';
import stripQueryParams from './utils/strip-query-params';
import {ALL_VIDEO_EVENTS} from './utils/video-events';
import VIDEO_LIBRARY_URLS from './utils/video-library-urls';
import {version} from './version';

const GAIN_BROWSER_TRUST_LISTEN_ARGS = {passive: true, capture: true};

const DEFAULT_CORE_OPTIONS: PlayerTechOptions = {
    DEBUG_INFO: false,
    DEBUG_LIBS: false,
    DEBUG_EME: false,
    DEBUG_EVENTS: false,
    gainBrowserTrust: false,
    disableAutoVolume: false,
    autoPlay: false,
    enableClosedCaptions: false,
    enableUserPreferredQuality: true,
    muted: false,
    preload: 'none',
    volume: 1,
    startAt: -1,
    capLevelToPlayerSize: false,
    enableDashSeamlessPeriodSwitchRegardlessOfDrmCompatibility: false,
    requestVideoSourceCallback() {
        return Promise.resolve(false);
    },
};

class PlayerTech {
    /**
     * Has the current video source played at all yet
     */
    hasPlayed = false;

    /**
     * Is the video currently playing
     */
    playing = false;

    /**
     * Has the video ended
     */
    ended = false;

    /**
     * Are we currently in an error processing state
     */
    errorProcessing = false; // Says if we're in the middle of rebooting the player to restore playback.

    /**
     * Are we mid-error-recovery and currently remembering the original error
     */
    errorHeld = false;

    /**
     * The state object that defines us in error (MediaError | Null)
     *
     */
    errorState: MediaError | null = null;

    /**
     * Further error details captured, mostly used in EME at this stage.
     */
    errorStateDetail: ErrorDetail = null;

    /**
     * The minimum amount of time we should try an recover per manifest.
     * This is to allow for async tasks like MediaTailor ad personalisation to finish
     */
    totalRecoveryTimeoutMs = 9_000;

    /**
     * When we last attempted our error recovery procedure
     */
    timeSinceLastRecoveryMs = 0;

    /**
     * The minimum uninterrupted playback we want to reach before marking a stream as unplayable
     */
    minimumUninterruptedPlaybackMs = 30_000;

    /**
     * The number of reattempts allowed for the current manifest.
     * This should be calculated as `manifestRetryThreshold * manifestRequestTimeout > totalRecoveryTimeout`
     *
     * We want to give ourselves a couple attempts at a retry however since our totalRecoveryTimeout is so low
     * and we need to follow the above logic; round up + set a minimum value
     */
    manifestRetryThreshold = Math.max(
        Math.ceil(this.totalRecoveryTimeoutMs / MANIFEST_RETRY_TIMEOUT_MS),
        1
    );

    /**
     * The number of retry attempts for the current manifest
     */
    manifestRetryCount = 0;

    /**
     * An array of PlaybackHandler classes that PlayerTech should consider using
     * This is a handy way to disable certain playback handlers if needed
     * If set to an empty array, all known PlaybackHandlers will be available
     */
    playbackHandlers: SupportedPlayerTech[] = [];

    /**
     * The current source of the video we're playing, possibly a manifest URL.
     * A slightly normalised interface over the native videoElement.src that works around some browser quirks.
     */
    currentSource: string | null | undefined = null;

    /**
     * The source details that the player is currently working with (first argument of most recent setSources call that isn't blacklisted or null)
     */
    currentSourceDetails: SourceConfig[] | null = null;

    /**
     * A set of src urls that we have attempted playback with but resulted in a source error.
     * Adding them to this set will prevent us attempting playback on a source with this src until our blacklist is cleared
     * This is done when the player is destroyed, we have a fatal error or we setSources (while we are not attempting to auto-recover)
     */
    currentSrcBlacklist: Set<string> = new Set<string>();

    /**
     * A historical list of errors related to the current asset.
     * This history is to persist the isPlaying across recovery attempts and is reset when we have a new asset playing or the error is considered fresh
     */
    currentErrorHistory: ErrorHistory[] = [];

    /**
     * Instance of the playback handler looking after our current source, or null
     */
    currentPlaybackHandler:
        | SupportedPlayerTechInstance
        | PlaybackHandlerEmpty
        | null = null;

    /**
     * The base options the playerTech instance was initialised with
     */
    coreOptions: PlayerTechOptions = {startAt: 0}; // Options to hold for the whole player tech.

    /**
     * Options specific to the active source, which will take precedence over the coreOptions
     */
    sourceOptions: PlayerTechOptions = {startAt: 0}; // Options for hold specific to the current source.

    /**
     * Are we in the middle of gaining browser trust
     */
    processingGainBrowserTrust = false;

    /**
     * Already played ad breaks which need to be remove when user seek backword
     */
    _adBreaksNeedToRemove: BreakToRemove[] = [];

    /**
     * Has this playerTech instance been destroyed
     * Handy for knowing when to ignore future callbacks like un-cancellable promises
     */
    private _hasBeenDestroyed = false;

    /**
     * HTML video element to play the video
     */
    videoElement: HTMLVideoElement;

    currentSourceType = '';

    /**
     * PlayerTech
     *
     * @param videoElement - The video element the playerTech will be attached to
     * @param options - Core options for this playerTech instance
     */
    constructor(
        videoElement: HTMLVideoElement,
        options: PlayerTechOptions = {startAt: 0}
    ) {
        this.videoElement = videoElement;
        this.coreOptions = merge({}, DEFAULT_CORE_OPTIONS, options);

        // If we have `video-debug=true` as a query param, force DEBUG_INFO and DEBUG_EME to true
        // Can also do video-debug-events and video-debug-libs (off by default even when using video-debug). Very noisy.
        if (isBrowser()) {
            const queryParams = parse(trimStart(window.location.search, '?'));

            if (queryParams['video-debug'] === 'true') {
                this.coreOptions.DEBUG_INFO = true;
                this.coreOptions.DEBUG_EME = true;
            }

            if (queryParams['video-debug-info'] === 'true') {
                this.coreOptions.DEBUG_INFO = true;
            }

            if (queryParams['video-debug-libs'] === 'true') {
                this.coreOptions.DEBUG_LIBS = true;
            }

            if (queryParams['video-debug-eme'] === 'true') {
                this.coreOptions.DEBUG_EME = true;
            }

            if (queryParams['video-debug-events'] === 'true') {
                this.coreOptions.DEBUG_EVENTS = true;
            }
        }

        this.videoElement.addEventListener('play', this.onEventPlay, true);
        this.videoElement.addEventListener('playing', this.onEventPlaying);
        this.videoElement.addEventListener('pause', this.onEventPause);
        this.videoElement.addEventListener('ended', this.onEventEnded);
        this.videoElement.addEventListener('error', this.onEventError, true);

        this.currentPlaybackHandler = new PlaybackHandlerEmpty({
            videoElement: this.videoElement,
            src: '',
            options: {startAt: 0},
            onError: () => void 0,
        });

        setTimeout(() => {
            this.videoElement.dispatchEvent(new CustomEvent('fs-mounted'));
        });

        if (this.coreOptions.gainBrowserTrust) {
            this.gainBrowserTrust();
        }

        if (this.coreOptions.DEBUG_EVENTS) {
            ALL_VIDEO_EVENTS.forEach((eventName) => {
                this.videoElement.addEventListener(eventName, console.info); // eslint-disable-line no-console
            });
        }
    }

    /**
     * Reset the player instance, clearing the current playback handler and resetting some key properties
     * Part of the process of changing source
     *
     * @param holdErrorState - Should we avoid clearing the error state (e.g. if we're doing this as part of error recovery)
     */
    async resetPlayer(holdErrorState = false): Promise<void> {
        if (this.isPlaying) {
            this.pause();
        }

        if (this.currentPlaybackHandler) {
            await this.currentPlaybackHandler?.destroy();
            this.currentPlaybackHandler = null;
        }

        this.currentPlaybackHandler = new PlaybackHandlerEmpty({
            videoElement: this.videoElement,
            src: '',
            options: {startAt: 0},
            onError: () => void 0,
        });

        this.errorHeld = false;

        // Reset some safe video things, like we can't be playing, our poster and the playback rate.
        this.playing = false;
        this.poster = '';
        this.playbackRate = 1; // Make sure the next video is played at the normal rate.
        this.hasPlayed = false;
        this._adBreaksNeedToRemove = [];

        if (!holdErrorState) {
            this._resetErrorState();
            this.currentSource = null;

            // ONLY reset the video element if we clear out the errorState at the same time.
            // If this because using an empty source on a video element, throws an error.
            // Normally we ignore this, but only if we don't have an error state to propagate through.
            // When we throw our error for propagation, we're NOT expecting the video element to throw anymore errors,
            // which happens when .src = ''.
            try {
                if (!this.videoElement) {
                    return;
                }

                this.videoElement.currentTime = 0;
                this.videoElement.src = '';
                this.videoElement.load(); // cause any buffer/duration/currentTime to reset.
            } catch (e) {
                // This try/catch is ONLY for IE11.  DEATH TO IE-FUCKIN-11!
                // None of these things can be 'reset' there.
            }
        }
    }

    /**
     * Irrevocably destroy this playerTech instance, e.g. because the player has been closed
     * Performs all necessary tear-downs on playback handlers and the video element
     */
    destroy = async (): Promise<void> => {
        await this.resetPlayer();
        this.currentSrcBlacklist.clear();
        this.errorProcessing = false;

        this.videoElement.removeEventListener('play', this.onEventPlay, true);
        this.videoElement.removeEventListener('playing', this.onEventPlaying);
        this.videoElement.removeEventListener('pause', this.onEventPause);
        this.videoElement.removeEventListener('ended', this.onEventEnded);
        this.videoElement.removeEventListener('error', this.onEventError, true);

        if (this.coreOptions.DEBUG_EVENTS) {
            ALL_VIDEO_EVENTS.forEach((eventName) => {
                this.videoElement.removeEventListener(eventName, console.info); // eslint-disable-line no-console
            });
        }

        this._hasBeenDestroyed = true;
    };

    gainBrowserTrust(): void {
        if (isServer()) {
            return;
        }

        document.addEventListener(
            'click',
            this.handleGainBrowserTrust,
            GAIN_BROWSER_TRUST_LISTEN_ARGS
        );
    }

    handleGainBrowserTrust = (): void => {
        const currentSource = this.videoElement.src;

        document.removeEventListener(
            'click',
            this.handleGainBrowserTrust,
            GAIN_BROWSER_TRUST_LISTEN_ARGS
        );

        // Only do this is the video is paused (not autoplaying for example)
        // Video Elements start out paused, and our knowledge of play would be false.
        if (this.videoElement.paused && this.hasPlayed === false) {
            this.processingGainBrowserTrust = true;

            // @TODO Consider removing source before attempting playback,
            // as preload 'semi' happens automagically when you play/pause with a source.
            // Need to check that preload 100% is 'none' before removing source.
            if (isIos()) {
                // On ios, trust no longer seems to be given to video elements, unless
                // that video element has a source (even if it's invalid).
                this.videoElement.src =
                    'https://foxsportshls-vh.akamaihd.net/trust.m3u8';
                this.videoElement.play();
                this.videoElement.pause();
                this.videoElement.src = currentSource;
            } else {
                this.videoElement.play();
                this.videoElement.pause();
            }
        }
    };

    /**
     * Set the source of the video player.
     * @param  newSources - Sources to consider playing
     * @param  newOptions - Options for this new source
     * @param  preferredDashPlaybackHandler - indicate preference of dash playback handler
     */
    setSources = async ({
        isAttemptingToRecover = false,
        sources = [],
        options = {startAt: 0},
        preferredDashPlaybackHandler = 'dashjs',
    }: {
        isAttemptingToRecover?: boolean;
        sources?: SourceConfig | SourceConfig[];
        options?: PlayerTechOptions;
        preferredDashPlaybackHandler?: SupportedDashPlaybackHandler;
    }): Promise<void> => {
        try {
            const incomingSources = Array.isArray(sources)
                ? sources
                : [sources];

            // Expecting mutation. Overriding things that may have changed since initialization of player.
            this.sourceOptions = merge(
                {},
                this.coreOptions,
                options,

                // Preserve volume and muted
                {
                    volume: this.volume,
                    muted: this.muted,
                },
                {preferredDashPlaybackHandler}
            );

            await this.resetPlayer(false);

            if (!isAttemptingToRecover) {
                this.currentSrcBlacklist.clear();
                this.currentErrorHistory = [];
            }

            if (!incomingSources.length) {
                this.currentSourceDetails = null;
                this.currentSource = null;

                this.videoElement.dispatchEvent(new CustomEvent('fs-stopped'));

                return;
            }

            this.currentSourceDetails = incomingSources;

            // If we have blacklisted every stream we want to show our most recent error
            // Note that we might have changed our currentSourceDetails but persisted a blacklist.
            // Therefore we cannot just compare length
            const allSourcesBlacklisted = incomingSources.every(({src}) =>
                this.currentSrcBlacklist.has(stripQueryParams(src))
            );

            // If we have blacklisted all sources we want to throw a fatal error with our persisted error
            if (allSourcesBlacklisted) {
                this.errorState = new MediaError(
                    ERROR_CODES.CUSTOM_ERR_UNKNOWN,
                    'All available sources have been blacklisted'
                );
                this.errorStateDetail = new PlayerTechError({
                    type: VIDEO_FS_ERROR_TYPES.ALL_SOURCES_BLACKLISTED,
                    message: 'All available sources have been blacklisted',
                });

                throw VIDEO_FS_ERROR_TYPES.ALL_SOURCES_BLACKLISTED;
            }

            const bestPlaybackHandlerWithSource =
                await getBestPlaybackHandlerWithSource({
                    sources: incomingSources,
                    srcBlacklist: this.currentSrcBlacklist,
                    playbackHandlers: this.playbackHandlers.length
                        ? this.playbackHandlers
                        : undefined,
                    videoElement: this.videoElement,
                    preferredDashPlaybackHandler,
                });

            // No supported playback handlers were given.  We need to
            // define ourselves a media not supported error.
            if (
                !bestPlaybackHandlerWithSource ||
                !bestPlaybackHandlerWithSource.PlaybackHandler
            ) {
                // if we have blacklisted sources and don't support the remaining sources
                // show a blacklisted error instead of not supported error
                if (this.currentSrcBlacklist.size > 0) {
                    this.errorState = new MediaError(
                        ERROR_CODES.CUSTOM_ERR_UNKNOWN,
                        'All available sources have been blacklisted'
                    );
                    this.errorStateDetail = new PlayerTechError({
                        type: VIDEO_FS_ERROR_TYPES.ALL_SOURCES_BLACKLISTED,
                        message: 'All available sources have been blacklisted',
                    });

                    throw VIDEO_FS_ERROR_TYPES.ALL_SOURCES_BLACKLISTED;
                }

                console.error('PlayerTech: Stream error, media not Supported.');

                // Populate our error state with
                this.errorState = new MediaError(
                    ERROR_CODES.MEDIA_ERR_SRC_NOT_SUPPORTED,
                    'Unable to find PlaybackHandler for given sources'
                );
                this.errorStateDetail = new PlayerTechError({
                    type: VIDEO_FS_ERROR_TYPES.NO_PLAYBACK_HANDLER,
                    message: 'Unable to find PlaybackHandler for given sources',
                });

                throw VIDEO_FS_ERROR_TYPES.NO_PLAYBACK_HANDLER;
            }

            const {
                src,
                withCredentials,
                PlaybackHandler,
                type,
                availableKeySystems,
                keySystems,
                cdnProvider,
                hasSsai,
            } = bestPlaybackHandlerWithSource;

            if (type) {
                this.currentSourceType = type;
            }

            // Keep a record of what our in src(s) were for fatal error retries and things.
            this.currentSource = src;

            // Catch places where we might have an invalid startAt time (lower than 0 (not -1))
            // In the cases we've been given something that's not a number, (or lower than 0)
            // make sure we correct as required.
            const safelyAdjustedStartAt = Number.parseFloat(
                this.sourceOptions.startAt.toString()
            );

            if (!Number.isFinite(safelyAdjustedStartAt)) {
                console.warn(
                    `PlayerTech: startAt "${this.sourceOptions.startAt}" given not finite. Reset to -1.`
                );
                this.sourceOptions.startAt = -1;
            } else if (
                safelyAdjustedStartAt < 0 &&
                safelyAdjustedStartAt !== -1
            ) {
                console.warn(
                    `PlayerTech: startAt "${this.sourceOptions.startAt}" given less than 0. Reset to 0.`
                );
                this.sourceOptions.startAt = 0;
            } else {
                this.sourceOptions.startAt = safelyAdjustedStartAt;
            }

            // Set our current source, create the new handler for playback, lets get it going \o/
            this.currentPlaybackHandler = new PlaybackHandler({
                videoElement: this.videoElement,
                src: this.currentSource,
                srcType: this.currentSourceType,
                options: {...this.sourceOptions, withCredentials},
                onError: this.onError,
                availableKeySystems,
                keySystems,
                cdnProvider,
                hasSsai,
                isAttemptingToRecover,
                isLiveLinear: options?.isLiveLinear,
            });

            await this.currentPlaybackHandler?.setup();

            this.videoElement.dispatchEvent(
                new CustomEvent('fs-source-updated')
            );

            // Check if we need to preload metadata
            switch (this.sourceOptions.preload) {
                case 'none': // indicates that the video should not be preloaded.
                    break;

                case 'metadata': // indicates that only video metadata (e.g. length) is fetched.
                    this.currentPlaybackHandler?.preloadMetadata();
                    break;

                case '': // the empty string: synonym of the auto value
                case 'auto': // indicates that the whole video file could be downloaded, even if the user is not expected to use it.
                default:
                    this.currentPlaybackHandler?.preloadAuto();
                    break;
            }

            if (this.sourceOptions.autoPlay) {
                this.play();
            }
        } catch (error) {
            console.error('Error in setSources', get(error, 'stack', error)); // give us the proper stack on error, or just a message if we have to.

            // Re-throw it, so it can be observed by whoever is running setSources()
            // not sure if the error is string type, hopefully it works.
            throw new Error(error as string);
        }
    };

    /**
     * Adds the current source src to the src blacklist to prevent its use during the next playback attempt
     * restarts the player to reselect the best source
     * if we exhaust our list of sources, show the last error that was stored in PlayerTech
     */
    blacklistCurrentSource = async ({
        startAt,
        autoPlay,
    }: {
        startAt: number;
        autoPlay: boolean;
    }): Promise<void> => {
        if (
            !this.currentSourceDetails ||
            this.currentSourceDetails.length === 0
        ) {
            // if we don't have any sources, we cant blacklist anything so just return here
            return;
        }

        // add the current src to the blacklist
        const currentSource = this.currentSourceDetails.find(
            (source) => source.src === this.currentSource
        );

        if (!currentSource) {
            return;
        }

        this.currentSrcBlacklist.add(stripQueryParams(currentSource.src));

        console.warn(
            `PlayerTech: Added ${currentSource?.src} to the blacklist`
        );

        return this.setSources({
            isAttemptingToRecover: true,
            sources: this.currentSourceDetails || [],
            options: {
                ...this.sourceOptions,
                startAt,
                autoPlay: false, // Don't auto play here, we want to ensure out source is working before starting playback
            },
            preferredDashPlaybackHandler:
                this.sourceOptions.preferredDashPlaybackHandler,
        })
            .then(() => {
                if (this._hasBeenDestroyed) {
                    // If we've been destroyed while waiting for the async events above, bail out now

                    return;
                }

                // eslint-disable-next-line no-console
                console.info(
                    'PlayerTech: Source error - Playback resumed on next available source'
                );

                triggerCustomEvent(this.videoElement, 'fs-source-blacklisted');

                // Attempt to resume playback if the error happened while we were playing
                // ...or for any DRM error, since they usually come up as part of the setup process
                if (autoPlay) {
                    // eslint-disable-next-line no-console
                    console.info(
                        'PlayerTech: Source Error - Attempting to resume playback after source added to blacklist.'
                    );
                    setTimeout(() => {
                        if (this._hasBeenDestroyed) {
                            // If we've been destroyed while waiting for the async events above, bail out now

                            return;
                        }

                        this.play();

                        setTimeout(() => {
                            // we have recovered successfully, we have finished processing the error so reset our retry count
                            this.errorProcessing = false;
                            this._resetErrorState();
                            this.manifestRetryCount = 0;
                        });
                    });
                } else {
                    this.errorProcessing = false;
                    this._resetErrorState();
                }
            })
            .catch(async (e: string) => {
                if (this._hasBeenDestroyed) {
                    // If we've been destroyed while waiting for the async events above, bail out now
                    return;
                }

                // Since something went wrong in setSources or play, let log this out to help the dev
                console.error('Error thrown while attempting to setSources', {
                    error: e,
                    errorState: this.errorState,
                    errorStateDetail: this.errorStateDetail,
                });

                // stop attempting playback and let the consumer know we failed
                return await this._throwFatalError();
            });
    };

    /**
     * The current diagnostics controller instance. Contains diagnostic information about the playback session.
     */
    get diagnostics():
        | SupportedPlayerTechInstance['controllerDiagnostics']
        | null
        | undefined {
        return this.currentPlaybackHandler?.controllerDiagnostics;
    }

    /**
     * The current source of the video we're playing, possibly a manifest URL.
     * A slightly normalised interface over the native videoElement.src that works around some browser quirks.
     */
    get src(): string | null | undefined {
        return this.currentSource;
    }

    /**
     * @deprecated - Use playerTech.setSources instead
     */
    set src(newSource) {
        throw `Do not use src = for setting a source ${newSource}. See setSources as types and options are required.`;
    }

    onEventPlay = (e: Event): void => {
        if (this.processingGainBrowserTrust) {
            // Only hold this error back if it's not autoplay.
            // For auto play events, we want this to go on as expected.
            if (this.sourceOptions.autoPlay === false) {
                e.stopPropagation(); // stops propagation for bubbles.
                e.stopImmediatePropagation(); // stops any other event listening to this exact event. It's a fake play.
            }

            this.processingGainBrowserTrust = false;

            return;
        }

        if (this.errorProcessing) {
            e.stopPropagation();
            e.stopImmediatePropagation();
        }

        this.hasPlayed = true;
        this.playing = true;
        this.ended = false;
    };

    onEventPlaying = (): void => {
        // When playing event is triggered, the paused value should be false.
        // Hisense U6 has an issue to emitting playing event even if the video element keeps paused status.
        // To solve this issue, we don't update the playing value when abnormal playing event is emitted.
        if (this.videoElement.paused && isHisenseU6()) {
            return;
        }

        this.hasPlayed = true;
        this.playing = true;
        this.ended = false;
    };

    onEventPause = (): void => {
        this.playing = false;
    };

    onEventEnded = (): void => {
        this.playing = false;
        this.ended = true;
    };

    onEventError = (event: ErrorEvent): void => {
        // We're specifically here to hold back errors that are really not
        // errors according to us. A blank source is one such example.
        const errorCode = this.videoElement?.error?.code || 0;
        const errorMessage = this.videoElement?.error?.message || '';

        if (
            // Before considering about errorCode and errorMessage around missing
            // poster images and the like, ensure we don't have a held error state
            // that we need to address right now.
            !this.errorState &&
            !this._isLegitimateError(this.videoElement?.error)
        ) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        } else if (!this.errorState) {
            // If we currently don't hold an error state, send this
            // error through our fancy error handler.
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();

            this.onError(errorCode, errorMessage);
        }
    };

    /**
     * In some cases (e.g. dashjs) the videoElement doesn't have a src set right away,
     * but this isn't necessarily a real problem. Despite this, the videoElement tries to be helpful
     * by raising a MEDIA_ERR_SRC_NOT_SUPPORTED error, but we need to know when we can ignore this as "illegitimate".
     * There are also some other "false alarm" errors that can arise. We call each of these cases out in the comments below.
     *
     * @param error - the error object attached to the videoElement
     * @returns - is this error one we should pay attention to
     */
    private _isLegitimateError(error: MediaError | null): boolean {
        if (!error) {
            return false;
        }

        const {message = '', code = null} = pick(error, ['message', 'code']);

        if (code === ERROR_CODES.MEDIA_ERR_SRC_NOT_SUPPORTED) {
            const sourceIsKnownToBeEmpty =
                // We know we don't have a current source
                !this.currentSource ||
                // The video element has no src itself (or its src is reported the same as the page url, which also means it's effectively blank)
                this.videoElement.src === '' ||
                this.videoElement.src === window.location.href ||
                this.videoElement.src === window.location.href.split('#')[0]; // on some devices, the route is stripped from the dummy src value

            if (sourceIsKnownToBeEmpty) {
                return false;
            }

            const errorMessageDenotesIntentionallyEmptySource =
                // Chrome 'MEDIA_ELEMENT_ERROR: Empty src attribute'
                message.includes('Empty src') ||
                // Firefox 'NS_ERROR_DOM_INVALID_STATE_ERR (0x8053000b) - MediaLoadInvalidURI'
                message.includes('NS_ERROR_DOM_INVALID_STATE_ERR') ||
                // Edge and chrome sometimes just give an empty message.
                // Safari gives an empty message if it cant load the manifest so exclude safari from this check
                // (Safari doesn't care about missing sources)
                (!isSafari() && message === '');

            return !errorMessageDenotesIntentionallyEmptySource;
        } else {
            // If the video element triggered an error but we have no information to report on, let's consider that "illegitimate"
            // For example, Chrome does this for 404 on the video element's poster
            return code !== null;
        }
    }

    /**
     * When an error is thrown on the video element this function will determine if we attempt to recover playback
     * by following the auto-recovery flow. Due to some limitations with the error data we have access to we don't actually
     * know what network HTTP statusCode caused the current error so its a best effort to align with auto-recovery requirements.
     *
     * @param code - The current media error code (Not a HTTP statusCode)
     * @returns If the current error is blacklisted from attempting auto-recovery
     */
    private _isBlacklistedError(code: number | null): boolean {
        // If code is null we don't know what the error is so don't consider it blacklisted
        if (code === null) {
            return false;
        }

        // When navigator.onLine returns false, you can assume the browser is offline.
        // However, a true value does not necessarily mean the browser has internet access.
        // Since we only have access to the media error code, we can't identify the specific HTTP status code that triggered the error.
        // We'll make a best-effort attempt by allowing network errors to pass through,
        // but if the browser is offline, we want to prevent auto-recovery.
        if (
            code === ERROR_CODES.MEDIA_ERR_NETWORK &&
            navigator.onLine === false
        ) {
            return true;
        }

        // if the source isn't supported, we don't want to reattempt so consider it blacklisted
        // this shouldn't happen since we should only get getting sources that we support
        if (code === ERROR_CODES.MEDIA_ERR_SRC_NOT_SUPPORTED) {
            return true;
        }

        // Otherwise, it's not blacklisted
        return false;
    }

    /**
     * Given a playback error that should prevent playback from continuing:
     * - emits a fs-fatal-error event and error event on the video element
     * - resets the player (including the src blacklist)
     */
    private async _throwFatalError(): Promise<void> {
        // broadcast with our current errorState
        triggerCustomEvent(this.videoElement, 'fs-fatal-error', {
            error: this.currentErrorHistory[0]?.errorState || this.errorState,
        });

        // Since we are currently throwing a fatal error, consider error processing as finished
        this.errorProcessing = false;

        // Broadcast that we have an actual error to anyone listening
        // they can read errorState + errorStateDetail from playerTech if they want more info
        // if we have blacklisted all streams we need to set our errorState + errorStateDetail to be something of value to the end user
        // otherwise just leave it as our blacklisted error which will show up as "Unknown Video Error"
        if (
            this.errorStateDetail instanceof PlayerTechError &&
            this.errorStateDetail?.type ===
                VIDEO_FS_ERROR_TYPES.ALL_SOURCES_BLACKLISTED
        ) {
            this.errorState =
                this.currentErrorHistory[0]?.errorState ?? this.errorState;
            this.errorStateDetail =
                this.currentErrorHistory[0]?.errorDetail ?? this.errorDetail;
        }

        this.videoElement.dispatchEvent(new Event('error'));

        // hold error state but reset everything else about the player
        await this.resetPlayer(true);
        this.currentSrcBlacklist.clear();
    }

    /**
     * Cleans up internal playerTech error state
     */
    private _resetErrorState(): void {
        this.errorState = null;
        this.errorStateDetail = null;
    }

    /**
     * The current error object tracked by player tech
     *
     * @see [./utils/error-codes.js](src/js/player-tech/utils/error-codes.js)
     * @see [Native MediaError Documentation](https://developer.mozilla.org/en-US/docs/Web/API/MediaError)
     */
    get error(): MediaError | null {
        // Prefer our own tracked error
        if (this.errorState) {
            return this.errorState;
        }

        const videoElementError = this.videoElement.error;

        // If the video element has a valid error, return it
        if (videoElementError && this._isLegitimateError(videoElementError)) {
            return videoElementError;
        }

        // Otherwise, return null for consistency
        return null;
    }

    /**
     * Returns an Error Object if captured, that can be thrown exceptions that result in native Error
     * or a created PlayerTechError during times we know a little more about an error (See EME, rejected license requests)
     */
    get errorDetail(): ErrorDetail {
        return this.errorStateDetail;
    }

    onError = async (
        code: number,
        message: string,
        errorDetail: ErrorDetail = null
    ): Promise<void> => {
        if (this._hasBeenDestroyed || this.errorProcessing) {
            // Ignore latent error events if playerTech has already been destroyed or we are currently dealing with an error

            return;
        }

        /** ---------------------
         *      AUTO RECOVERY
         *  ---------------------
         *
         *  Auto recovery implements the following error cases
         *  - Fatal error:  Our error is blacklisted from retrying or we have exhausted our list of streams,
         *                  we want to stop video playback (if its playing) and let the user know via an error card.
         *  - Source error: Our current source is added to a blacklist and we need to attempt
         *                  to use the next source in our list. This can eventually be re-thrown as a Fatal error
         *                  if we have exhausted our source array.
         */

        this.errorHeld = false; // No longer holding errors back from play()
        this.errorProcessing = true; // Stops propagating of some events like play and stuff. @TODO Consider adding to seeking/seeked

        // Save values so we can resume playback on retry
        const now = performance.now();
        const currentTimeAtError = this.currentTime;
        const wasPlayingAtError = this.isPlaying;
        const wasTryingToPlayFromStartAt =
            (currentTimeAtError === this.sourceOptions.startAt &&
                !wasPlayingAtError) ||
            false;
        // NOTE: RxPlayer reports currentTime as 0 when loading live edge, DashJS reports current time as -1 when loading live edge
        const wasTryingToPlayLiveEdge =
            ((currentTimeAtError === 0 || currentTimeAtError === -1) &&
                this.sourceOptions.startAt === -1 &&
                !wasPlayingAtError) ||
            false;
        const isDrmError = code === ERROR_CODES.MEDIA_ERR_ENCRYPTED;
        const isFreshError =
            !this.timeSinceLastRecoveryMs ||
            now - this.timeSinceLastRecoveryMs >
                this.minimumUninterruptedPlaybackMs;
        const isNetworkError = code === ERROR_CODES.MEDIA_ERR_NETWORK;

        // populate our playerTech error objects with the current error
        this.errorState = new MediaError(code, message);
        this.errorStateDetail = errorDetail;

        // if we have a fresh error we want to clear the error history so that the first error in this list is the original error
        if (isFreshError) {
            this.currentErrorHistory = [];
        }

        // persist the current error state in case we need to reference it later (eg triggering auto-play on recovery)
        this.currentErrorHistory.push({
            errorState: this.errorState,
            errorDetail: this.errorDetail,
            wasPlayingAtError,
            currentTimeAtError,
            wasTryingToPlayLiveEdge,
            wasTryingToPlayFromStartAt,
        });

        // Error has come through potentially as cause of preload.
        // However, if we fail to get the manifest due to a network problem, we should consider this a proper issue
        if (
            this.hasPlayed === false &&
            !this.sourceOptions.autoPlay &&
            !isNetworkError
        ) {
            console.error(
                'PlayerTech: Error before play requested. Holding error recovery until play request.'
            );
            // return early because we don't want to attempt to auto-recover an asset that hasn't attempted playback
            // marking this as held means that when we try play we will re-invoke the onError handler with the persisted error
            this.errorHeld = true;

            return;
        }

        if (this.errorProcessing && isDrmError) {
            console.warn(
                'PlayerTech: Encountered DRM error while already trying error recovery. Ignoring and hoping for the best.',
                {code, message, errorDetail}
            );

            // DRM errors can bubble up from multiple sources at once. Only try to recover from one at a time.
            return;
        }

        // if error is blacklisted from retrying, throw a fatal error
        if (this._isBlacklistedError(code)) {
            console.warn(
                'PlayerTech: Fatal error - The current source has encountered an error that is blacklisted. Throwing fatal error'
            );

            // broadcast our error to the consumer
            await this._throwFatalError();

            return;
        }

        // if we have another error too soon, blacklist this stream
        if (!isFreshError) {
            console.warn(
                'PlayerTech: Source error - The current stream has errored too frequently. Trying another stream'
            );

            await this.blacklistCurrentSource({
                startAt: wasTryingToPlayLiveEdge
                    ? -1
                    : this.currentErrorHistory[0]?.currentTimeAtError ||
                      currentTimeAtError,
                autoPlay:
                    isDrmError ||
                    this.currentErrorHistory[0]?.wasPlayingAtError || // use isPlaying state from initial error from this sequence
                    this.currentErrorHistory[0]?.wasTryingToPlayLiveEdge || // If we were trying to play at live edge and failed, we want to continue trying to play at the live edge
                    this.currentErrorHistory[0]?.wasTryingToPlayFromStartAt ||
                    wasPlayingAtError,
            });

            return;
        }

        // If we have tried this manifest too many times, blacklist the current source
        if (this.manifestRetryCount > this.manifestRetryThreshold) {
            console.warn(
                'PlayerTech: Source error - The current source has exhausted the retry threshold. Trying another source'
            );

            // NOTE: wasTryingToPlayLive is here to persist our play on edge request if there is an error before the stream starts
            // otherwise after recovery playback will be from the start which will be jarring to the user
            await this.blacklistCurrentSource({
                startAt: wasTryingToPlayLiveEdge
                    ? -1
                    : this.currentErrorHistory[0]?.currentTimeAtError ||
                      currentTimeAtError,
                autoPlay:
                    isDrmError ||
                    this.currentErrorHistory[0]?.wasPlayingAtError || // use isPlaying state from initial error from this sequence
                    this.currentErrorHistory[0]?.wasTryingToPlayLiveEdge || // If we were trying to play at live edge and failed, we want to continue trying to play at the live edge
                    this.currentErrorHistory[0]?.wasTryingToPlayFromStartAt ||
                    wasPlayingAtError,
            });

            return;
        }

        if (this.currentPlaybackHandler) {
            // Saving some diagnostics information if the error details are present.
            if (errorDetail) {
                errorDetail.diagnostics = {
                    activeKeySystemName:
                        this.currentPlaybackHandler.activeKeySystemName,
                    container: this.diagnostics?.container,
                    playbackHandlerName: this.diagnostics?.playbackHandlerName,
                };
            }

            await this.currentPlaybackHandler.destroy();
            this.currentPlaybackHandler = null;
        }

        // We don't want to 'reset' at this stage, we just want to ensure any attempt to
        // seek or further events past the error are simply discarded.
        this.currentPlaybackHandler = new PlaybackHandlerEmpty({
            videoElement: this.videoElement,
            src: '',
            options: {startAt: 0},
            onError: () => void 0,
        });

        this.playing = false; // We won't be playing anymore after reset or death.

        // @TODO, consider putting the original POSTER back onto the video element after reset.

        // Since this is our first error, or first error in a while, reboot all the things.
        // and chuck a warning in the console for the devs :)
        console.warn(
            'PlayerTech: Source error - rebooting video playbackHandler'
        );

        triggerCustomEvent(this.videoElement, 'fs-source-error-retry', {
            error: this.errorState,
        });

        // since we are trying to recover the current stream, note down the time of error to reference if we have another error
        this.timeSinceLastRecoveryMs = now;
        this.manifestRetryCount += 1;

        if (isDrmError) {
            // It's a DRM error. Arbitrarily wait a couple of seconds before attempting recovery
            // Some CDMs need a bit of time to run in the background
            await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        if (this._hasBeenDestroyed) {
            // If we've been destroyed while waiting for the async events above, bail out now

            return;
        }

        // reattempt playback for the current source
        this.sourceOptions
            // re-get our sources from playAPI in case there was a problem with the initial response
            ?.requestVideoSourceCallback?.()
            .then((sourceDetails) => {
                if (this._hasBeenDestroyed) {
                    // If we've been destroyed while waiting for the async events above, bail out now

                    return;
                }

                // If we were a live asset, the rolling DVR window would have changed slightly
                // calculate how long it took us to get here since the error and
                // set currentTime to account for this time. Also don't forget to convert to seconds
                // this will generally be very small and not very accurate since
                // it might take some time for our asset to actually begin playback

                return this.setSources({
                    isAttemptingToRecover: true,
                    sources: sourceDetails || this.currentSourceDetails || [],
                    options: {
                        ...this.sourceOptions,
                        startAt: wasTryingToPlayLiveEdge
                            ? -1
                            : this.currentErrorHistory[0]?.currentTimeAtError ||
                              currentTimeAtError,
                        autoPlay: false, // We will manage play from the wasPlayingAtError after reload.
                    },
                    preferredDashPlaybackHandler:
                        this.sourceOptions.preferredDashPlaybackHandler,
                });
            })
            .then(() => {
                if (this._hasBeenDestroyed) {
                    // If we've been destroyed while waiting for the async events above, bail out now

                    return;
                }

                // eslint-disable-next-line no-console
                console.info(
                    'PlayerTech: Source error - Recovered with reset source. Original error: ',
                    {code, message, errorDetail}
                );

                triggerCustomEvent(
                    this.videoElement,
                    'fs-source-error-recovered'
                );

                // Attempt to resume playback if the error happened while we were playing
                // If we haven't played the video yet and already got an error this usually means we had issues getting the manifest.
                // We want to try play in this scenario so that we will get another error instead of just waiting for the user to initiate playback
                // Attempt to play so that we can trigger our error handler
                // ...or for any DRM error, since they usually come up as part of the setup process
                // if we error out before this play call triggers our isPlaying flag we might have an error that says we were not playing
                // because of this we should prioritise our currentErrorHistory over the current isPlaying state of playerTech
                if (
                    this.currentErrorHistory[0]?.wasPlayingAtError ||
                    wasPlayingAtError ||
                    isDrmError ||
                    this.hasPlayed === false
                ) {
                    // eslint-disable-next-line no-console
                    console.info(
                        'PlayerTech: Source Error - Attempting to resume playback after source error.'
                    );
                    setTimeout(() => {
                        if (this._hasBeenDestroyed) {
                            // If we've been destroyed while waiting for the async events above, bail out now

                            return;
                        }

                        this.play();

                        setTimeout(() => {
                            // we have recovered successfully, we have finished processing the error so reset our retry count
                            this.errorProcessing = false;
                            this._resetErrorState();
                            this.manifestRetryCount = 0;
                        });
                    });
                } else {
                    this.errorProcessing = false;
                    this._resetErrorState();
                }
            })
            .catch(async (e) => {
                if (this._hasBeenDestroyed) {
                    // If we've been destroyed while waiting for the async events above, bail out now
                    return;
                }

                // Since something went wrong in setSources or play, let log this out to help the dev
                console.error('Error thrown while attempting to setSources', {
                    error: e,
                    errorState: this.errorState,
                    errorStateDetail: this.errorStateDetail,
                });

                // broadcast our error to the consumer
                return await this._throwFatalError();
            });
    };

    /**
     * Play the current video, or raise an Error if there is a problem
     */
    play = (): void => {
        // Attempt at playback has occurred, even if we never get to 'playing'
        this.hasPlayed = true;

        if (this.errorHeld) {
            // We're in error (probably held), so we can't actually play. Continue the previous error handling.
            const {code, message} = this.errorState || {};
            const errorDetail = this.errorStateDetail;

            this.onError(code ?? 0, message ?? '', errorDetail);

            return;
        }

        if (!this.isPlaying) {
            this.currentPlaybackHandler?.play();
        }
    };

    /**
     * Pause the current video
     */
    pause = (): void => {
        if (this.isPlaying) {
            this.currentPlaybackHandler?.pause();
        }
    };

    /**
     * Toggle video playback (pause if we're playing and vice versa)
     *
     */
    togglePlayPause = (): void => {
        // for multiple period stream, when switching period, duration will change to NaN or 0
        // if toggle play state at this time, will accidentally restart the video
        if (
            this.duration &&
            (isNaN(this.videoElement.duration) ||
                this.videoElement.duration === 0)
        ) {
            return;
        }

        if (this.isPlaying) {
            this.pause();
        } else {
            this.play();
        }
    };

    /**
     * Is the video currently playing
     */
    get isPlaying(): boolean {
        const currentPlaybackHandlerIsPlaying =
            this.currentPlaybackHandler?.isPlaying;

        if (currentPlaybackHandlerIsPlaying !== undefined) {
            return currentPlaybackHandlerIsPlaying;
        }

        return this.playing;
    }

    /**
     * The duration of the current video in seconds
     */
    get duration(): number | undefined {
        return this.currentPlaybackHandler?.duration;
    }

    /**
     * The current time of this video in seconds
     */
    get currentTime(): number {
        return this.currentPlaybackHandler?.currentTime ?? 0;
    }

    set currentTime(newCurrentTime) {
        triggerCustomEvent(this.videoElement, 'fs-seek-requested', {
            currentTime: this.currentTime,
        });

        /**
         * check if user seeked backward and there are any previously watched ad breaks and playback handler is not dashjs
         * If all conditions are met then remove the already watched ad breaks
         */
        if (
            this._adBreaksNeedToRemove.length &&
            newCurrentTime < this.currentTime &&
            this.currentPlaybackHandler?.controllerDiagnostics
                ?.playbackHandlerType !== 'dash'
        ) {
            this.currentPlaybackHandler?.removeBreaks(
                this._adBreaksNeedToRemove,
                newCurrentTime
            );
            this._adBreaksNeedToRemove = [];
        } else if (this.currentPlaybackHandler) {
            this.currentPlaybackHandler.currentTime = newCurrentTime;
        }
    }

    /**
     * The video volume. A number between 0 and 1 where 1 is "full volume"
     */
    get volume(): number {
        return this.currentPlaybackHandler?.volume ?? 0;
    }

    set volume(newVolume) {
        if (this.currentPlaybackHandler) {
            this.currentPlaybackHandler.volume = newVolume;
        }
    }

    /**
     * Is the video muted
     */
    get muted(): boolean {
        return !!this.currentPlaybackHandler?.muted;
    }

    set muted(shouldMute) {
        if (this.currentPlaybackHandler) {
            this.currentPlaybackHandler.muted = shouldMute;
        }
    }

    /**
     * If true, we will avoid remembering volume and mute settings for the next session
     */
    get disableAutoVolume(): boolean {
        return !!this.currentPlaybackHandler?.disableAutoVolume;
    }

    set disableAutoVolume(value) {
        if (this.currentPlaybackHandler) {
            this.currentPlaybackHandler.disableAutoVolume = value;
        }
    }

    /**
     * Toggle between muted and un-muted
     */
    toggleMute = (): void => {
        this.currentPlaybackHandler?.toggleMute();
    };

    /**
     * Save the current volume settings for next time
     */
    saveVolume(): void {
        this.currentPlaybackHandler?.saveVolume();
    }

    /**
     * The src url of the video element poster
     */
    get poster(): string | null {
        return this.currentPlaybackHandler?.poster ?? null;
    }

    set poster(newPoster) {
        if (this.currentPlaybackHandler) {
            this.currentPlaybackHandler.poster = newPoster;
        }
    }

    /**
     * The current playback rate. 1 is normal, less than 1 is slow-mo, greater than 1 is faster speed. Can't be below 0.
     *
     */
    get playbackRate(): number {
        return this.currentPlaybackHandler?.playbackRate || 1;
    }

    set playbackRate(newRate) {
        if (this.currentPlaybackHandler) {
            this.currentPlaybackHandler.playbackRate = newRate;
        }
    }

    /**
     * @param fullscreenElement - The element that we might have made fullscreen
     * @returns  Is `fullscreenElement` in full screen mode?
     */
    checkElementIsFullscreen(fullscreenElement: Element): boolean {
        return !!this.currentPlaybackHandler?.checkElementIsFullscreen(
            fullscreenElement
        );
    }

    /**
     * Put fullscreenElement in native fullscreen mode
     * @param fullscreenElement - The element that should go full screen. Defaults to the videoElement.
     */
    enterFullscreen = (fullscreenElement?: Element | null): void => {
        this.currentPlaybackHandler?.enterFullscreen(
            fullscreenElement ?? undefined
        );
    };

    /**
     * Exit fullscreen mode
     */
    exitFullscreen = (): void => {
        this.currentPlaybackHandler?.exitFullscreen();
    };

    /**
     * Toggle fullscreenElement in and out of native fullscreen mode.
     * @param fullscreenElement - The element that should go full screen/should leave fullscreen. Defaults to the videoElement.
     */
    toggleFullscreen = (fullscreenElement: Element): void => {
        this.currentPlaybackHandler?.toggleFullscreen(fullscreenElement);
    };

    /**
     * For live streams, scrub forward to a point near the edge of live
     * This will take us just behind the latest known position, lest the user immediately needs to enter a buffering state
     */
    setCurrentTimeToEdge = (): void => {
        triggerCustomEvent(this.videoElement, 'fs-seek-requested', {
            currentTime: this.currentTime,
        });
        this.currentPlaybackHandler?.setCurrentTimeToEdge();
    };

    /**
     * The buffered time ranges of the current video
     * This may return a TimeRanges pseudo-type
     */
    get bufferedTimeRanges(): BufferRange[] {
        return this.currentPlaybackHandler?.bufferedTimeRanges ?? [];
    }

    /**
     * Available bitrate levels for this video
     */
    get bitrateLevels(): BaseBitrateInfo[] {
        return this.currentPlaybackHandler?.bitrateLevels ?? [];
    }

    /**
     * Index of bitrate currently in use
     */
    get bitrateCurrentIndex(): number {
        return this.currentPlaybackHandler?.bitrateCurrentIndex ?? -1;
    }

    /**
     * Index of bitrate we're trying to switch to (might take some time before it becomes bitrateCurrentIndex)
     */
    get bitrateNextIndex(): number {
        return this.currentPlaybackHandler?.bitrateNextIndex ?? -1;
    }

    set bitrateNextIndex(requestedIndex) {
        if (this.currentPlaybackHandler) {
            this.currentPlaybackHandler.bitrateNextIndex = requestedIndex;
        }
    }

    /**
     * Are we automatically switching to the most appropriate bitrate for our connection?
     */
    get bitrateIsAuto(): boolean {
        return !!this.currentPlaybackHandler?.bitrateIsAuto;
    }

    /**
     * @deprecated - Set `playerTech.bitrateNextIndex` to -1 to turn on, or a valid index to turn off
     */
    set bitrateIsAuto(dontUseMe) {
        throw `Don\'t attempt to ${
            dontUseMe ? 'enable' : 'disable'
        } auto bitrate in this way. Simply set your nextIndex accordingly.`;
    }

    /**
     * @returns - Get the user's explicitly selected bitrate
     */
    bitrateGetUserPreferredQuality: () => PlayerQuality | undefined = () =>
        this.currentPlaybackHandler?.bitrateGetUserPreferredQuality?.();

    /**
     * A string label that summarises the current quality (e.g. 'low', 'auto')
     */
    get bitrateCurrentQuality(): PlayerQualityLevel {
        return this.currentPlaybackHandler?.bitrateCurrentQuality || 'auto';
    }

    /**
     * Current bitrate level
     */
    get currentBitrateLevel(): BaseBitrateInfo {
        const {id, width, height, bitrate} = this.bitrateLevels?.[
            this.bitrateCurrentIndex
        ] || {
            width: 0,
            height: 0,
            bitrate: 0,
        };

        return {
            id,
            width,
            height,
            bitrate,
        };
    }

    /**
     * Switch to automatic bitrate selection (ABR), to use a bitrate appropriate for the current bandwidth
     */
    bitrateSwitchToAuto = (): void =>
        void this.currentPlaybackHandler?.bitrateSwitchToAuto();

    /**
     * Switch to automatic bitrate selection (ABR), to use a bitrate appropriate for the current bandwidth
     *
     */
    setBitrateToAuto = (): void =>
        void this.currentPlaybackHandler?.setBitrateToAuto();

    /**
     * @param selectedHeight - Change the bitrates so we always stay at or below `selectedHeight`
     * @param quality - change quality level so we store the right quality value
     */
    setMaxBitrate = ({
        selectedHeight,
        quality,
    }: {
        selectedHeight: number;
        quality: PlayerQualityLevel;
    }): void => {
        this.currentPlaybackHandler?.setMaxBitrate({
            selectedHeight,
            quality,
        });
    };

    /**
     * @param selectedHeight - Change the bitrates so we always stay at or below `selectedHeight`
     * @param quality - change quality level so we store the right quality value
     */
    saveUserPreferredBitrate = ({
        selectedHeight,
        quality,
    }: {
        selectedHeight: number;
        quality: PlayerQualityLevel;
    }): void => {
        this.currentPlaybackHandler?.saveUserPreferredBitrate({
            selectedHeight,
            quality,
        });
    };

    /**
     * Text tracks available for this video
     */
    get textTracks(): TextTracks | undefined {
        return this.currentPlaybackHandler?.textTracksList;
    }

    /**
     * The index of the current text track, or -1 if none selected
     */
    get textTrackCurrentIndex(): number {
        return this.currentPlaybackHandler?.currentTextTrackIndex ?? -1;
    }

    set textTrackCurrentIndex(index) {
        if (this.currentPlaybackHandler) {
            this.currentPlaybackHandler.textTrack = index;
        }
    }

    /**
     * Stop showing text tracks
     */
    disableTextTrack = (): void => {
        this.currentPlaybackHandler?.disableTextTrack();
    };

    /**
     * Audio tracks available for this video
     */
    get audioTracks(): AudioTrack[] | DashAudioTrack[] {
        return this.currentPlaybackHandler?.audioTracks ?? [];
    }

    /**
     * The index of the current audio track
     */
    get currentAudioTrackIndex(): number {
        return this.currentPlaybackHandler?.currentAudioTrackIndex ?? -1;
    }

    /**
     * Sets the active audio track for the playback
     *
     * @param index - The index of the audio track
     */
    set currentAudioTrackIndex(index: number) {
        if (this.currentPlaybackHandler) {
            this.currentPlaybackHandler.currentAudioTrackIndex = index;
        }
    }

    /**
     * Should the player limit itself to bitrates that aren't too big for the underlying videoElement?
     * This is useful if the player is liable to be in a small viewport, so we don't waste bandwidth downloading a 1080p video only to show it on a 320px player
     */
    get capLevelToPlayerSize(): boolean {
        return !!this.currentPlaybackHandler?.capLevelToPlayerSize;
    }

    set capLevelToPlayerSize(shouldCap) {
        this.coreOptions.capLevelToPlayerSize = shouldCap;

        if (this.currentPlaybackHandler) {
            this.currentPlaybackHandler.capLevelToPlayerSize = shouldCap;
        }
    }

    /**
     * Does this device support AirPlay?
     */
    get supportsAirPlay(): boolean {
        return !!this.currentPlaybackHandler?.supportsAirPlay;
    }

    /**
     * Show the AirPlay target picker, which is used to send our current video to an AirPlay device
     * This function will re-fetch the video source via the requestVideoSourceCallback so we ensure we have a fresh src URL
     */
    showAirPlayTargetPicker = (): void => {
        // By the time we want to cast to AirPlay our initial source might no longer be valid, so refresh it before opening the target picker

        // Unfortunately we can't call showAirPlayTargetPicker after the below promise, because the event needs some trust.
        // 500ms is about as long as we can delay it before losing trust.
        setTimeout(
            () => this.currentPlaybackHandler?.showAirPlayTargetPicker(),
            500
        );
        const currentTimeBeforeSourceReset = this.currentTime;

        this.sourceOptions
            ?.requestVideoSourceCallback?.()
            .then((sourceDetails) =>
                this.setSources({
                    sources: sourceDetails || this.currentSourceDetails || [],
                    options: {
                        ...this.sourceOptions,
                        startAt: currentTimeBeforeSourceReset,
                    },
                    preferredDashPlaybackHandler:
                        this.sourceOptions.preferredDashPlaybackHandler,
                })
            );
    };

    /**
     * Does this device support a Pop Out Player (sometimes called a "PiP")
     */
    get supportsPopOutPlayer(): boolean {
        return !!this.currentPlaybackHandler?.supportsPopOutPlayer;
    }

    /**
     * Version of dash JS
     */
    get dashJSVersion(): string {
        // @ts-expect-error dash js had a wrong type define for Version
        return isBrowser()
            ? window.dashjs?.Version
            : VIDEO_LIBRARY_URLS.dashjs.version;
    }

    /**
     * Version of hls JS
     */
    get hlsJSVersion(): string | undefined {
        return isBrowser()
            ? window?.Hls?.version
            : VIDEO_LIBRARY_URLS.hlsjs.version;
    }

    /**
     * Is this video playing in PopOutPlayer mode
     */
    get isPopOutPlayer(): boolean {
        return !!this.currentPlaybackHandler?.isPopOutPlayer;
    }

    set isPopOutPlayer(shouldPopOut) {
        if (this.currentPlaybackHandler) {
            this.currentPlaybackHandler.isPopOutPlayer = shouldPopOut;
        }
    }

    /**
     * Toggle between regular mode and pop out player mode
     */
    toggleIsPopOutPlayer = (): void =>
        void this.currentPlaybackHandler?.toggleIsPopOutPlayer();

    /**
     * Handling Ad Break Completion
     * For the dash.js player, the ad break is removed from the manifest immediately after it is completed
     * In other cases, the ad break is retained and removed only when the user scrubs backward at any point
     */
    handleCompletedAdBreak = (playedAdBreak: BreakToRemove): void => {
        if (
            this.currentPlaybackHandler?.controllerDiagnostics
                ?.playbackHandlerType === 'dash'
        ) {
            const {startTimeInSeconds, durationInSeconds} = playedAdBreak;

            this.currentPlaybackHandler?.removeBreaks(
                [playedAdBreak],
                startTimeInSeconds + durationInSeconds
            );
        } else {
            this._adBreaksNeedToRemove.push(playedAdBreak);
        }
    };

    /**
     * The current PlayerTech version
     */
    static get version(): string {
        return version;
    }
}

export default PlayerTech;
