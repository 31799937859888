import {
    mediaQuery,
    stylesWhen,
} from '@fsa-streamotion/styled-component-helpers';

import classnames from 'classnames';
import {rgba} from 'polished';
import React, {useState, useRef} from 'react';
import styled, {css} from 'styled-components';

import {transformEnterExit} from '../../../../common/animations';
import CommonTransition from '../../../../common/common-transition';
import {white, satellite} from '../../../../common/palette';
import {
    SCREEN_768_TABLET,
    SCREEN_1920_DESKTOP,
    SCREEN_1024_DESKTOP,
    useEffectiveViewport,
} from '../../../../common/screen-sizes';
import {THEME_ACCESSORS} from '../../../../common/theme-helpers';
import BA01CtrlBtn from '../../../atoms/ba/01-ctrl-btn';
import BA02TraySectionListItem from '../../../atoms/ba/02-tray-section-list-item';
import {IC26ArrowU, IC27ArrowD} from '../../../atoms/ic';
import TM06TraySectionList from '../../../molecules/tm/06-tray-section-list';

const padding = css`
    padding: 15px 0;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 22px 0px;
    `}
`;

const Tray = styled.section<{isTop: boolean}>`
    --smwplayer-tray-offset: 197px;
    display: flex;
    position: relative;
    align-items: center;
    background-color: ${({theme}) => rgba(theme.brandColorVariant, 0.9)};
    background-image: ${({theme}) =>
        `linear-gradient(to top, transparent, ${rgba(
            theme.brandColorVariant,
            0.4
        )})`};
    width: 100%;
    height: 197px;

    ${mediaQuery<{isTop: boolean}>({minWidthPx: SCREEN_768_TABLET})`
        height: 207px;
        --smwplayer-tray-offset: 237px;
    `}

    ${mediaQuery<{isTop: boolean}>({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 252px;
        --smwplayer-tray-offset: 296px;
    `}

    ${transformEnterExit<{isTop: boolean}>`
        transform: translateY(0);
    ``
        transform: translateY(calc(${({isTop}) =>
            isTop ? -1 : 1} * var(--smwplayer-tray-offset)));
    `}
`;

const Content = styled.section`
    box-sizing: border-box;
    position: relative;
    flex-grow: 1;
    width: auto;
    height: 100%;
    overflow: hidden;

    ${stylesWhen('isPadded')`
        ${padding}
        padding-left: 30px;
        ${mediaQuery({minHeightPx: SCREEN_1024_DESKTOP})`
            padding-left: 40px;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding-left: 56px;
        `}
    `}
`;

const CloseButtonContainer = styled.div`
    flex: none;
    width: 42px;
    height: 100%;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 56px;
    `}
`;

const StyledCloseBtn = styled(BA01CtrlBtn)`
    justify-content: center;
    background-color: ${rgba(white, 0.1)};
    width: 100%;
    height: 100%;
    color: ${satellite};

    &:hover {
        background-color: ${THEME_ACCESSORS.brandColor};
        color: ${THEME_ACCESSORS.brandForegroundColor};
    }
`;

const TraySectionListContainer = styled.div`
    ${padding}
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    height: 100%;
`;

const Divider = styled.div`
    background-color: ${rgba(white, 0.15)};
    width: 1px;
    height: 100%;
`;

export type Props = {
    tabItems?: {
        name?: string;
        node?: React.ReactNode;
    }[];
    splitViewToggle?: React.ReactNode;
    isTop?: boolean;
    isVisible?: boolean;
    isPadded?: boolean;
    onTrayClose?: React.MouseEventHandler<HTMLButtonElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const OR02Tray = ({
    tabItems = [],
    isTop = true,
    isVisible,
    isPadded,
    onTrayClose,
    onKeyDown,
    className,
    splitViewToggle,
    ...htmlAttributes
}: Props): React.ReactElement => {
    const [activeIndex, setActiveIndex] = useState(0);
    const activeTraySectionContent = tabItems[activeIndex]?.node;
    const closeIconSize = useEffectiveViewport(SCREEN_1920_DESKTOP)
        ? '33px'
        : '22px';
    const listItemToFocusRef = useRef<HTMLButtonElement>(null);
    const trayRef = useRef<HTMLElement>(null);

    const listItems = tabItems.map(({name = ''}, index) => (
        <BA02TraySectionListItem
            ref={index === 0 ? listItemToFocusRef : undefined}
            // This could be `key={name}`, but that will cause the first item to be destroyed and re-created when tab items are loaded,
            //   which will cause the focus state styling on the first element to "blink"
            key={index}
            label={name}
            isActive={activeIndex === index}
            onClick={() => setActiveIndex(index)}
        />
    ));

    return (
        <CommonTransition
            in={isVisible}
            nodeRef={trayRef}
            onEntered={() => listItemToFocusRef.current?.focus()}
        >
            <Tray
                ref={trayRef}
                isTop={isTop}
                className={classnames(className, 'OR02Tray')}
                onKeyDown={onKeyDown}
                onClick={(event: React.MouseEvent) => {
                    // prevent click events from bubbling out of the tray
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                <TraySectionListContainer {...htmlAttributes}>
                    {splitViewToggle}
                    <TM06TraySectionList>{listItems}</TM06TraySectionList>
                    <Divider />
                </TraySectionListContainer>
                <Content
                    role="tabpanel"
                    isPadded={isPadded}
                    {...htmlAttributes}
                >
                    {activeTraySectionContent}
                </Content>
                <CloseButtonContainer isTop={isTop}>
                    <StyledCloseBtn
                        shouldUseParentContainer={true}
                        onClick={onTrayClose}
                        aria-label={`Close ${isTop ? 'upper' : 'lower'} tray`}
                        tabIndex={-1}
                    >
                        {isTop ? (
                            <IC26ArrowU size={closeIconSize} />
                        ) : (
                            <IC27ArrowD size={closeIconSize} />
                        )}
                    </StyledCloseBtn>
                </CloseButtonContainer>
            </Tray>
        </CommonTransition>
    );
};

OR02Tray.displayName = 'OR02Tray';

export default OR02Tray;
